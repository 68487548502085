import { CrudComponent } from '@admin/components/crud/crud.component';
import { Component } from '@angular/core';

import { UsersFormComponent } from './users-form/users-form.component';

import { Pagination } from '@shared/models/Pagination.model';
import { User } from '@admin/models/User.model';
import { CrudModule } from '@admin/components/crud/crud.module';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [CrudModule],
  templateUrl: '../../components/crud/crud.component.html',
  styleUrl: './users.component.scss',
})
export class UsersComponent extends CrudComponent {
  override title: string = 'Users';
  override data: Pagination<User> = new Pagination(User);

  override handleLoadResponse(responses: any[]): void {
    if (responses[0].data) this.data.setData(responses[0].data);

    this.pagination.set(this.data.rows.length > 0);
    this.stopLoading();
  }

  override settings(): void {
    this.showComponent = UsersFormComponent;
    this.editComponent = UsersFormComponent;
  }
}
