import {
  CrudFormComponent,
  CrudFormFooterComponent,
  CrudFormHeaderComponent,
} from '@admin/components';
import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { AlertComponent } from '@shared/components';

@Component({
  selector: 'app-channels-form',
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
  ],
  templateUrl: './channels-form.component.html',
  styleUrl: './channels-form.component.scss',
})
export class ChannelsFormComponent extends CrudFormComponent {
  resources: string[] = [];
  types: string[] = [];

  override data = this.formBuilder.group({
    name: ['', [Validators.required]],
    code: ['', [Validators.required]],
  });

  override handleLoadResponse(responses: any[]): void {
    if (this.dataId() !== -1 && responses[0] && responses[0].status === 200) {
      const responseData = responses[0].data;
      this.data.patchValue(responseData);
    }
  }

  override getParams() {
    const params = this.data.value;

    return params;
  }

  override initSettings(): void {
    if (this.readonly) this.data.disable();
  }

  override getFormatEndpoint(): string {
    return this.endpoint.startsWith('admin/')
      ? this.endpoint
      : 'admin/' + this.endpoint;
  }
}
