<div class="container">
  <div class="row gy-3">
    <div class="col-12 mt-0">
      <h4 class="fw-bold mb-0">SUMMARY</h4>
    </div>

    <div class="col-12">
      <div class="w-100 table-responsive card mb-4">
        <table class="table text-center mb-0">
          <thead class="table-dark">
            <tr>
              <th scope="col">Units Contracted</th>

              <th scope="col">Units Delivered</th>

              <th scope="col">Impressions Contracted</th>

              <th scope="col">Impressions Delivered</th>
            </tr>
          </thead>

          <tbody class="table-warning">
            <tr>
              <td class="col-3">{{ data.unitsContracted }}</td>
              <td class="col-3">{{ data.unitsDelivered }}</td>
              <td class="col-3">{{ data.impressionsContracted }}</td>
              <td class="col-3">{{ data.impressionsDelivered }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-12">
      <h5 class="fw-bold">Comments</h5>

      <textarea
        id="comments"
        class="form-control"
        [(ngModel)]="data.comments"
        style="min-height: 100px"
      ></textarea>
    </div>

    <div class="col-12 px-0">
      <div class="row gy-4 gy-lg-5">
        @for (item of menuItems; track $index) {
        <div class="col-md-6 col-xl-5 mx-auto">
          <app-main-card
            image=""
            secondary=""
            [icon]="item.icon"
            (onPrimary)="goTo(item.id)"
          >
            <div class="w-100 pb-2 text-center">
              <h5 class="mb-2">{{ item.actions }}</h5>

              <h5 class="fw-bold text-primary mb-0">{{ item.title }}</h5>
            </div>
          </app-main-card>
        </div>
        }
      </div>
    </div>
  </div>
</div>
