import { Type } from 'class-transformer';

export class MatchModel {
  media: string[] = ['', ''];
  game: string = '';
  date: string = '';
  broadcast: string = '';
  viewership: number = 0;
  impressions: number = 0;

  unitsContracted: number = 0;
  unitsDelivered: number = 0;

  @Type(() => TimeModel)
  timeContracted: TimeModel = new TimeModel();

  @Type(() => TimeModel)
  timeDelivered: TimeModel = new TimeModel();

  @Type(() => TimeMatch)
  firstTimes: TimeMatch[] = [];

  @Type(() => TimeModel)
  totalFirst: TimeModel = new TimeModel();

  @Type(() => TimeMatch)
  secondTimes: TimeMatch[] = [];

  @Type(() => TimeModel)
  totalSecond: TimeModel = new TimeModel();

  // ! to remove
  // constructor(data: any = {}) {
  //   for (const key in this) {
  //     if (data[key] !== undefined) {
  //       this[key] = data[key];
  //     }
  //   }

  //   if (data.timeContracted) {
  //     this.timeContracted = new TimeModel(data.timeContracted);
  //   }

  //   if (data.timeDelivered) {
  //     this.timeDelivered = new TimeModel(data.timeDelivered);
  //   }
  //   if (data.firstTimes && Array.isArray(data.firstTimes)) {
  //     this.firstTimes = data.firstTimes.map((time: any) => {
  //       const nTime = new TimeMatch(time);
  //       return nTime;
  //     });
  //   }

  //   if (data.totalFirst) {
  //     this.totalFirst = new TimeModel(data.totalFirst);
  //   }

  //   if (data.secondTimes && Array.isArray(data.secondTimes)) {
  //     this.secondTimes = data.secondTimes.map((time: any) => {
  //       const nTime = new TimeMatch(time);
  //       return nTime;
  //     });
  //   }
  //   if (data.totalSecond) {
  //     this.totalSecond = new TimeModel(data.totalSecond);
  //   }
  // }
}

export class TimeModel {
  minutes: number = 0;
  seconds: number = 0;

  constructor(data: any = {}) {
    if (data.minutes) {
      this.minutes = data.minutes;
    }
    if (data.seconds) {
      this.seconds = data.seconds;
    }
  }

  totalTime(): number {
    return this.minutes * 60 + this.seconds;
  }

  setTime(seconds: number) {
    this.minutes = Math.floor(seconds / 60);
    this.seconds = seconds % 60;
  }
}

export class TimeMatch {
  @Type(() => TimeModel)
  start: TimeModel = new TimeModel();

  @Type(() => TimeModel)
  end: TimeModel = new TimeModel();

  @Type(() => TimeModel)
  total: TimeModel = new TimeModel();

  // constructor(data: any = {}) {
  //   this.start = new TimeModel(data.start);
  //   this.end = new TimeModel(data.end);
  //   this.total = new TimeModel(data.total);
  // }
}
