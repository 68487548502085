import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const minLength: number = 1;

export function minArrayLengthValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || !Array.isArray(control.value)) {
      return null;
    }
    return control.value.length >= minLength ? null : { minArrayLength: true };
  };
}
