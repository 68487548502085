<div class="time-table">
  <div class="w-100 table-responsive rounded-3">
    <table class="table table-striped text-center mb-0">
      <thead class="table-secondary">
        <tr class="text-light align-middle">
          <th scope="col">Start</th>
          <th scope="col">End</th>
          <th scope="col" colspan="2">Total Time</th>
        </tr>
      </thead>

      <tbody>
        @for (time of data; track $index) {
        <tr class="align-middle">
          <th class="col-4 ps-1">
            <div class="time-inputs">
              <input
                appSecondsInput
                id="minutes-start"
                type="number"
                [min]="0"
                [max]="130"
                step="1"
                class="form-control form-control-sm px-1"
                [(ngModel)]="time.start.minutes"
                (ngModelChange)="totalTime($index)"
                [value]="time.start.minutes | zeroPad"
              />

              <span class="mx-1">:</span>

              <input
                appSecondsInput
                id="seconds-start"
                type="number"
                [min]="0"
                [max]="59"
                step="1"
                class="form-control form-control-sm px-1"
                [(ngModel)]="time.start.seconds"
                (ngModelChange)="totalTime($index)"
                [value]="time.start.seconds | zeroPad"
              />
            </div>
          </th>

          <th class="col-4 pe-1">
            <div class="time-inputs">
              <input
                appSecondsInput
                id="minutes-end"
                type="number"
                [min]="0"
                [max]="130"
                step="1"
                class="form-control form-control-sm px-1"
                [(ngModel)]="time.end.minutes"
                (ngModelChange)="totalTime($index)"
                [value]="time.end.minutes | zeroPad"
              />

              <span class="mx-1">:</span>

              <input
                appSecondsInput
                id="seconds-end"
                type="number"
                [min]="0"
                [max]="59"
                step="1"
                class="form-control form-control-sm px-1"
                [(ngModel)]="time.end.seconds"
                (ngModelChange)="totalTime($index)"
                [value]="time.end.seconds | zeroPad"
              />
            </div>
          </th>

          <th class="col-3">
            {{
              ('' + time.total.minutes).padStart(2, '0') +
                ' : ' +
                ('' + time.total.seconds).padStart(2, '0')
            }}
          </th>

          <th class="col-auto col-icon">
            @if ($index > 0) {
            <button
              type="button"
              class="btn btn-danger"
              (click)="deleteTime($index)"
            >
              <i class="fa-solid fa-trash-can"></i>
            </button>
            }
          </th>
        </tr>
        }

        <tr>
          <th colspan="4" class="text-center py-3">
            <button
              type="button"
              class="btn btn-primary px-3 py-1"
              (click)="addTime()"
            >
              <i class="fa-solid fa-plus fa-lg"></i>
            </button>
          </th>
        </tr>
      </tbody>

      <tfoot class="table-secondary">
        <tr>
          <th colspan="4">
            <div class="col-4 ms-auto text-center">
              <h5 class="fw-bold text-light mb-0">
                {{
                  ('' + total.minutes).padStart(2, '0') +
                    ' : ' +
                    ('' + total.seconds).padStart(2, '0')
                }}
              </h5>
            </div>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
