import { NgClass } from '@angular/common';
import { Component, ElementRef, Input, output, ViewChild } from '@angular/core';
import { Observable, Observer, forkJoin } from 'rxjs';

const MB_SIZE = 1024 * 1024 * 5;

@Component({
  selector: 'app-image-controller',
  standalone: true,
  imports: [NgClass],
  templateUrl: './image-controller.component.html',
  styleUrl: './image-controller.component.scss',
})
export class ImageControllerComponent {
  @Input() src: string = '';
  @Input() alt: string = 'Imagen';
  @Input() label: string = 'Imagen';
  @Input() title: string = '';
  @Input() disable: boolean = false;
  @Input() fileTypes: string = 'image/png, image/jpeg, .png, .jpg';
  @ViewChild('fileInput') fileInput!: ElementRef;

  error = output<string>();
  selectFile = output<any>();

  template = {
    name: '',
    contents: '',
  };

  ngOnInit(): void {
    if (this.src) {
      this.template.contents = this.src;
    }
  }

  reset(inputRef: HTMLInputElement) {
    inputRef.value = '';
  }

  clearFileInput(): void {
    this.template = {
      name: '',
      contents: '',
    };

    this.fileInput.nativeElement.value = '';
  }

  // 1 Titulo, 2 Cedula
  onFileSelected(event: any) {
    const files: FileList = event.target.files;

    if (files instanceof FileList) {
      const allFilesValid = Array.from(files).every((file) => {
        return file.size <= MB_SIZE;
      });

      if (!allFilesValid) {
        this.error.emit('El archivo supera el tamaño máximo permitido');
        return;
      }

      this.convertFilesToBase64(files).subscribe({
        next: (base64Array: string[]) => {
          for (let i = 0; i < base64Array.length; i++) {
            const template = {
              name: '',
              contents: '',
            };
            template.name = files[i].name;
            template.contents = base64Array[i];

            this.template = template;
          }

          this.selectFile.emit(this.template);
        },
        error: (error) => {
          // Manejo del error
          console.error(error);
          this.error.emit(error);
        },
      });
    }
  }

  remove(inputElement: HTMLInputElement, index: number) {
    if (inputElement.files) {
      this.reset(inputElement);
    }
  }

  createFileList(files: File[]): FileList {
    const dataTransfer = new DataTransfer();

    for (const file of files) {
      dataTransfer.items.add(file);
    }

    return dataTransfer.files;
  }

  convertFilesToBase64(files: FileList): Observable<string[]> {
    const conversions: Observable<string>[] = Array.from(files).map((file) =>
      this.convertToBase64(file)
    );

    return forkJoin(conversions);
  }

  convertToBase64(file: File): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result as string;
        observer.next(base64String);
        observer.complete();
      };

      reader.onerror = () => {
        observer.error('Error al cargar el archivo.');
      };

      reader.readAsDataURL(file);
    });
  }
}
