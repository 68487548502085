import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-crud-form-footer',
  standalone: true,
  imports: [NgClass],
  templateUrl: './crud-form-footer.component.html',
  styleUrl: './crud-form-footer.component.scss',
})
export class CrudFormFooterComponent {
  @Input() created: boolean = false;
  @Input() modal: boolean = false;
  @Input() disable: boolean = false;
  @Input() loading: boolean = false;
  @Output() onSubmit: EventEmitter<void> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
}
