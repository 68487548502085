<div class="card border-0 shadow-sm">
  <div class="card-header bg-primary">
    <div class="row align-items-center">
      <div class="col-xl mb-3 mb-xl-0">
        <h5 class="text-white mb-0">Video {{ index + 1 }}</h5>
      </div>

      <div class="col-auto ms-auto">
        <button
          type="button"
          class="btn btn-secondary me-xl-3"
          (click)="collapsed = !collapsed"
        >
          <i
            class="fa-solid fa-lg"
            [ngClass]="collapsed ? 'fa-chevron-down' : 'fa-chevron-up'"
          ></i>
        </button>

        <button
          type="button"
          class="btn btn-light"
          ngbPopover="Delete"
          triggers="mouseenter:mouseleave"
          (click)="deleteMatch()"
        >
          <i class="fa-solid fa-trash fa-lg"></i>
        </button>
      </div>
    </div>
  </div>

  <div
    class="card-body text-center p-0"
    #collapse="ngbCollapse"
    [(ngbCollapse)]="collapsed"
  >
    <div class="container rounded-top bg-secondary-subtle py-3">
      <div class="row">
        <div class="col-xl-8 mx-auto">
          <label for="views" class="form-label">Views</label>

          <input
            id="views"
            type="number"
            min="0"
            class="form-control text-center"
            [(ngModel)]="data.views"
            (ngModelChange)="onVideoChange()"
          />
        </div>
      </div>
    </div>

    <div class="container rounded-bottom text-bg-secondary py-4">
      <app-video-edit
        [video]="data.video"
        (onVideoChange)="data.video = $event"
      ></app-video-edit>
    </div>
  </div>
</div>
