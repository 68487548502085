<mat-drawer-container class="app-container">
  <mat-drawer
    #drawer
    mode="side"
    class="sidebar-container shadow-sm"
    [mode]="isMobile ? 'over' : 'side'"
    [opened]="!isMobile"
  >
    <!-- @defer(when eventId() !== -1 && event.id !== -1) { -->
    <app-sidebar [items]="menuItems" />
    <!-- } -->
  </mat-drawer>

  <mat-drawer-content class="main-content">
    <div class="app">
      @if (isMobile) {
      <nav class="navbar bg-light-subtle border-bottom border-primary py-3">
        <div class="container-fluid">
          <a [routerLink]="['/admin/']" class="px-3">
            <img
              src="assets/images/logo.png"
              height="35"
              alt="Interticket Logo"
              onerror="this.onerror=null; this.src='assets/images/logo.png'"
            />
          </a>

          <div class="ms-auto">
            <button
              type="button"
              class="btn btn-primary"
              (click)="drawer.toggle()"
            >
              <i class="fa-solid fa-bars"></i>
            </button>
          </div>
        </div>
      </nav>
      }

      <main
        [@fadeIn]
        [ngClass]="{ 'overlay-loading-container': loading$ | async }"
      >
        <router-outlet></router-outlet>

        @if (loading$ | async) {
        <div class="loading-overlay"><div class="loading-skeleton"></div></div>
        }
      </main>

      <app-footer />
    </div>

    <p-toast />
  </mat-drawer-content>
</mat-drawer-container>
