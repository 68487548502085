import { Component, input } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { Budget, Budgets } from '@admin/models/Campaign.model';
import { CampaignReport } from '@admin/models/CampaignReport.model';
import { TableReport } from '@admin/models/TableReport.model';

@Component({
  selector: 'app-report',
  imports: [FormsModule, CurrencyPipe, DecimalPipe],
  templateUrl: './report.component.html',
  styleUrl: './report.component.scss',
})
export class ReportComponent {
  data = input<CampaignReport>(new CampaignReport());
  readonly = input<boolean>(true);
  budgets = input<Budgets[]>([]);
  typeId = input<number>(-1);

  budget = new Budget();
  sections: TableReport[] = [];
  summary = {
    units: {
      contracted: 0,
      delivered: 0,
      variance: 0,
    },
    impressions: {
      contracted: 0,
      delivered: 0,
      variance: 0,
    },
    cpm: {
      contracted: 0,
      delivered: 0,
      variance: 0,
    },
    description: '',
  };

  ngOnInit(): void {
    if (this.typeId() !== -1) {
      const fBudgets = this.data().campaign.budgets.filter((item) => {
        return this.typeId() === item.id;
      });

      if (fBudgets && fBudgets.length > 0) {
        this.budget = fBudgets[0];
      }
    }

    if (this.budgets() && this.budgets().length > 0) {
      this.budget.budgets = this.budgets().reduce((acc, budget) => {
        acc.fieldboards += budget.fieldboards;
        acc.near_goal += budget.near_goal;
        acc.key_moments += budget.key_moments;

        return acc;
      }, new Budgets());
    }

    this.initTables();
  }

  initTables() {
    this.calculateSummary();

    const fieldboard = this.calculateFieldboards();
    this.sections.push(fieldboard);

    const keyMoments = this.calculateKeyMoments();
    this.sections.push(keyMoments);

    const nearGoal = this.calculateNearGoal();
    this.sections.push(nearGoal);

    const earnedMedia = this.calculateEarnedMedia();
    this.sections.push(earnedMedia);
  }

  calculateSummary() {
    const [fieldboardUC, fieldboardUD, fieldboardID, fieldboardIC] =
      this.data().segments.data.fieldboard.segments.reduce(
        (acc, segment) => {
          return [
            acc[0] + segment.summary.unitsContracted,
            acc[1] + segment.summary.unitsDelivered,
            acc[2] + segment.summary.impressionsDelivered,
            acc[3] + segment.summary.impressionsContracted,
          ];
        },
        [0, 0, 0, 0]
      );

    const [keyMomentsUC, keyMomentsUD, keyMomentsID, keyMomentsIC] =
      this.data().segments.data.keyMoments.segments.reduce(
        (acc, segment) => {
          return [
            acc[0] + segment.summary.unitsContracted,
            acc[1] + segment.summary.unitsDelivered,
            acc[2] + segment.summary.impressionsDelivered,
            acc[3] + segment.summary.impressionsContracted,
          ];
        },
        [0, 0, 0, 0]
      );

    const [nearGoalUR, nearGoalIC, nearGoalID] =
      this.data().segments.data.nearGoal.segments.reduce(
        (acc, segment) => {
          return [
            acc[0] + segment.summary.unitsReceived,
            acc[1] + segment.summary.impressionsContracted,
            acc[2] + segment.summary.impressionsDelivered,
          ];
        },
        [0, 0, 0]
      );

    const earnedMediaID = this.data().segments.data.earnedMedia.segments.reduce(
      (acc, segment) => {
        return acc + segment.summary.totalViews;
      },
      0
    );

    // * UNITS
    this.summary.units.contracted = fieldboardUC + keyMomentsUC + nearGoalUR;
    this.summary.units.delivered = fieldboardUD + keyMomentsUD;
    this.summary.units.variance =
      this.summary.units.delivered - this.summary.units.contracted;

    // * IMPRESSIONS
    this.summary.impressions.contracted =
      fieldboardIC + keyMomentsIC + nearGoalIC;
    this.summary.impressions.delivered =
      fieldboardID + keyMomentsID + nearGoalID + earnedMediaID;
    this.summary.impressions.variance =
      this.summary.impressions.delivered - this.summary.impressions.contracted;

    // * CPM

    if (this.data().campaign) {
      const totalBudget =
        this.budget.budgets.fieldboards +
        this.budget.budgets.key_moments +
        this.budget.budgets.near_goal;

      this.summary.cpm.contracted =
        this.summary.impressions.contracted > 0
          ? (totalBudget / this.summary.impressions.contracted) * 1000
          : 0;

      this.summary.cpm.delivered =
        this.summary.impressions.delivered > 0
          ? (totalBudget / this.summary.impressions.delivered) * 1000
          : 0;

      this.summary.cpm.variance =
        this.summary.cpm.contracted - this.summary.cpm.delivered;
    }
  }

  calculateFieldboards(): TableReport {
    const nTable = new TableReport();
    nTable.comentControl = 'fieldboards';

    const fieldboardsBudget = this.budget.budgets.fieldboards || 0;

    const [fieldboardUC, fieldboardUD, fieldboardID, fieldboardIC] =
      this.data().segments.data.fieldboard.segments.reduce(
        (acc, segment) => {
          return [
            acc[0] + segment.summary.unitsContracted,
            acc[1] + segment.summary.unitsDelivered,
            acc[2] + segment.summary.impressionsDelivered,
            acc[3] + segment.summary.impressionsContracted,
            acc[4] + segment.info.contracted,
            acc[5] + segment.info.delivered,
          ];
        },
        [0, 0, 0, 0, 0, 0]
      );

    const cpmC =
      fieldboardIC > 0 ? (fieldboardsBudget / fieldboardIC) * 1000 : 0;

    const cpmD =
      fieldboardID > 0 ? (fieldboardsBudget / fieldboardID) * 1000 : 0;

    nTable.title = `FIELDBOARDS`;
    nTable.cols = ['Totals', 'Contracted', 'Delivered', 'Variance'];
    nTable.rows = [
      ['Units', fieldboardUC, fieldboardUD, fieldboardUD - fieldboardUC],
      ['Impressions', fieldboardIC, fieldboardID, fieldboardID - fieldboardIC],
      ['CPM', cpmC, cpmD, Number(cpmC) - Number(cpmD)],
    ];

    return nTable;
  }

  calculateKeyMoments(): TableReport {
    const nTable = new TableReport();
    nTable.comentControl = 'key-moments';

    const [keyMomentsUC, keyMomentsUD, keyMomentsID, keyMomentsIC] =
      this.data().segments.data.keyMoments.segments.reduce(
        (acc, segment) => {
          return [
            acc[0] + segment.summary.unitsContracted,
            acc[1] + segment.summary.unitsDelivered,
            acc[2] + segment.summary.impressionsDelivered,
            acc[3] + segment.summary.impressionsContracted,
            acc[4] + segment.info.contracted,
            acc[5] + segment.info.delivered,
          ];
        },
        [0, 0, 0, 0, 0, 0]
      );

    const keymomentsBudget = this.budget.budgets.key_moments || 0;

    const cpmC =
      keyMomentsIC > 0 ? (keymomentsBudget / keyMomentsIC) * 1000 : 0;

    const cpmD =
      keyMomentsID > 0 ? (keymomentsBudget / keyMomentsID) * 1000 : 0;

    nTable.title = `KEY MOMENTS`;
    nTable.cols = ['Totals', 'Contracted', 'Delivered', 'Variance'];
    nTable.rows = [
      ['Units', keyMomentsUC, keyMomentsUD, keyMomentsUD - keyMomentsUC],
      ['Impressions', keyMomentsIC, keyMomentsID, keyMomentsID - keyMomentsIC],
      ['CPM', cpmC, cpmD, cpmC - cpmD],
    ];

    return nTable;
  }

  calculateNearGoal(): TableReport {
    const nTable = new TableReport();
    nTable.comentControl = 'near-goal';

    const [nearGoalID, nearGoalIC, nearGoalUR] =
      this.data().segments.data.nearGoal.segments.reduce(
        (acc, segment) => {
          return [
            acc[0] + segment.summary.impressionsDelivered,
            acc[1] + segment.summary.impressionsContracted,
            acc[2] + segment.summary.unitsReceived,
          ];
        },
        [0, 0, 0]
      );

    const neargoalBudget = this.budget.budgets.near_goal || 0;

    const cpmC = nearGoalIC > 0 ? (neargoalBudget / nearGoalIC) * 1000 : 0;

    const cpmD = nearGoalID > 0 ? (neargoalBudget / nearGoalID) * 1000 : 0;

    nTable.title = `NEAR GOAL`;
    nTable.cols = ['Totals', 'Contracted', 'Delivered', 'Variance'];
    nTable.rows = [
      ['Units', nearGoalUR, '-', '-'],
      ['Impressions', nearGoalIC, nearGoalID, nearGoalID - nearGoalIC],
      ['CPM', cpmC, cpmD, cpmC - cpmD],
    ];

    return nTable;
  }

  calculateEarnedMedia(): TableReport {
    const nTable = new TableReport();
    nTable.comentControl = 'earned-media';

    const totalViews = this.data().segments.data.earnedMedia.segments.reduce(
      (acc, segment) => {
        return acc + segment.summary.totalViews;
      },
      0
    );

    nTable.title = `EARNED MEDIA`;
    nTable.cols = ['Totals', 'Delivered'];
    nTable.rows = [['Impressions', totalViews]];

    return nTable;
  }
}
