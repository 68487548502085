import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { Observable } from 'rxjs';

import { CrudFormFooterComponent } from '@admin/components/crud-form-footer/crud-form-footer.component';
import { CrudFormHeaderComponent } from '@admin/components/crud-form-header/crud-form-header.component';
import { CrudFormComponent } from '@admin/components/crud-form/crud-form.component';
import { RolePermission } from '@admin/models/Role.model';

import { AlertComponent } from '@shared/components/alert/alert.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-roles-form',
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,

    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
  ],
  templateUrl: './roles-form.component.html',
  styleUrl: './roles-form.component.scss',
})
export class RolesFormComponent extends CrudFormComponent {
  permissions: RolePermission[] = [];
  ids: Set<number> = new Set<number>();

  override data = this.formBuilder.group({
    name: ['', [Validators.required]],
  });
  override services: Observable<any>[] = [
    this.api.call('admin/permissions?limit=10000'),
  ];

  override initSettings(): void {
    if (this.readonly) this.data.disable();
  }

  override handleLoadResponse(responses: any[]): void {
    const last = responses.length;

    if (responses[0] && responses[0].status === 200)
      this.permissions = responses[0].data.rows.map((role: any) => {
        const nRole = new RolePermission();
        nRole.setData(role);
        return nRole;
      });



    if (this.dataId() !== -1 && responses[last - 1]) {
      if (responses[last - 1].status === 200) {
        const responseData = responses[last - 1].data;
        this.data.patchValue(responseData);

        if (responseData['permissions']) {
          responseData['permissions']?.forEach((roleId: RolePermission) => {
            this.ids.add(roleId.id);
          });
        }
      } else {
        responses[last - 1].status === 400;
        this.failed(responses[last - 1].message)
        // this.goBack();
      }
    }
  }

  handleId(id: number) {
    if (this.ids.has(id)) {
      this.ids.delete(id);
    } else {
      this.ids.add(id);
    }
  }

  override getParams() {
    const params: any = this.data.value;
    params['permissions'] = [...this.ids];

    return params;
  }

  override isValid(): boolean {
    this.data.markAllAsTouched();

    let valid: boolean = this.data.valid ?? false;

    if (!valid) {
      this.handleAlert('Fill in all required fields!');
    }

    if (this.ids.size === 0) {
      this.handleAlert('Select at least one permission!');

      valid = false;
    }

    return valid;
  }

  override getFormatEndpoint(): string {
    return this.endpoint.startsWith('admin/')
      ? this.endpoint
      : 'admin/' + this.endpoint;
  }
}
