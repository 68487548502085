<app-crud-form-header
  [id]="dataId()"
  title="client"
  titleContainerClass="container pt-3 pt-lg-4 color-primary"
  [modal]="isComponent"
></app-crud-form-header>

<div [ngClass]="isComponent ? 'modal-body' : 'container py-3 py-lg-4 py-xl-5'">
  <form
    [formGroup]="data"
    (ngSubmit)="submit()"
    class="row h-100 justify-content-around gy-3 gy-lg-4"
  >
    <div class="col-12 px-0">
      @if (initialized) {
      <app-image-controller
        title="Logo"
        label="Add an image, accepts jpg and png formats, with a minimum size of 50px x 50px."
        [src]="currentTemplate.file || '/assets/images/avatar.png'"
        [disable]="readonly"
        (selectFile)="selectFile($event)"
      ></app-image-controller>
      }
    </div>

    <!-- ! DEBUG -->
    <!-- <button type="button" class="btn btn-danger" (click)="resetFile()">
      test
    </button> -->
    <!-- ! DEBUG -->

    <div class="col-lg-6 d-flex flex-column">
      <p class="fw-bold mb-1">Name</p>

      <p>To identify the client, it will be displayed in the client list.</p>

      <input
        id="name"
        name="name"
        class="form-control"
        type="text"
        formControlName="name"
        [ngClass]="{
          'is-invalid':
            data.get('name')?.invalid &&
            (data.get('name')?.dirty || data.get('name')?.touched)
        }"
      />

      @if (data.get('name')?.invalid && (data.get('name')?.dirty ||
      data.get('name')?.touched) ) {
      <div id="validationName" class="invalid-feedback">Name is required.</div>
      }
    </div>

    <div class="col-lg-6 d-flex flex-column">
      <p class="fw-bold mb-1">Email</p>

      <p>To automatically send reports to the client once published.</p>

      <input
        id="email"
        name="email"
        class="form-control"
        type="text"
        formControlName="email"
        [ngClass]="{
          'is-invalid':
            data.get('email')?.invalid &&
            (data.get('email')?.dirty || data.get('email')?.touched)
        }"
      />

      @if (data.get('email')?.invalid && (data.get('email')?.dirty ||
      data.get('email')?.touched) ) {
      <div id="validationName" class="invalid-feedback">Email is required.</div>
      }
    </div>

    <div class="col-lg-6 d-flex flex-column">
      <p class="fw-bold mb-1">Viewership</p>

      <p>Assigns an existing viewership to the client.</p>

      <ng-select
        id="tag"
        appearance="outline"
        formControlName="viewership_id"
        placeholder="Viewership"
        [searchable]="true"
        [clearable]="false"
      >
        @for (item of viewerships; track item.id) {
        <ng-option [value]="item.id">{{ item.name }}</ng-option>
        }

        <ng-option [value]="-1">Other</ng-option>
      </ng-select>

      @if (data.get('viewership_id')?.invalid &&
      (data.get('viewership_id')?.dirty || data.get('viewership_id')?.touched))
      {
      <div id="tagValidation" class="invalid-feedback">
        Viewership is required
      </div>
      }
    </div>

    <div class="col-lg-6 d-flex flex-column">
      @if (data.get('viewership_id')?.value === -1) {
      <p class="fw-bold mb-1">New Viewership</p>
      <p>If you need a new viewership write it below.</p>

      <input
        id="viewership"
        name="viewership"
        class="form-control"
        type="text"
        formControlName="viewership"
      />
      }
    </div>

    <div class="col-12">
      <p class="fw-bold mb-1">Description</p>

      <label for="discount-amount" class="form-label mb-1">
        Add a client description for more information.
      </label>

      <textarea
        id="description"
        name="description"
        class="form-control"
        formControlName="description"
        rows="3"
      ></textarea>
    </div>

    <div class="col-12 d-flex align-items-center">
      <div class="flex-fill">
        <p class="fw-bold mb-1">Send reports</p>

        Do you want the general report to be automatically sent to the client
        once it is generated?
      </div>

      <div class="form-check form-check-inline">
        <input
          id="reports2"
          class="form-check-input"
          type="radio"
          name="report"
          [value]="0"
          formControlName="report"
        />
        <label class="form-check-label" for="reports2">No</label>
      </div>

      <div class="form-check form-check-inline">
        <input
          id="reports1"
          class="form-check-input"
          type="radio"
          name="report"
          [value]="1"
          formControlName="report"
        />
        <label class="form-check-label" for="reports1">Yes</label>
      </div>
    </div>

    <app-alert [content]="alertData"></app-alert>

    @if (!isComponent) {
    <div class="col-12 px-0">
      <app-crud-form-footer
        [created]="created"
        [disable]="readonly"
        [modal]="isComponent"
        [loading]="loadingService.getLoading()"
        (cancel)="cancel()"
      ></app-crud-form-footer>
    </div>
    }
  </form>
</div>

@if (isComponent) {
<app-crud-form-footer
  [created]="created"
  [disable]="readonly"
  [modal]="isComponent"
  [loading]="loadingService.getLoading()"
  (onSubmit)="submit()"
  (cancel)="cancel()"
></app-crud-form-footer>
}
