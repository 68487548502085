import { Component } from '@angular/core';

import { CrudComponent } from '@admin/components';

import { MainCardComponent } from '@shared/components';
import { ElementModel } from '@shared/models/Element.model';
import { Pagination } from '@shared/models/Pagination.model';

import { CrudElement } from '@admin/models/CrudElement.model';
import { CrudModule } from '@admin/components/crud/crud.module';

@Component({
  selector: 'app-reports',
  standalone: true,
  imports: [CrudModule, MainCardComponent],
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.scss',
})
export class ReportsComponent extends CrudComponent {
  override data: Pagination<CrudElement> = new Pagination(CrudElement);

  override handleLoadResponse(responses: any[]): void {
    if (responses[0].data) this.data.setData(responses[0].data);

    this.pagination.set(this.data.rows.length > 0);

    this.stopLoading();
  }

  override onPrimary() {
    this.router.navigate([`${this.crudRoute}/`]);
  }
}
