<app-title title="CUSTOM REPORT"></app-title>

<div class="container px-0 py-3 py-lg-4 py-xl-5">
  <div class="row py-4">
    @switch (step) { @case (1) {
    <div class="col-12 mb-4 px-0">
      <form class="row" [formGroup]="form" (ngSubmit)="generate()">
        <div class="col-xl-4 mb-4">
          <label for="select-client" class="form-label fw-bold">Client</label>

          <ng-select
            id="select-client"
            appearance="outline"
            formControlName="client"
            placeholder="Select a client"
            [searchable]="true"
            [clearable]="false"
          >
            <ng-option [value]="0">All clients</ng-option>

            @for (item of clients; track item.id) {
            <ng-option [value]="item.id">{{ item.name }}</ng-option>
            }
          </ng-select>

          @if (form.get('client')?.invalid && (form.get('client')?.dirty ||
          form.get('client')?.touched)) {
          <div id="clientValidation" class="text-danger">Required field.</div>
          }
        </div>

        <div class="col-xl-4 mb-4">
          <label for="select-init" class="form-label fw-bold"
            >Initial monthly period</label
          >

          <ng-select
            id="select-init"
            appearance="outline"
            formControlName="start"
            placeholder="Select a period"
            [searchable]="true"
            [clearable]="false"
          >
            @for (item of months; track item.id) {
            <ng-option [value]="item.id">{{ item.label }}</ng-option>
            }
          </ng-select>

          @if (form.get('start')?.invalid && (form.get('start')?.dirty ||
          form.get('start')?.touched)) {
          <div id="startValidation" class="text-danger">Required field.</div>
          }
        </div>

        <div class="col-xl-4 mb-4">
          <label for="select-final" class="form-label fw-bold"
            >Final monthly period</label
          >

          <ng-select
            id="select-final"
            appearance="outline"
            formControlName="end"
            placeholder="Select a period"
            [searchable]="true"
            [clearable]="false"
          >
            @for (item of months; track item.id) {
            <ng-option [value]="item.id">{{ item.label }}</ng-option>
            }
          </ng-select>

          @if (form.get('end')?.invalid && (form.get('end')?.dirty ||
          form.get('end')?.touched)) {
          <div id="endValidation" class="text-danger">Required field.</div>
          }
        </div>

        <div class="col-auto ms-auto">
          <button class="btn btn-primary" type="submit">GENERATE REPORT</button>
        </div>
      </form>
    </div>
    } @case (2) {
    <div class="col-12 px-0">
      <app-report></app-report>
    </div>
    } }
  </div>
</div>
