<div class="crud-page">
  <div class="crud-content">
    <div class="container py-3 py-lg-4">
      <div class="row">
        <div class="col-12 px-0">
          <app-crud-header
            title="Clients"
            [createRoute]="crudRoute"
            [filters]="filters"
            (onSearch)="handleSearch($event)"
          ></app-crud-header>
        </div>

        <app-alert [content]="alertData"></app-alert>

        @for (item of data.rows; track $index) {
        <div class="col-md-6 col-xl-4 mb-3 mb-md-4">
          <app-main-card
            [primary]="canShow() ? 'folder' : ''"
            [secondary]="canEdit() ? 'edit' : ''"
            [image]="item['logo']"
            (onPrimary)="onPrimary(item.id)"
            (onSecondary)="onSecondary(item.id)"
          >
            <div class="row">
              <div class="col-12">
                <p class="mb-2">
                  <i class="fa-solid fa-user icon-w"></i
                  ><span class="fw-bold">{{ item.name }}</span>
                </p>

                <p class="mb-0">
                  <i class="fa-solid fa-folder icon-w"></i
                  ><span
                    >{{
                      item.campaigns +
                        ' campaign' +
                        (item.campaigns !== 1 ? 's' : '')
                    }}
                  </span>
                </p>
              </div>
            </div>
          </app-main-card>
        </div>
        } @empty {
        <div class="col-12">
          <p class="h4 text-secondary">No elements</p>
        </div>
        }
      </div>
    </div>

    @if (pagination() && data.rows && data.rows.length > 0) {
    <div class="crud-footer">
      <mat-paginator
        [pageSizeOptions]="[10, 25, 50, 100]"
        [length]="data.total"
        [pageSize]="data.limit"
        aria-label="Selecciona pagina de placas"
        (page)="handlePage($event)"
      ></mat-paginator>
    </div>
    }
  </div>
</div>
