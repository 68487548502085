<div class="container">
  <div class="row py-4">
    <div class="col-12 my-5">
      <div class="card bg-white avatar-container">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6 mb-4 mb-lg-0">
              <h1 class="fs-1 mb-3">Hi</h1>
              <h1>{{ data.name }}</h1>
              <h4 class="fw-light">What we doing today?</h4>
            </div>

            <div class="col-lg-4 mx-auto avatar-container-img">
              <img
                [src]="data.avatar || '/assets/svg/avatar.svg'"
                class="avatar-img img-fluid"
                alt="Avatar"
                onerror="this.onerror=null; this.src='/assets/svg/avatar.svg'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    @for (item of items; track $index) {
    <div class="col-lg-6 col-xl-4 mb-4">
      <app-main-card
        image=""
        [icon]="item.icon"
        secondary=""
        (onPrimary)="goTo(item.route)"
      >
        <div class="w-100 pb-2 text-center">
          <h5 class="mb-2">{{ item.actions }}</h5>

          <h5 class="fw-bold mb-0 text-primary">{{ item.title }}</h5>
        </div>
      </app-main-card>
    </div>
    }
  </div>
</div>
