<div class="row position-relative">
  <div
    class="col-12 mb-4 mb-lg-0"
    [ngClass]="{'col-lg-10 col-xl-9': editingVideo}"
  >
    @if (editingVideo) {
    <h5 class="text-center">Video</h5>

    <input
      type="text"
      class="form-control"
      id="games"
      [(ngModel)]="video"
      (ngModelChange)="onVideoChange.emit(video)"
      aria-describedby="emailHelp"
    />
    } @else {
    <div class="ratio ratio-16x9">
      <iframe
        id="ytplayer"
        type="text/html"
        [src]="embebed"
        frameborder="0"
      ></iframe>
    </div>
    }
  </div>

  <div class="col-lg align-self-end">
    <button
      type="button"
      class="btn btn-primary"
      [ngClass]="editingVideo ? 'w-100' : 'edit-btn btn-sm'"
      (click)="editVideo()"
      [disabled]="editingVideo && !video"
    >
      @if ( editingVideo ) { SAVE } @else {
      <i class="fa-solid fa-pen fa-xl"></i>
      }
    </button>
  </div>
</div>
