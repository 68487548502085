import { Main } from './Main.model';

export class Pagination<T extends Main> {
  total: number = 0;
  limit: number = 16;
  page: number = 1;
  show: number = 0;
  group: string = '';
  rows: T[] = [];
  cols: Column[] = [];
  order: string[] = [];

  private readonly construct: new () => T;

  constructor(construct: new () => T) {
    this.construct = construct;
  }

  setData(data: any): void {
    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }

    if (data.rows && Array.isArray(data.rows)) {
      this.rows = data.rows.map((row: any) => {
        const nT = new this.construct();

        nT.setData(row);

        return nT;
      });
    }
  }
}

export class Column {
  field: string = '';
  label: string = '';
  sort: boolean = false;
}
