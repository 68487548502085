import { NgClass } from '@angular/common';
import {
  Component,
  Input,
  Signal,
  computed,
  inject,
  output,
  signal,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { ElementModel } from '@shared/models/Element.model';
import { RolesService } from '@services/roles.service';
import { TitleComponent } from '@shared/components/title/title.component';
import { Search, SearchBarComponent } from '../search-bar/search-bar.component';

@Component({
  selector: 'app-crud-header',
  standalone: true,
  imports: [
    NgClass,
    RouterModule,
    MatIconModule,
    MatButtonModule,

    TitleComponent,
    SearchBarComponent,
  ],
  templateUrl: './crud-header.component.html',
  styleUrl: './crud-header.component.scss',
})
export class CrudHeaderComponent {
  private roles = inject(RolesService);
  protected route = inject(ActivatedRoute);
  protected router = inject(Router);

  @Input() createRoute: string = '';
  @Input() description: string = '';
  @Input() filters: ElementModel[] = [];
  @Input() name: string = '';
  @Input() search: boolean = false;
  @Input({ required: true }) title: string = '';
  @Input() titleContainer: string = 'w-100';

  group = signal('');
  canCreate: Signal<boolean> = computed(() =>
    this.createRoute ? this.roles.can(this.group(), 'create') : false
  );
  onSearch = output<Search>();

  ngOnInit(): void {
    if (this.createRoute) {
      const parts = this.router.url.split('/').slice(0, 3);

      const endpoint = parts.join('/').substring(1);
      this.group.set(endpoint.replace('/', '.'));
    }
  }

  handleSearch(data: Search) {
    this.onSearch.emit(data);
  }
}
