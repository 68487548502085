import {
  Component,
  HostListener,
  Signal,
  computed,
  signal,
} from '@angular/core';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';

import { MatSidenavModule } from '@angular/material/sidenav';
import { ToastModule } from 'primeng/toast';

import { SharedModule } from '@shared/shared.module';

import { ApiService } from '@services/api.service';
import { LoadingService } from '@services/loading.service';
import { RolesService } from '@services/roles.service';

import { FooterComponent, HeaderComponent, SidebarComponent } from './layout';
import { MenuItem } from './models/MenuItem.model';
import { AsyncPipe, NgClass } from '@angular/common';
import { fadeInAnimation } from '@shared/animations/fade.animation';
import { Profile } from './models/Profile.model';
import { forkJoin } from 'rxjs';

const MIN_SCREEN_SIZE = 992;

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    NgClass,
    AsyncPipe,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    MatSidenavModule,
    SharedModule,
    ToastModule,
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss',
  animations: [fadeInAnimation],
})
export class AdminComponent {
  // data: Pagination<EventModel> = new Pagination(EventModel);
  initialized: boolean = false;
  isMobile: boolean = false;
  loading = signal(false);
  menuItems: MenuItem[] = [];
  params: any = {
    page: 1,
    take: 10,
  };
  group = signal('');
  canShow: Signal<boolean> = computed(() =>
    this.roles.can(this.group(), 'index')
  );
  canCreate: Signal<boolean> = computed(() =>
    this.roles.can(this.group(), 'create')
  );
  loading$ = this.loadingService.loading$;
  profile = new Profile();

  constructor(
    private api: ApiService,
    protected loadingService: LoadingService,
    private route: ActivatedRoute,
    private roles: RolesService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = window.innerWidth < MIN_SCREEN_SIZE;
  }

  ngOnInit(): void {
    this.isMobile = window.innerWidth < MIN_SCREEN_SIZE;
    // this.loadingService.start();
    const sections: any[] = [
      {
        name: 'RECENT CAMPAIGNS',
        icon: 'fa-solid fa-folder',
        permission: 'index',
        route: '/admin/campaigns',
      },
      {
        name: 'CLIENTS',
        icon: 'fa-solid fa-user',
        permission: 'index',
        route: '/admin/clients',
      },
      {
        name: 'REPORTS',
        icon: 'fa-solid fa-file',
        permission: 'index',
        route: '/admin/reports',
        separator: true,
      },
      {
        name: 'NEW CLIENT',
        icon: 'fa-solid fa-user-plus',
        permission: 'index',
        route: '/admin/clients/create',
      },
      {
        name: 'NEW CAMPAIGN',
        icon: 'fa-solid fa-folder-plus',
        permission: 'index',
        route: '/admin/campaigns/create',
      },
      {
        name: 'PROPERTIES',
        icon: 'fa-solid fa-square-list',
        permission: 'index',
        route: '/admin/properties',
      },
      {
        name: 'ADMIN',
        icon: 'fa-solid fa-user-gear',
        permission: 'index',
        children: [
          {
            name: 'CHANNELS',
            icon: 'fa-solid fa-tower-broadcast',
            permission: 'index',
            route: '/admin/channels',
          },
          {
            name: 'PERMISSIONS',
            icon: 'fa-solid fa-lock',
            permission: 'index',
            route: '/admin/permissions',
          },
          {
            name: 'ROLES',
            icon: 'fa-solid fa-user-shield',
            permission: 'index',
            route: '/admin/roles',
          },
          {
            name: 'USERS',
            icon: 'fa-solid fa-users',
            permission: 'index',
            route: '/admin/users',
          },
        ],
      },
    ];
    this.menuItems = sections.map((section, ind) => {
      const nItem = new MenuItem();
      nItem.id = ind + 1;
      nItem.title = section.name;
      nItem.icon = section.icon;
      nItem.permission = section.permission;
      nItem.route = section.route;
      nItem.separator = section.separator || false;

      if (section.children && section.children.length > 0) {
        nItem.children = section.children.map(
          (sectionC: any, index: number) => {
            const nCItem = new MenuItem();
            nCItem.id = index + 1;
            nCItem.title = sectionC.name;
            nCItem.icon = sectionC.icon;
            nCItem.permission = sectionC.permission;
            nCItem.route = sectionC.route;
            nCItem.separator = sectionC.separator || false;

            return nCItem;
          }
        );
      }

      return nItem;
    });

    // * profile service
    const services = [this.api.call('admin/profile/me')];

    forkJoin(services).subscribe({
      next: ([response]) => {
        if (response.status === 200) {
          Object.assign(this.profile, response.data);
        }
      },
    });
  }

  pagination(next: boolean = false) {
    this.params['page'] = this.params['page'] + (next ? 1 : -1);

    this.api.call('admin/events', 'get', this.params).subscribe({
      next: (response) => {
        if (response.status === 200) {
          // this.data.setData(response.data);
        }

        this.loading.set(false);
      },
      error: (error) => {
        this.loading.set(false);
      },
    });
  }
}
