import { Injectable } from '@angular/core';

import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (!value) return null;

    const date = value.split(this.DELIMITER);

    return {
      day: parseInt(date[1], 10),
      month: parseInt(date[0], 10),
      year: parseInt(date[2], 10),
    };
  }

  format(date: NgbDateStruct | null): string {
    if (!date) return '';

    const month = date.month.toString().padStart(2, '0');
    const day = date.day.toString().padStart(2, '0');

    return `${month}${this.DELIMITER}${day}${this.DELIMITER}${date.year}`;
  }
}
