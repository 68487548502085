import { Fieldboard } from './Fieldboard.model';
import { EarnedMedia } from './EarnedMedia.model';
import { NearGoal } from './NearGoal.model';
import { plainToClassFromExist, Type, plainToClass } from 'class-transformer';

export class SegmentEarnedMedia {
  impressions: number = 0;

  @Type(() => EarnedMedia)
  items: EarnedMedia[] = [];

  setData(data: any) {
    if (data['impressions']) {
      this.impressions = data['impressions'];
    }

    if (data['items'] && Array.isArray(data['items'])) {
      this.items = data['items'].map((item: any) => {
        let nItem = new EarnedMedia();

        // nItem.setData(item);
        nItem = plainToClassFromExist(nItem, item);
        nItem.matches = item.items;
        nItem.summary.totalViews = Number(item?.impressions || 0);
        // nItem.calculateSummary();

        return nItem;
      });
    }
  }
}

export class TypeFieldboard {
  name: string = '';
  slug: string = '';
  budget: number = 0;

  @Type(() => Fieldboard)
  segments: Fieldboard[] = [];

  setData(all: any) {
    const { segments, ...data } = all;

    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }

    if (segments) {
      this.segments = segments.map((segment: any) => {
        const nFieldboard = new Fieldboard();
        nFieldboard.id = segment.id;
        nFieldboard.budget = this.budget;
        nFieldboard.matches = segment.items;
        nFieldboard.summary.impressionsContracted = Number(
          segment?.impressions || 0
        );

        nFieldboard.init();

        return nFieldboard;
      });
    }
  }
}

export class TypeNearGoal {
  name: string = '';
  slug: string = '';
  budget: number = 0;

  @Type(() => NearGoal)
  segments: NearGoal[] = [];

  // ! TO REMOVE
  setData(all: any) {
    const { segments, ...data } = all;

    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }

    if (segments) {
      this.segments = segments.map((segment: any) => {
        const nSegment = plainToClass(NearGoal, segment);
        nSegment.matches = segment.items;
        nSegment.budget = this.budget;
        nSegment.summary.impressionsContracted = Number(
          segment.impressions || 0
        );

        nSegment.init();

        return nSegment;
      });
    }
  }
}

export class TypeEarnedMedia {
  name: string = '';
  slug: string = '';
  budget: number = 0;

  @Type(() => EarnedMedia)
  segments: EarnedMedia[] = [];

  // ! TO REMOVE
  setData(all: any) {
    const { segments, ...data } = all;

    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }

    if (segments) {
      this.segments = segments.map((segment: any) => {
        const nSegment = plainToClass(EarnedMedia, segment);
        nSegment.matches = segment.items;

        nSegment.summary.totalViews = Number(segment?.impressions || 0);

        nSegment.calculateSummary();

        return nSegment;
      });
    }
  }
}

export class CampaignSeasonData {
  @Type(() => TypeFieldboard)
  fieldboard: TypeFieldboard = new TypeFieldboard();
  @Type(() => TypeFieldboard)
  keyMoments: TypeFieldboard = new TypeFieldboard();
  @Type(() => TypeNearGoal)
  nearGoal: TypeNearGoal = new TypeNearGoal();
  @Type(() => TypeEarnedMedia)
  earnedMedia: TypeEarnedMedia = new TypeEarnedMedia();

  // ! TO REMOVE
  // setData(data: any) {
  //   if (data['fieldboard']) {
  //     this.fieldboard.setData(data['fieldboard']);
  //   }
  //   if (data['keyMoments']) {
  //     this.keyMoments.setData(data['keyMoments']);
  //   }
  //   if (data['nearGoal']) {
  //     this.nearGoal.setData(data['nearGoal']);
  //   }
  //   if (data['earnedMedia']) {
  //     this.earnedMedia.setData(data['earnedMedia']);
  //   }
  // }
}

export class SegmentType {
  id: number = -1;
  campaign_id: number = -1;
  property_id: number = -1;
  property: SegmentProperty = new SegmentProperty();
}

export class SegmentProperty {
  id: number = -1;
  name: string = '';
  created_at: string | null = null;
  updated_at: string | null = null;
  deleted_at: string | null = null;
}

export class CampaignSeason {
  budgets: Array<number> = new Array<number>();
  unitsContracted: number = 0;
  unitsDelivered: number = 0;
  impressionsContracted: number = 0;
  impressionsDelivered: number = 0;
  comments: string = '';
  published: boolean = false;

  @Type(() => CampaignSeasonData)
  data: CampaignSeasonData = new CampaignSeasonData();

  @Type(() => SegmentType)
  type: SegmentType = new SegmentType();

  calculateData() {
    this.data.fieldboard.segments.forEach((fieldboard) => {
      fieldboard.calculateSummary();
      fieldboard.calculateCPMContrated();
    });

    this.data.keyMoments.segments.forEach((keyMoments) => {
      keyMoments.calculateSummary();
      keyMoments.calculateCPMContrated();
    });

    this.data.nearGoal.segments.forEach((nearGoal) => {
      nearGoal.calculateSummary();
      nearGoal.calculateCPMContrated();
    });

    this.data.earnedMedia.segments.forEach((earnedMedia) => {
      earnedMedia.calculateSummary();
    });
  }

  calculate() {
    this.calculateUnitsContracted();
    this.calculateUnitsDelivered();
    this.calculateImpressionsContracted();
    this.calculateImpressionsDelivered();
  }

  calculateUnitsContracted() {
    // * UC = Units Contracted, UR = Units Received
    const fieldboardUC = this.data.fieldboard.segments.reduce(
      (acc, fieldboard) => {
        return acc + fieldboard.summary.unitsContracted;
      },
      0
    );

    const keyMomentsUC = this.data.keyMoments.segments.reduce(
      (acc, keyMoments) => {
        return acc + keyMoments.summary.unitsContracted;
      },
      0
    );

    const nearGoalUR = this.data.nearGoal.segments.reduce((acc, nearGoal) => {
      return acc + nearGoal.summary.unitsReceived;
    }, 0);

    this.unitsContracted = fieldboardUC + keyMomentsUC + nearGoalUR;
  }

  calculateUnitsDelivered() {
    // * UD = Units Delivered, UR = Units Received
    const fieldboardUD = this.data.fieldboard.segments.reduce(
      (acc, fieldboard) => {
        return acc + fieldboard.summary.unitsDelivered;
      },
      0
    );

    const keyMomentsUD = this.data.keyMoments.segments.reduce(
      (acc, keyMoments) => {
        return acc + keyMoments.summary.unitsDelivered;
      },
      0
    );

    this.unitsDelivered = fieldboardUD + keyMomentsUD;
  }

  calculateImpressionsContracted() {
    // * IC = Impressions Contracted
    const fieldboardIC = this.data.fieldboard.segments.reduce(
      (acc, fieldboard) => {
        return acc + fieldboard.summary.impressionsContracted;
      },
      0
    );

    const keyMomentsIC = this.data.keyMoments.segments.reduce(
      (acc, keyMoments) => {
        return acc + keyMoments.summary.impressionsContracted;
      },
      0
    );

    const nearGoalIC = this.data.nearGoal.segments.reduce((acc, nearGoal) => {
      return acc + nearGoal.summary.impressionsContracted;
    }, 0);

    this.impressionsContracted = fieldboardIC + keyMomentsIC + nearGoalIC;
  }

  calculateImpressionsDelivered() {
    // * ID = Impressions Delivered
    const fieldboardID = this.data.fieldboard.segments.reduce(
      (acc, fieldboard) => {
        return acc + fieldboard.summary.impressionsDelivered;
      },
      0
    );

    const keyMomentsID = this.data.keyMoments.segments.reduce(
      (acc, keyMoments) => {
        return acc + keyMoments.summary.impressionsDelivered;
      },
      0
    );

    const nearGoalID = this.data.nearGoal.segments.reduce((acc, nearGoal) => {
      return acc + nearGoal.summary.impressionsDelivered;
    }, 0);

    const earnedMediaID = this.data.earnedMedia.segments.reduce(
      (acc, nearGoal) => {
        return acc + nearGoal.summary.totalViews;
      },
      0
    );

    this.impressionsDelivered =
      fieldboardID + keyMomentsID + nearGoalID + earnedMediaID;
  }
}
