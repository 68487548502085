import { Component, input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

import { Menu } from '@admin/models/Menu.model';
import { CampaignSeason } from '@admin/models/Playoffs.model';
import { MainCardComponent } from '@shared/components';
import { SegmentBroadcast } from '@admin/models/Segment.model';

@Component({
  selector: 'app-monthly-summary',
  imports: [FormsModule, DecimalPipe, MainCardComponent],
  templateUrl: './monthly-summary.component.html',
  styleUrl: './monthly-summary.component.scss',
})
export class MonthlySummaryComponent {
  broadcasts = input<SegmentBroadcast[]>([]);
  data = input<CampaignSeason>(new CampaignSeason());

  onStepChange = output<string>();

  menuItems: Menu[] = [];

  ngOnInit(): void {
    this.menuItems = [];

    console.debug(this.broadcasts());
    console.debug(this.data());

    [1, 2, 3].forEach((item) => {
      const nItem = this.getItem(item);

      if (nItem) {
        this.menuItems.push(nItem);
      }
    });

    this.menuItems.push({
      id: 4,
      title: 'EARNED MEDIA',
      actions: 'Add / View',
      icon: 'fa-solid fa-video',
      route: 'earned-media',
    });

    this.calculateMonthly();
  }

  calculateMonthly() {
    console.debug(this.data());

    // this.data().calculateData();
    this.data().calculate();

    console.debug(this.data());
  }

  getItem(id: number): Menu | null {
    switch (id) {
      case 1:
        return {
          id: 1,
          title: 'FIELDBOARDS',
          actions: 'Add / View',
          icon: 'fa-solid fa-star-of-life',
          route: 'fieldboards',
        };
      case 2:
        return {
          id: 2,
          title: 'KEY MOMENTS',
          actions: 'Add / View',
          icon: 'fa-solid fa-star',
          route: 'key-moments',
        };
      case 3:
        return {
          id: 3,
          title: 'NEAR GOAL',
          actions: 'Add / View',
          icon: 'fa-solid fa-circle',
          route: 'near-goal',
        };

      default:
        return null;
    }
  }

  goTo(id: string) {
    this.onStepChange.emit(id);
  }
}
