import { Component, Input } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { FormComponent } from '@shared/components';

import { NearGoalVideoComponent } from '@admin/components';
import { ACTION_LOADING } from '@admin/campaigns/constants/variables';
import { NearGoal, NearGoalVideo } from '@admin/models/NearGoal.model';

@Component({
  selector: 'app-near-goal',
  standalone: true,
  imports: [FormsModule, CurrencyPipe, DecimalPipe, NearGoalVideoComponent],
  templateUrl: './near-goal.component.html',
  styleUrl: './near-goal.component.scss',
})
export class NearGoalComponent extends FormComponent {
  @Input() override data: NearGoal = new NearGoal();
  @Input() typeId: number = 0;

  ngOnInit(): void {
    this.endpoint = `admin/segments/broadcast/${this.data.id}`;
    this.method = 'put';

    if (this.data.matches.length === 0) {
      this.newMatch();
    } else {
      this.data.matches = this.data.matches.map((match) => {
        return new NearGoalVideo(match);
      });
    }

    this.calculateCPMContrated();
    this.calculateTotal();
    this.data.init();
  }

  newMatch() {
    const nVideo = new NearGoalVideo();

    this.data.matches.push(nVideo);

    setTimeout(() => {
      const element = document.getElementById(
        `match-${this.data.matches.length}`
      );

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, ACTION_LOADING - 80);
  }

  deleteMatch(index: number) {
    this.data.matches.splice(index, 1);
  }

  override isValid(): boolean {
    this.data.matches = this.data.matches.filter((match) => {
      if (
        match.leftVideo === '' ||
        match.rightVideo === '' ||
        match.game === ''
      ) {
        return false;
      }

      return true;
    });

    const valid = this.data.matches.length > 0;

    if (!valid) {
      this.handleAlert('Match is required');
      this.newMatch();
    }

    return valid;
  }

  override getParams() {
    const params = {
      impressions: this.data.summary.impressionsContracted,
      items: this.data.matches,
    };

    return params;
  }

  override success(response: any): void {
    this.handleAlert(`¡Changes saved successfully!`, 'success');

    this.stopLoading();
  }

  calculateTotal() {
    this.data.calculateSummary();
    this.data.calculateCPMDelivered();
  }

  calculateCPMContrated() {
    this.data.calculateCPMContrated();
  }
}
