import { MatchModel } from './Match.model';

export class Fieldboard {
  id: number = -1;
  budget: number = 0;
  description: string = '';
  summary = {
    unitsContracted: 0,
    unitsDelivered: 0,
    impressionsContracted: 0,
    impressionsDelivered: 0,
  };
  info = {
    totals: 0,
    contracted: 0,
    delivered: 0,
    variance: 0,
  };
  matches: MatchModel[] = [];

  init() {
    this.calculateCPMContrated();
    this.calculateCPMDelivered();
    this.calculateSummary();
    this.calculateVariance();
  }

  calculateSummary() {
    const [unitsContracted, unitsDelivered, impressionsDelivered] =
      this.matches.reduce(
        (acc, match) => {
          return [
            acc[0] + match.unitsContracted,
            acc[1] + match.unitsDelivered,
            acc[2] + match.impressions,
          ];
        },
        [0, 0, 0]
      );

    this.summary.unitsContracted = unitsContracted;
    this.summary.unitsDelivered = unitsDelivered;
    this.summary.impressionsDelivered = impressionsDelivered;

    console.groupEnd();
  }

  // Presupuesto ÷ Impresiones Contrated × 1,000 = CPM
  calculateCPMContrated() {
    if (this.summary.impressionsContracted < 1) {
      return;
    }

    this.info.contracted =
      (this.budget / this.summary.impressionsContracted) * 1000;

    this.calculateVariance();
  }

  // Presupuesto ÷ Impresiones Delivered × 1,000 = CPM
  calculateCPMDelivered() {
    if (this.summary.impressionsDelivered < 1) {
      console.error('no valid delivered: ', this.summary);

      return;
    }

    this.info.delivered =
      (this.budget / this.summary.impressionsDelivered) * 1000;

    this.calculateVariance();
  }

  calculateVariance() {
    if (this.info.contracted > 0 && this.info.delivered > 0) {
      this.info.variance = this.info.contracted - this.info.delivered;
    }
  }
}
