<div class="card border-0 shadow-sm">
  <div class="card-header bg-primary">
    <div class="row align-items-center">
      <div class="col-xl mb-3 mb-xl-0">
        <h5 class="text-white mb-0">
          {{ data.game }}
        </h5>
      </div>

      <div class="col-auto ms-auto">
        <button
          type="button"
          class="btn btn-secondary me-3"
          (click)="collapsed = !collapsed"
        >
          <i
            class="fa-solid fa-md"
            [ngClass]="collapsed ? 'fa-chevron-down' : 'fa-chevron-up'"
          ></i>
        </button>

        <button
          type="button"
          class="btn btn-light"
          ngbPopover="Delete"
          triggers="mouseenter:mouseleave"
          (click)="deleteMatch()"
        >
          <i class="fa-solid fa-trash fa-md"></i>
        </button>
      </div>
    </div>
  </div>

  <div
    class="card-body p-0"
    #collapse="ngbCollapse"
    [(ngbCollapse)]="collapsed"
  >
    <!-- <div class="delete-match-container"></div> -->

    <div class="container text-bg-secondary py-4">
      <div class="row">
        <div class="col-xl-6 mb-3 mb-xl-0 px-0">
          <div class="row">
            <div class="col-12 px-0 pt-2">
              <app-video-edit
                [video]="data.video"
                (onVideoChange)="data.video = $event"
              ></app-video-edit>
            </div>
          </div>
        </div>

        <div class="col-xl-6 px-0">
          <div class="row">
            <div class="col-12 px-0 pt-2">
              <div class="row text-center gy-4 my-auto">
                <div class="col-12 text-center mt-0">
                  <label for="games" class="form-label">Games</label>

                  <input
                    type="text"
                    class="form-control"
                    id="games"
                    [(ngModel)]="data.game"
                    aria-describedby="emailHelp"
                  />
                </div>

                <div class="col-md col-input">
                  <label for="date" class="form-label"> Date </label>

                  <input
                    type="date"
                    class="form-control mt-auto"
                    id="date"
                    [(ngModel)]="data.date"
                  />
                </div>

                <div class="col-md col-input">
                  <label for="broadcast" class="form-label">
                    Broadcast(s)
                  </label>

                  <ng-select
                    id="broadcast"
                    appearance="outline"
                    [items]="channels"
                    bindValue="id"
                    bindLabel="name"
                    class="mt-auto"
                    [(ngModel)]="data.broadcast"
                    placeholder="Broadcast"
                    [clearable]="false"
                  ></ng-select>
                </div>

                <div class="col-md col-input">
                  <label for="viwership" class="form-label">
                    Viewership (P2+)
                  </label>

                  <input
                    id="viwership"
                    type="number"
                    min="0"
                    class="form-control mt-auto"
                    [(ngModel)]="data.viewership"
                    (ngModelChange)="calculateTotalImpressions()"
                  />
                </div>

                <div class="col-12">
                  <p class="mb-1">Total Impressions</p>

                  <h4 class="text-light mb-0">
                    {{ data.impressions | number : '1.0-0' }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container bg-secondary-subtle py-4">
      <div class="row text-center">
        <div class="col-md-3">
          <p class="mb-1">Units Contracted</p>

          <input
            id="viwership"
            type="number"
            min="0"
            class="form-control text-center"
            [(ngModel)]="data.unitsContracted"
            (ngModelChange)="calculateUnits()"
          />
        </div>

        <div class="col-md-3">
          <p class="mb-1">Units Delivered</p>

          <p class="fw-bold mb-0">
            {{ data.unitsDelivered | number : '1.3-3' }}
          </p>
        </div>

        <div class="col-md-3">
          <p class="mb-1">Time Contracted</p>

          <p class="fw-bold mb-0">
            {{
              ('' + data.timeContracted.minutes).padStart(2, '0') +
                ':' +
                ('' + data.timeContracted.seconds).padStart(2, '0')
            }}
          </p>
        </div>

        <div class="col-md-3">
          <p class="mb-1">Time Delivered</p>

          <p class="fw-bold mb-0">
            {{
              ('' + data.timeDelivered.minutes).padStart(2, '0') +
                ':' +
                ('' + data.timeDelivered.seconds).padStart(2, '0')
            }}
          </p>
        </div>
      </div>
    </div>

    <div class="container bg-white rounded-bottom py-4">
      <div class="row">
        <div class="col-xl-6 px-0 mb-4 mb-xl-0">
          <div class="row">
            <div class="col-12 mb-2">
              <p class="fw-bold text-center mb-0">First Half of Insertions</p>
            </div>

            <div class="col-12">
              <app-time-controller
                [data]="data.firstTimes"
                [total]="data.totalFirst"
                (onTimeChange)="calculateTimeContracted()"
              ></app-time-controller>
            </div>
          </div>
        </div>

        <div class="col-xl-6 px-0">
          <div class="row">
            <div class="col-12 mb-2">
              <p class="fw-bold text-center mb-0">Second Half of Insertions</p>
            </div>

            <div class="col-12">
              <app-time-controller
                [data]="data.secondTimes"
                [total]="data.totalSecond"
                (onTimeChange)="calculateTimeContracted()"
              ></app-time-controller>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
