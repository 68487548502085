<div class="crud-page">
  <div class="crud-content">
    <div class="container py-3 py-lg-4">
      <div class="row">
        <div class="col-12 px-0">
          <app-crud-header
            title="Reports"
            [createRoute]="crudRoute"
          ></app-crud-header>
        </div>

        <app-alert [content]="alertData"></app-alert>

        @for (item of data.rows; track $index) {
        <div class="col-lg-6 col-lg-4 col-xxl-3 mb-3 mb-lg-4">
          <app-main-card
            primary="folder"
            (onPrimary)="onPrimary()"
            (onSecondary)="onSecondary(item.id)"
          >
            <div class="row py-3">
              <div class="col-12">
                <p class="mb-2">
                  <i class="fa-solid fa-user icon-w"></i
                  ><span class="fw-bold">Clients name</span>
                </p>

                <p class="mb-2">
                  <i class="fa-solid fa-folder icon-w"></i
                  ><span>10 campaigns</span>
                </p>

                <p class="mb-0">
                  <i class="fa-solid fa-tag icon-w"></i><span>Property</span>
                </p>
              </div>
            </div>
          </app-main-card>
        </div>
        } @empty {
        <div class="col-12">
          <p class="h4 text-secondary">No elements</p>
        </div>
        }
      </div>
    </div>

    @if (pagination() && data.rows && data.rows.length > 0) {
    <div class="crud-footer">
      <mat-paginator
        [pageSizeOptions]="[10, 25, 50, 100]"
        [length]="data.total"
        [pageSize]="data.limit"
        aria-label="Selecciona pagina de placas"
        (page)="handlePage($event)"
      ></mat-paginator>
    </div>
    }
  </div>
</div>
