import { Main } from '@shared/models/Main.model';

export class Client extends Main {
  id: number = -1;
  campaigns: number = 0;
  name: string = '';
  email: string = '';
  logo: string = '';
  description: string = '';
  viewership_id: number = -1;
}
