import { Routes } from '@angular/router';

import { environment } from '@environments';

import { adminRoutes } from './admin/admin.routes';
import { authRoutes } from './auth/auth.routes';

import { privateGuard, privateGuardChild } from '@shared/guards/private.guard';
import { publicGuard, publicGuardChild } from '@shared/guards/public.guard';

import { AdminComponent } from '@admin/admin.component';

export const routes: Routes = [
  {
    path: 'auth',
    children: [...authRoutes],
    canActivate: [publicGuard],
    canActivateChild: [publicGuardChild],
  },
  {
    path: 'admin',
    component: AdminComponent,
    title: `Admin - ${environment.title}`,
    children: [...adminRoutes],
    canActivate: [privateGuard],
    canActivateChild: [privateGuardChild],
  },
  {
    path: '**',
    redirectTo: 'auth',
  },
];
