import { Type } from 'class-transformer';

export class EarnedMedia {
  id: number = -1;
  description: string = '';
  summary = {
    totalViews: 0,
  };

  @Type(() => CampaignEarnedMedia)
  matches: CampaignEarnedMedia[] = [];

  // ! TO REMOVE
  // setData(data: any) {
  //   for (const key in this) {
  //     if (data[key] !== undefined) {
  //       this[key] = data[key];
  //     }
  //   }
  // }

  calculateSummary(): void {
    this.summary.totalViews = this.matches.reduce(
      (acc: number, current: CampaignEarnedMedia) =>
        acc + Number(current.views),
      0
    );
  }
}

export class CampaignEarnedMedia {
  media: string = '';
  views: number = 0;
}
