import { Type } from 'class-transformer';

export class Segment {
  id: number = -1;
  name: string = '';
  comments: string | null = null;
  published: number = 0;

  @Type(() => SegmentBroadcast)
  broadcasts: SegmentBroadcast[] = [];

  @Type(() => SegmentType)
  type: SegmentType = new SegmentType();
}

export class SegmentBroadcast {
  id: number = -1;
  impressions: number = 0;
  items: any[] = [];
}

export class SegmentType {
  id: number = -1;
  campaign_id: number = -1;

  @Type(() => Comments)
  comments: Comments = new Comments();

  @Type(() => Property)
  property: Property = new Property();

  @Type(() => TypeType)
  type: TypeType = new TypeType();
}

export class Comments {
  fieldboards: string = '';
  key_moments: string = '';
  near_goal: string = '';
  earned_media: string = '';
}

export class Property {
  id: number = -1;
  name: string = '';

  @Type(() => Property)
  property: Property | null = null;
}

export class TypeType {
  id: number = -1;
  type: string | null = null;

  @Type(() => Comments)
  comments: Comments = new Comments();

  @Type(() => Property)
  property: Property = new Property();
}
