import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgClass } from '@angular/common';

import { NgSelectModule } from '@ng-select/ng-select';

import {
  CrudFormFooterComponent,
  CrudFormHeaderComponent,
  CrudFormComponent,
  ImageControllerComponent,
} from '@admin/components';

import { AlertComponent } from '@shared/components/alert/alert.component';
import { Permission } from '@admin/models/Permission.model';
import { minArrayLengthValidator } from '@shared/validators/array-length.validator';
import { TitleComponent } from '@shared/components';
import { Client } from '@admin/models/Client.model';

@Component({
  selector: 'app-users-form',
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
    NgSelectModule,

    AlertComponent,
    CrudFormHeaderComponent,
    TitleComponent,
    CrudFormFooterComponent,
    ImageControllerComponent,
  ],
  templateUrl: './users-form.component.html',
  styleUrl: './users-form.component.scss',
})
export class UsersFormComponent extends CrudFormComponent {
  resources: string[] = [];
  types: string[] = [];
  passwordVisible: boolean = false;
  matchPassword: boolean = true;
  clients: Client[] = [];
  roles: Permission[] = [];

  override data = this.formBuilder.group({
    active: [true],
    name: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    email: ['', [Validators.required]],
    avatar: [''],
    client: new FormControl<number | null>(null, Validators.required),
    roles: [[], [minArrayLengthValidator()]],
    password: ['', [Validators.required]],
    confirm: ['', [Validators.required]],
  });

  override initSettings(): void {
    this.services = [
      this.api.call('admin/roles?take=1000'),
      this.api.call('admin/clients?take=1000'),
    ];

    if (this.readonly) this.data.disable();
  }

  override handleLoadResponse(responses: any[]): void {
    const last = responses.length - 1;

    if (responses[0] && responses[0].status === 200) {
      this.roles = responses[0].data.rows;
    }

    if (responses[1] && responses[1].status === 200) {
      this.clients = responses[1].data.rows;
    }

    if (
      this.dataId() !== -1 &&
      responses[last] &&
      responses[last].status === 200
    ) {
      const responseData = responses[last].data;
      this.data.patchValue(responseData);

      if (
        this.data.get('avatar')?.value &&
        typeof this.data.get('avatar')?.value === 'string'
      ) {
        this.currentTemplate.file = String(
          this.data.get('avatar')?.value ?? ''
        );
      }
    }
  }

  override isValid(): boolean {
    this.data.markAllAsTouched();

    this.matchPassword =
      this.data.get('password')?.value === this.data.get('confirm')?.value;

    const valid = this.data.valid && this.matchPassword;

    return valid;
  }

  override getParams() {
    const params = this.data.value;

    return params;
  }

  override handleFile() {
    if (this.currentTemplate.file) {
      this.data.get('avatar')?.setValue(this.currentTemplate.file);
    }
  }
}
