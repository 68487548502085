import { Type } from 'class-transformer';

import { Main } from '@shared/models/Main.model';
import { Segment } from './Segment.model';

export class CampaignType extends Main {
  id: number = -1;
  campaign_id: number = -1;

  @Type(() => Comments)
  comments: Comments = new Comments();

  @Type(() => Property)
  property: Property = new Property();

  @Type(() => Budgets)
  budgets: Budgets = new Budgets();

  @Type(() => SimpleType)
  type: SimpleType | null = null;

  @Type(() => SimpleType)
  types: SimpleType[] = [];

  @Type(() => Segment)
  segments: Segment[] = [];
}

export class SimpleType {
  id: number = -1;
  @Type(() => Comments)
  comments: Comments = new Comments();

  @Type(() => Property)
  property: Property = new Property();

  @Type(() => SimpleType)
  type: SimpleType | null = null;

  @Type(() => SimpleType)
  types: SimpleType[] = [];

  @Type(() => Segment)
  segments: Segment[] = [];
}

export class Comments {
  fieldboards: string = '';
  key_moments: string = '';
  near_goal: string = '';
  earned_media: string = '';
}

export class Property {
  id: number = -1;
  name: string = '';

  @Type(() => Property)
  property: Property | null = null;
}

export class Budgets {
  fieldboards: number = 0;
  key_moments: number = 0;
  near_goal: number = 0;
}
