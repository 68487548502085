import { Component, inject } from '@angular/core';
import {
  Validators,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { Observable } from 'rxjs';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  CrudFormComponent,
  CrudFormFooterComponent,
  CrudFormHeaderComponent,
  ImageControllerComponent,
} from '@admin/components';
import { CrudElement } from '@admin/models/CrudElement.model';

import { AlertComponent } from '@shared/components/alert/alert.component';
import { RouterLink } from '@angular/router';
import { SimpleElement } from '@admin/models/SimpleElement.model';
import { NgClass } from '@angular/common';
import { Broadcast } from '@admin/models/Campaign.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsFormComponent } from '@admin/pages';

@Component({
  selector: 'app-campaigns-form',
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatInputModule,
    MatRadioModule,

    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
    ImageControllerComponent,
  ],
  templateUrl: './campaigns-form.component.html',
  styleUrl: './campaigns-form.component.scss',
})
export class CampaignsFormComponent extends CrudFormComponent {
  protected modalService = inject(NgbModal);

  clients: CrudElement[] = [];
  properties: SimpleElement[] = [];

  override services: Observable<any>[] = [
    this.api.call('admin/clients?limit=1000'),
    this.api.call('admin/catalogs/properties'),
  ];
  override data = this.formBuilder.group({
    name: new FormControl<string | null>(null, Validators.required),
    clients: new FormControl<number[] | null>(null, Validators.required),
    totalBudget: [0],
    fieldboardsBudget: [0],
    keyMomentsBudget: [0],
    nearGoalBudget: [0],
    description: ['', [Validators.required]],
  });

  openNewClient() {
    const modalRef = this.modalService.open(ClientsFormComponent, {
      size: 'lg',
      scrollable: true,
    });

    modalRef.componentInstance.endpoint = 'admin/clients';
    modalRef.componentInstance.isComponent = true;

    modalRef.componentInstance.onAction.subscribe((result: boolean) => {
      modalRef.close();

      if (result) {
        this.load();
      }
    });
  }

  override handleLoadResponse(responses: any[]): void {
    if (responses[0] && responses[0].status === 200)
      this.clients = responses[0].data.rows;

    if (responses[1] && responses[1].status === 200)
      this.properties = responses[1].data;

    if (
      this.dataId() !== -1 &&
      responses[responses.length - 1] &&
      responses[responses.length - 1].status === 200
    ) {
      const responseData = responses[responses.length - 1].data;
      this.setParams(responseData);
    }
  }

  override onSuccess(response: any): void {
    if (this.dataId() === -1) {
      if (response.data?.id) {
        this.router.navigate(['/admin/campaigns/show', response.data?.id]);
      } else {
        this.data.reset();
      }
    }
  }

  // ! HADRCODED FROM SERVICE /catalogs/broadcasts, 1 total, 2 fieldboards, 3 key moments and 4 near goal
  override getParams() {
    const {
      totalBudget,
      fieldboardsBudget,
      keyMomentsBudget,
      nearGoalBudget,
      ...data
    } = this.data.value;

    const params = {
      ...data,
      broadcasts: [
        { broadcast_type_id: 1, amount: totalBudget },
        { broadcast_type_id: 2, amount: fieldboardsBudget },
        { broadcast_type_id: 3, amount: keyMomentsBudget },
        { broadcast_type_id: 4, amount: nearGoalBudget },
      ],
    };

    return params;
  }
  // ! HADRCODED FROM SERVICE /catalogs/broadcasts, 1 total, 2 fieldboards, 3 key moments and 4 near goal
  setParams(params: any) {
    const { broadcasts, clients, ...data } = params;

    const totalBudget =
      broadcasts.find((b: Broadcast) => b.broadcast_type_id === 1)?.amount || 0;
    const fieldboardsBudget =
      broadcasts.find((b: Broadcast) => b.broadcast_type_id === 2)?.amount || 0;
    const keyMomentsBudget =
      broadcasts.find((b: Broadcast) => b.broadcast_type_id === 3)?.amount || 0;
    const nearGoalBudget =
      broadcasts.find((b: Broadcast) => b.broadcast_type_id === 4)?.amount || 0;

    const formData: any = {
      ...data,
      totalBudget,
      fieldboardsBudget,
      keyMomentsBudget,
      nearGoalBudget,
    };

    if (clients) {
      formData['clients'] = clients.map((type: any) => type.id);
    }

    this.data.patchValue(formData);
    this.data.updateValueAndValidity();
  }
}
