import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { RolesService } from '@services/roles.service';
import { UserService } from '@services/user.service';

export const adminGuard: CanActivateFn = (route, state) => {
  return true;

  const roles = inject(RolesService).getUserRoles();
  const requiredRoles = route.data['requiredRoles'] ?? [];

  if (requiredRoles.length === 0) return true;

  if (roles.size === 0) {
    inject(UserService).clearSession();
    inject(Router).navigate(['/auth/login']);

    return false;
  }

  if (requiredRoles.every((role: string) => roles.has(role))) {
    return true;
  } else {
    inject(Router).navigate(['/admin']);
    return false;
  }
};
