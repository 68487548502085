import { Location, NgClass } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  ConfirmModalComponent,
  FormComponent,
  MainCardComponent,
  TitleComponent,
} from '@shared/components';
import { Campaign } from '@admin/models/Campaign.model';
import { SegmentType } from '@admin/models/Playoffs.model';
import { Property } from '@admin/models/CampaignType.model';
import { RolesService } from '@services/roles.service';

class Season {
  id: number = -1;
  name: string = '';
  comments: string = '';
  published: boolean = false;
  type_id: number = -1;
  type: SegmentType = new SegmentType();
}

const GROUP = 'admin.segments';

@Component({
  selector: 'app-segments',
  imports: [
    NgClass,
    NgSelectModule,
    FormsModule,
    MainCardComponent,
    TitleComponent,
  ],
  templateUrl: './segments.component.html',
  styleUrl: './segments.component.scss',
})
export class SegmentsComponent extends FormComponent {
  private modalService = inject(NgbModal);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private roles = inject(RolesService);
  private location = inject(Location);

  // * -1 = nothing state, 0 > index to edits
  editing: number = -1;
  creating: boolean = false;
  campaign: Campaign = new Campaign();
  nSeason: Season = new Season();
  title: string = 'CLIENT NAME / CAMPAIGN NAME';
  subtitle: string = '';
  properties: Property[] = [];
  type: string = '';
  items: Season[] = [];
  months: string[] = ['Liga MX', 'CDC', 'Mexican National Team'];

  campaignId: number = -1;
  clientId: number = -1;
  periodId: number = -1;
  typeId: number = -1;
  propertyId: number = -1;

  // ! ACTIONS CONTROLS
  canCreate = signal(false);
  canDelete = signal(false);
  canEdit = signal(false);
  canUpdate = signal(false);
  // ! ACTIONS CONTROLS

  ngOnInit(): void {
    this.roles.roles$.subscribe((loaded) => {
      if (loaded) {
        this.canCreate.set(this.roles.can(GROUP + '.create'));
        this.canEdit.set(this.roles.can(GROUP + '.edit'));
        this.canDelete.set(this.roles.can(GROUP + '.destroy'));
        this.canUpdate.set(this.roles.can(GROUP + '.update'));
      }
    });

    this.route.paramMap.subscribe((params) => {
      this.type = String(params.get('type') || '');
      this.type = decodeURIComponent(this.type).toUpperCase();
      this.campaignId = Number(params.get('id') ?? -1);
      this.clientId = Number(params.get('clientId') || -1);
      this.periodId = Number(params.get('periodId') ?? -1);
      this.typeId = Number(params.get('typeId') ?? -1);
      this.propertyId = Number(params.get('propertyId') ?? -1);

      console.debug(params);
      console.debug(this.type);

      const data = this.route.snapshot.data;
      this.subtitle = data['title'] || '';

      if (this.loading) return;

      this.services = [
        this.api.call(`admin/campaigns/${this.campaignId}`),
        this.api.call(
          `admin/campaigns/${this.campaignId}/segments?type_id=${this.typeId}&order=id|ASC`
        ),
      ];

      this.loadServices();
    });
  }

  override onLoadServicesSuccess(responses: any[]): void {
    if (responses[0].status === 200) {
      this.campaign = responses[0].data;

      this.title = `${
        this.campaign.clients.length > 0
          ? this.campaign.clients[0].name + '/'
          : ''
      } ${this.campaign.name}`;

      console.debug(this.campaign);
    }

    if (responses[1].status === 200 && responses[1].data.rows) {
      this.items = responses[1].data.rows.map((segment: any) => ({
        id: segment.id,
        name: segment.name || '',
        published: segment.published ? Boolean(segment.published) : false,
        comments: segment.comments || '',
        type: segment.type || new SegmentType(),
      }));
    }

    this.stopLoading();
  }

  override getParams() {
    this.nSeason.type_id = this.typeId;
    const params: any = { ...this.nSeason };

    if (this.nSeason.id === -1) {
      delete params['type'];
      this.method = 'post';
      this.endpoint = `admin/campaigns/${this.campaignId}/segments`;

      delete params.id;
    } else {
      this.method = 'put';
      this.endpoint = `admin/segments/${this.nSeason.id}`;
    }

    return params;
  }

  handlePrymary(season: Season) {
    if (this.editing !== -1) {
      if (this.editing !== season.id) {
        this.editMonth(season.id);

        return;
      }

      this.editing = -1;

      if (this.nSeason.id !== -1) {
        this.submit();
      }
    } else {
      this.router.navigate(
        [
          'segment',
          season.id, // Parámetro :segmentId
        ],
        {
          relativeTo: this.route,
        }
      );
    }
  }

  generalReport() {
    this.router.navigate([
      `/admin/campaigns/${this.campaignId}/general-report/period/${this.periodId}/type/${this.typeId}`,
    ]);
  }

  cancelNew() {
    this.creating = false;
    this.nSeason = new Season();
  }

  createMonth() {
    this.creating = true;
    this.editing = -1;
  }

  editMonth(id: number) {
    this.creating = false;
    this.nSeason = new Season();
    this.editing = this.editing === id ? -1 : id;

    if (this.editing !== -1) {
      this.nSeason = this.items.find((item) => item.id === id) || new Season();

      if (this.nSeason.id !== -1) {
        this.nSeason = { ...this.nSeason };
      }
    }
  }

  onEdit(ind: number) {
    this.items[ind] = this.nSeason;
    this.nSeason = new Season();
    this.editing = -1;
  }

  goBack(): void {
    const clientRoute = this.clientId !== -1 ? '/client/' + this.clientId : '';

    this.router.navigate([
      `/admin/campaigns/show/${this.campaignId}${clientRoute}/period/${this.periodId}/property/${this.propertyId}`,
    ]);
  }

  deleteModal(id: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      backdrop: 'static',
      centered: true,
      // backdrop: false,
    });

    modalRef.componentInstance.title = 'DELETE SEGMENT';
    modalRef.componentInstance.message =
      'Do you really want to delete the segment?';
    modalRef.result.then(
      (result: any) => {
        if (result) {
          this.deleteSegment(id);
        }
      },
      (reason) => {}
    );
  }

  deleteSegment(id: number) {
    if (this.loading) {
      return;
    }

    this.startLoading();

    this.api.call(`admin/segments/${id}`, 'delete', {}).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.handleAlert('Segment deleted successfully!', 'success');
        } else {
          this.handleAlert(
            Array.isArray(response.message)
              ? response.message.join(', ')
              : response.message
          );
        }

        this.loadServices();
      },
      error: (error) => {
        this.handleAlert('An unexpected error occurred!');
        console.error(error);
        this.stopLoading();
      },
    });
  }

  override isValid(): boolean {
    return !!this.nSeason.name;
  }

  override success(response: any): void {
    this.handleAlert(
      `¡${
        this.nSeason.id === -1 ? 'Item created ' : 'Changes saved'
      } successfully!`,
      'success'
    );
    this.cancelNew();

    this.loadServices();
  }
}
