import { Injectable } from '@angular/core';

import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (!value) return null;

    const date = value.split(this.DELIMITER);

    return {
      day: parseInt(date[1], 10),
      month: parseInt(date[0], 10),
      year: parseInt(date[2], 10),
    };
  }

  toModel(date: NgbDateStruct | null): string | null {
    if (!date) return null;

    // return date.month + this.DELIMITER + date.day + this.DELIMITER + date.year;

    const month = date.month.toString().padStart(2, '0');
    const day = date.day.toString().padStart(2, '0');

    return `${month}${this.DELIMITER}${day}${this.DELIMITER}${date.year}`;
  }
}
