import { Type } from 'class-transformer';

import { Campaign } from './Campaign.model';
import { CampaignSeason } from './Playoffs.model';

export class CampaignReport {
  id: number = -1;

  @Type(() => Campaign)
  campaign: Campaign = new Campaign();

  @Type(() => CampaignSeason)
  segments: CampaignSeason = new CampaignSeason();
}
