import { Component, computed, inject, signal, Signal } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Location, NgClass } from '@angular/common';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Campaign } from '@admin/models/Campaign.model';
import { SimpleElement } from '@admin/models/SimpleElement.model';
import {
  ConfirmModalComponent,
  FormComponent,
  MainCardComponent,
  TitleComponent,
} from '@shared/components';
import { fadeInAnimation } from '@shared/animations/fade.animation';
import { RolesService } from '@services/roles.service';

export class NewSegment {
  id: number = -1;
  property_id: number | null = null;
  property_other?: string;
  name: string = '';
}

const GROUP = 'admin.types';

@Component({
  selector: 'app-campaigns-show',
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,

    NgSelectModule,

    MainCardComponent,
    TitleComponent,
  ],
  animations: [fadeInAnimation],
  templateUrl: './campaigns-show.component.html',
  styleUrl: './campaigns-show.component.scss',
})
export class CampaignsShowComponent extends FormComponent {
  private location = inject(Location);
  private modalService = inject(NgbModal);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  protected roles = inject(RolesService);

  // * -1 = nothing state, 0 > item id to edit
  editing: number = -1;
  creating: boolean = false;
  campaign: Campaign = new Campaign();
  title: string = 'CLIENT NAME / CAMPAIGN NAME';
  subtitle: string = '';
  campaignId: number = -1;
  segmentId: number = -1;
  items: NewSegment[] = [];
  properties: SimpleElement[] = [];
  page: number = 1;
  limit: number = 11;

  nSegment = this.formBuilder.group({
    id: new FormControl<number>(-1, Validators.required),
    property_id: new FormControl<number | null>(null, Validators.required),
    property_other: new FormControl<string | null>(null),
  });

  // ! ACTIONS CONTROLS
  canCreate = signal(false);
  canDelete = signal(false);
  canEdit = signal(false);
  canUpdate = signal(false);
  // ! ACTIONS CONTROLS

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.campaignId = Number(params.get('id') ?? -1);
      this.segmentId = Number(params.get('segmentId') ?? -1);

      const data = this.route.snapshot.data;
      this.subtitle = data['title'] || '';

      if (this.loading) return;

      this.services = [
        this.api.call(`admin/campaigns/${this.campaignId}`),
        this.api.call(
          `admin/campaigns/${this.campaignId}/types?page=${this.page}&limit=${this.limit}`
        ),
        this.api.call('admin/catalogs/properties'),
      ];

      // ! DEBUG
      // console.log(params);
      // console.log('campaignId: ', this.campaignId);
      // console.log('segmentId: ', this.segmentId);
      // console.log('subtitle: ', this.subtitle);
      // console.log(this.endpoint);
      // ! DEBUG

      this.loadServices();
    });

    this.nSegment.get('property_id')?.valueChanges.subscribe((type) => {
      this.updateDateValidator(type);
    });

    this.roles.roles$.subscribe((loaded) => {
      if (loaded) {
        this.canCreate.set(this.roles.can(GROUP, 'create'));
        this.canEdit.set(this.roles.can(GROUP, 'edit'));
        this.canDelete.set(this.roles.can(GROUP, 'destroy'));
        this.canUpdate.set(this.roles.can(GROUP, 'update'));
      }
    });
  }

  private updateDateValidator(type: number | null): void {
    const propertyControl = this.nSegment.get('property_other');

    propertyControl?.clearValidators();

    if (type === -1) {
      propertyControl?.setValidators([Validators.required]);
    }

    propertyControl?.updateValueAndValidity();
  }

  override getServices(): Observable<any>[] {
    return [
      this.api.call(`admin/campaigns/${this.campaignId}`),
      this.api.call(
        `admin/campaigns/${this.campaignId}/types?page=${this.page}&limit=${this.limit}`
      ),
    ];
  }

  // /admin/campaigns/1/segments
  override onLoadServicesSuccess(responses: any[]): void {
    if (responses[2] && responses[2].status === 200)
      this.properties = responses[2].data;

    if (responses[0].status === 200) {
      this.campaign = responses[0].data;

      this.title = `${
        this.campaign.clients.length > 0
          ? this.campaign.clients[0].name + ' / '
          : ''
      }${this.campaign.name}`;
      this.subtitle = this.campaign.property_other
        ? this.campaign.property_other
        : this.properties.find((prop) => prop.id === this.campaign.property_id)
            ?.name || '';
    }

    if (responses[1].status === 200 && responses[1].data.rows) {
      const items = responses[1].data.rows.map((item: any) => {
        return {
          id: item.id,
          name: item.property_id
            ? this.findProperty(item.property_id)
            : item.property_other || '',
        };
      });

      if (items.length === 0) {
        this.page = this.page > 1 ? this.page - 1 : 0;
      }

      if (this.page == 1) {
        this.items = items;
      } else {
        this.items = [...this.items, ...items];
      }

      // ! DEBUG
      // console.log(this.campaign);
      // console.log(this.data);
      // console.log(this.items);
      // ! DEBUG
    }

    this.stopLoading();
  }

  findProperty(id: number): string {
    return this.properties.find((p) => p.id === id)?.name || '';
  }

  loadMore() {
    this.page += 1;
    this.loadServices();
  }

  override getParams() {
    const params: any = this.nSegment.value;

    if (params.property_id === -1) {
      params.property_id = null;
    }

    if (params.id === -1) {
      this.method = 'post';
      this.endpoint = `admin/campaigns/${this.campaignId}/types`;

      delete params.id;
    } else {
      this.method = 'put';
      this.endpoint = `admin/types/${params.id}`;
    }

    return params;
  }

  override isValid(): boolean {
    return this.nSegment.valid;
  }

  handlePrymary(item: NewSegment) {
    if (this.editing !== -1) {
      if (this.editing !== item.id) {
        this.editType(item.id);

        return;
      }

      this.editing = -1;

      if (this.nSegment.get('id')?.value !== -1) {
        this.submit();
      }
    } else {
      const property =
        item.property_id !== -1
          ? this.properties.find((p) => p.id === item.property_id)?.name
          : item.property_other;

      this.router.navigate([
        `admin/campaigns/${this.campaignId}/${encodeURIComponent(
          property || 'type'
        )}/${item.id}`,
      ]);
    }
  }

  generalReport() {
    this.router.navigate([
      `/admin/campaigns/${this.campaignId}/general-report`,
    ]);
  }

  cancelNew() {
    this.creating = false;
    this.nSegment.reset();
  }

  createType() {
    this.creating = true;
    this.editing = -1;
  }

  editType(id: number) {
    this.creating = false;
    this.nSegment.reset();
    this.editing = this.editing === id ? -1 : id;

    if (this.editing !== -1) {
      const nItem =
        this.items.find((item) => item.id === id) || new NewSegment();

      if (nItem.id !== -1) {
        this.nSegment.patchValue(nItem);
      }
    }
  }

  onEdit(ind: number) {
    this.items[ind] = this.nSegment.value as NewSegment;
    this.nSegment.reset();
    this.editing = -1;
  }

  deleteModal(id: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      backdrop: 'static',
      centered: true,
      // backdrop: false,
    });

    modalRef.componentInstance.title = 'DELETE TYPE';
    modalRef.componentInstance.message =
      'Do you really want to delete the type?';
    modalRef.result.then(
      (result: any) => {
        if (result) {
          this.deleteSegment(id);
        }
      },
      (reason) => {}
    );
  }

  deleteSegment(id: number) {
    if (this.loading) {
      return;
    }

    this.startLoading();

    this.api.call(`admin/types/${id}`, 'delete', {}).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.handleAlert('Type deleted successfully!', 'success');
        } else {
          this.handleAlert(
            Array.isArray(response.message)
              ? response.message.join(', ')
              : response.message
          );
        }

        this.loadServices();
      },
      error: (error) => {
        this.handleAlert('An unexpected error occurred!');
        console.error(error);
        this.stopLoading();
      },
    });
  }

  override success(response: any): void {
    this.handleAlert(
      `¡${
        this.nSegment.get('id')?.value === -1
          ? 'Item created '
          : 'Changes saved'
      } successfully!`,
      'success'
    );
    this.endpoint = '';
    this.cancelNew();

    this.loadServices();
  }

  goBack(): void {
    this.location.back();
  }
}
