// USED AS MENUS TO SIDEBAR

import { Main } from '@shared/models/Main.model';
import { Type } from 'class-transformer';

export class MenuItem extends Main {
  id: number = -1;
  collapse: boolean = true;
  icon: string = '';
  permission: string = '';
  route: string = '';
  separator: boolean = true;
  title: string = '';

  @Type(() => MenuChild)
  children: MenuChild[] = [];
}

export class MenuChild {
  title: string = '';
  icon: string = '';
  route: string = '';
  permission: string = '';
}
