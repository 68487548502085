import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

import { Menu } from '@admin/models/Menu.model';
import { FormComponent, MainCardComponent } from '@shared/components';
import { Profile } from '@admin/models/Profile.model';
import { UserService } from '@services/user.service';
import { plainToClass } from 'class-transformer';

@Component({
  selector: 'app-home',
  imports: [MainCardComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent extends FormComponent {
  private router = inject(Router);
  private user = inject(UserService);

  override data: Profile = new Profile();

  items: Menu[] = [
    {
      id: 2,
      title: 'Recent Campaign',
      route: '/admin/campaigns',
      actions: 'Continue with',
      icon: 'fa-solid fa-folder',
    },
    {
      id: 3,
      title: 'New Client',
      route: '/admin/clients/create',
      actions: 'Add',
      icon: 'fa-solid fa-user-plus',
    },
    {
      id: 4,
      title: 'New Campaign',
      route: '/admin/campaigns/create',
      actions: 'Start',
      icon: 'fa-solid fa-folder-plus',
    },
  ];

  // private api: ApiService,
  // private loading: LoadingService

  ngOnInit() {
    this.user.profile$.subscribe(() => {
      if (this.user.profile) {
        this.data = plainToClass(Profile, this.user.profile);
      }
    });
  }

  goTo(route?: string) {
    this.router.navigate([`${route}`]);
  }
}
