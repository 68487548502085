<div class="px-0" [ngClass]="titleContainer">
  <app-title
    [title]="title"
    [titleContainer]="titleContainer"
    [description]="description"
  ></app-title>

  <div class="row align-items-center mx-0 pb-5">
    @if (search) {
    <div class="col-12 px-0 mt-3">
      <app-search-bar
        [canCreate]="canCreate()"
        [createRoute]="createRoute"
        [name]="name"
        [filters]="filters"
        (onSearch)="handleSearch($event)"
      ></app-search-bar>
    </div>
    } @else if(canCreate()) {
    <div class="col-lg-auto ms-auto mt-3">
      <a
        class="btn btn-primary px-4"
        [routerLink]="['/' + createRoute + '/create']"
      >
        New {{ name }}
      </a>
    </div>
    }
  </div>
</div>
