<app-title
  [title]="title"
  [subtitle]="reportType"
  [description]="data.campaign.description"
>
  <div class="row py-3">
    <div class="col-auto ms-auto">
      <button type="button" class="btn btn-secondary px-4" (click)="back()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>

      <button
        type="button"
        class="btn btn-primary ms-2"
        (click)="handleAction(0)"
      >
        DOWNLOAD
      </button>

      <button
        type="button"
        class="btn btn-secondary ms-2"
        (click)="handleAction(1)"
      >
        SAVE
      </button>
    </div>
  </div>
</app-title>

<!-- ! DEBUG -->
<!-- <div class="row">
  <div class="col-12">
    <span class="badge text-bg-warning">
      periodId: {{ periodId }} // typeId: {{ typeId }}
    </span>
  </div>
</div> -->
<!-- ! DEBUG -->

@if(initialized) {
<div id="report" class="container py-3 pb-lg-4">
  <app-report [budgets]="budgets" [data]="data" [typeId]="typeId"></app-report>
</div>
}
<!-- [playoffs]="playoffs"
    [regularSeason]="regularSeason" -->
