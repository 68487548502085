<app-title titleContainer="container" title="Terms and Conditions"></app-title>

<div class="container py-3 pb-lg-4">
  <hr class="border-primary border-2 my-3 my-lg-4" />

  <div class="row pt-3">
    <div class="col-12">
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Blanditiis
        quasi excepturi quibusdam voluptatem est assumenda quas modi, asperiores
        cumque, itaque repellat sit provident omnis beatae expedita mollitia
        doloribus ipsum eveniet? Lorem ipsum dolor sit amet consectetur,
        adipisicing elit. Blanditiis quasi excepturi quibusdam voluptatem est
        assumenda quas modi, asperiores cumque, itaque repellat sit provident
        omnis beatae expedita mollitia doloribus ipsum eveniet? Lorem ipsum
        dolor sit amet consectetur, adipisicing elit. Blanditiis quasi excepturi
        quibusdam voluptatem est assumenda quas modi, asperiores cumque, itaque
        repellat sit provident omnis beatae expedita mollitia doloribus ipsum
        eveniet? Lorem ipsum dolor sit amet consectetur, adipisicing elit.
        Blanditiis quasi excepturi quibusdam voluptatem est assumenda quas modi,
        asperiores cumque, itaque repellat sit provident omnis beatae expedita
        mollitia doloribus ipsum eveniet? Lorem ipsum dolor sit amet
        consectetur, adipisicing elit. Blanditiis quasi excepturi quibusdam
        voluptatem est assumenda quas modi, asperiores cumque, itaque repellat
        sit provident omnis beatae expedita mollitia doloribus ipsum eveniet?
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Blanditiis
        quasi excepturi quibusdam voluptatem est assumenda quas modi, asperiores
        cumque, itaque repellat sit provident omnis beatae expedita mollitia
        doloribus ipsum eveniet?
      </p>
    </div>
  </div>
</div>
