import { Component, Input } from '@angular/core';

import { TableReport } from '@admin/models/TableReport.model';
import { CampaignReport } from '@admin/models/CampaignReport.model';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-report',
  standalone: true,
  imports: [FormsModule, CurrencyPipe, DecimalPipe],
  templateUrl: './report.component.html',
  styleUrl: './report.component.scss',
})
export class ReportComponent {
  @Input() data: CampaignReport = new CampaignReport();
  @Input() sections: TableReport[] = [];
  @Input() readonly: boolean = true;

  summary = {
    units: {
      contracted: 0,
      delivered: 0,
      variance: 0,
    },
    impressions: {
      contracted: 0,
      delivered: 0,
      variance: 0,
    },
    cpm: {
      contracted: 0,
      delivered: 0,
      variance: 0,
    },
    description: '',
  };

  ngOnInit(): void {
    this.initTables();
  }

  calculateSummary() {
    const [
      fieldboardSummaryUnitsContracted,
      fieldboardSummaryUnitsDelivered,
      fieldboardSummaryImpressionsContracted,
      fieldboardSummaryImpressionsDelivered,
      fieldboardInfoUnitsContracted,
      fieldboardInfoUnitsDelivered,

      keyMomentsSummaryUnitsContracted,
      keyMomentsSummaryUnitsDelivered,
      keyMomentsSummaryImpressionsContracted,
      keyMomentsSummaryImpressionsDelivered,
      keyMomentsInfoUnitsContracted,
      keyMomentsInfoUnitsDelivered,

      nearGoalInfoContracted,
      nearGoalInfoDeliverred,
      nearGoalSummaryImpressionsContracted,
      nearGoalSummaryImpressionsDelivered,

      impressionsContracted,
      impressionsDelivered,
      unitsContracted,
      unitsDelivered,
    ] = this.data.segments.reduce(
      (acc, season) => {
        return [
          acc[0] + season.data.fieldboard.summary.unitsContracted,
          acc[1] + season.data.fieldboard.summary.unitsDelivered,
          acc[2] + season.data.fieldboard.summary.impressionsDelivered,
          acc[3] + season.data.fieldboard.summary.impressionsContracted,
          acc[4] + season.data.fieldboard.info.contracted,
          acc[5] + season.data.fieldboard.info.delivered,

          acc[6] + season.data.keyMoments.info.variance,
          acc[6] + season.data.keyMoments.info.variance,
          acc[6] + season.data.keyMoments.info.variance,
          acc[6] + season.data.keyMoments.info.variance,
          acc[6] + season.data.keyMoments.info.variance,
          acc[6] + season.data.keyMoments.info.variance,

          acc[6] + season.data.nearGoal.info.variance,
          acc[6] + season.data.nearGoal.info.variance,
          acc[6] + season.data.nearGoal.info.variance,
          acc[6] + season.data.nearGoal.info.variance,

          acc[6] + season.impressionsContracted,
          acc[6] + season.impressionsDelivered,
          acc[6] + season.unitsContracted,
          acc[6] + season.unitsDelivered,
        ];
      },
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    );

    // * UNITS
    this.summary.units.contracted =
      fieldboardSummaryUnitsContracted +
      keyMomentsSummaryUnitsContracted +
      nearGoalInfoContracted +
      unitsContracted;

    this.summary.units.delivered =
      fieldboardSummaryUnitsDelivered +
      keyMomentsSummaryUnitsDelivered +
      unitsDelivered;

    this.summary.units.variance =
      this.summary.units.delivered - this.summary.units.contracted;
    // * UNITS

    // * IMPRESSIONS
    this.summary.impressions.contracted =
      fieldboardSummaryImpressionsContracted +
      keyMomentsSummaryImpressionsContracted +
      nearGoalSummaryImpressionsContracted +
      impressionsContracted;

    this.summary.impressions.delivered =
      fieldboardSummaryImpressionsDelivered +
      keyMomentsSummaryImpressionsDelivered +
      impressionsDelivered;

    this.summary.impressions.variance =
      this.summary.impressions.delivered - this.summary.impressions.contracted;
    // * IMPRESSIONS

    // * CPM
    if (this.data.campaign.broadcasts[0]) {
      this.summary.cpm.contracted =
        this.summary.impressions.contracted > 0
          ? (this.data.campaign.broadcasts[0].amount /
              this.summary.impressions.contracted) *
            1000
          : 0;

      this.summary.cpm.contracted =
        this.summary.impressions.delivered > 0
          ? (this.data.campaign.broadcasts[0].amount /
              this.summary.impressions.delivered) *
            1000
          : 0;

      this.summary.cpm.variance =
        this.summary.cpm.delivered - this.summary.cpm.contracted;
    }
    // * CPM
  }

  initTables() {
    this.calculateSummary();

    const keyMoments = this.calculateKeyMoments();
    this.sections.push(keyMoments);

    const nearGoal = this.calculateNearGoal();
    this.sections.push(nearGoal);

    const earnedMedia = this.calculateEarnedMedia();
    this.sections.push(earnedMedia);
  }

  calculateFieldboards(): TableReport {
    const nTable = new TableReport();
    nTable.comentControl = 'fieldboards';

    const fieldboardsBudget = this.data.campaign.broadcasts[1].amount || 0;

    const [
      unitsContracted,
      unitsDelivered,
      impressionsDelivered,
      impressionsContracted,
    ] = this.data.segments.reduce(
      (acc, season) => {
        return [
          acc[0] + season.unitsContracted,
          acc[1] + season.unitsDelivered,
          acc[2] + season.impressionsDelivered,
          acc[3] + season.impressionsContracted,
        ];
      },
      [0, 0, 0, 0]
    );

    nTable.title = `FIELDBOARDS`;
    nTable.cols = ['Totals', 'Contracted', 'Delivered', 'Variance'];
    nTable.rows = [
      [
        'Units',
        unitsContracted,
        unitsDelivered,
        unitsContracted - unitsDelivered,
      ],
      [
        'Impressions',
        impressionsContracted,
        impressionsDelivered,
        impressionsDelivered - impressionsContracted,
      ],
      [
        'CPM',
        impressionsContracted > 0
          ? (fieldboardsBudget / impressionsContracted) * 1000
          : 0,
        impressionsDelivered > 0
          ? (fieldboardsBudget / impressionsDelivered) * 1000
          : 0,
        (impressionsContracted > 0
          ? (fieldboardsBudget / impressionsContracted) * 1000
          : 0) -
          (impressionsDelivered > 0
            ? (fieldboardsBudget / impressionsDelivered) * 1000
            : 0),
      ],
    ];

    nTable.comments = this.data.campaign.description;

    return nTable;
  }

  calculateKeyMoments(): TableReport {
    const nTable = new TableReport();
    nTable.comentControl = 'key-moments';

    const [
      summaryUnitsContracted,
      summaryUnitsDelivered,
      summaryImpressionsDelivered,
      summaryImpressionsContracted,
      infoContracted,
      infoDelivered,
      infoVariance,
    ] = this.data.segments.reduce(
      (acc, season) => {
        return [
          acc[0] + season.data.keyMoments.summary.unitsContracted,
          acc[1] + season.data.keyMoments.summary.unitsDelivered,
          acc[2] + season.data.keyMoments.summary.impressionsDelivered,
          acc[3] + season.data.keyMoments.summary.impressionsContracted,

          acc[4] + season.data.keyMoments.info.contracted,
          acc[5] + season.data.keyMoments.info.delivered,
          acc[6] + season.data.keyMoments.info.variance,
        ];
      },
      [0, 0, 0, 0, 0, 0, 0]
    );

    nTable.title = `KEY MOMENTS`;
    nTable.cols = ['Totals', 'Contracted', 'Delivered', 'Variance'];
    nTable.rows = [
      [
        'Units',
        summaryUnitsContracted,
        summaryUnitsDelivered,
        summaryUnitsDelivered - summaryUnitsContracted,
      ],
      [
        'Impressions',
        summaryImpressionsContracted,
        summaryImpressionsDelivered,
        summaryImpressionsDelivered - summaryImpressionsContracted,
      ],
      ['CPM', infoContracted, infoDelivered, infoVariance],
    ];
    nTable.comments = this.data.campaign.description;

    return nTable;
  }

  calculateNearGoal(): TableReport {
    const nTable = new TableReport();
    nTable.comentControl = 'near-goal';

    const [
      summaryImpressionsDelivered,
      summaryImpressionsContracted,
      summaryUnitsReceived,
    ] = this.data.segments.reduce(
      (acc, season) => {
        return [
          acc[0] + season.data.nearGoal.summary.impressionsDelivered,
          acc[1] + season.data.nearGoal.summary.impressionsContracted,
          acc[2] + season.data.nearGoal.summary.unitsReceived,
        ];
      },
      [0, 0, 0]
    );

    const [infoContracted, infoDelivered, infoVariance] =
      this.data.segments.reduce(
        (acc, season) => {
          return [
            acc[0] + season.data.nearGoal.info.contracted,
            acc[1] + season.data.nearGoal.info.delivered,
            acc[2] + season.data.nearGoal.info.variance,
          ];
        },
        [0, 0, 0]
      );

    nTable.title = `NEAR GOAL`;
    nTable.cols = ['Totals', 'Contracted', 'Delivered', 'Variance'];
    nTable.rows = [
      ['Units', summaryUnitsReceived, '-', '-'],
      [
        'Impressions',
        summaryImpressionsContracted,
        summaryImpressionsDelivered,
        summaryImpressionsDelivered - summaryImpressionsContracted,
      ],
      ['CPM', infoContracted, infoDelivered, infoVariance],
    ];
    nTable.comments = this.data.campaign.description;

    return nTable;
  }

  calculateEarnedMedia(): TableReport {
    const nTable = new TableReport();
    nTable.comentControl = 'earned-media';

    const [summaryTotalViews] = this.data.segments.reduce(
      (acc, season) => {
        return [acc[0] + season.data.earnedMedia.summary.totalViews];
      },
      [0]
    );

    nTable.title = `EARNED MEDIA`;
    nTable.cols = ['Totals', 'Delivered'];
    nTable.rows = [['Impressions', summaryTotalViews]];
    nTable.comments = this.data.campaign.description;

    return nTable;
  }

  // calculatePlayoffs(): TableReport {
  //   const playoffsBudget = this.data.campaign.broadcasts[0].amount;
  //   const nTable = new TableReport();
  //   const [
  //     unitsContracted,
  //     unitsDelivered,
  //     impressionsDelivered,
  //     impressionsContracted,
  //   ] = this.data.segments.reduce(
  //     (acc, season) => {
  //       return [
  //         acc[0] + season.unitsContracted,
  //         acc[1] + season.unitsDelivered,
  //         acc[2] + season.impressionsDelivered,
  //         acc[3] + season.impressionsContracted,
  //       ];
  //     },
  //     [0, 0, 0, 0]
  //   );

  //   nTable.title = `PLAYOFFS`;
  //   nTable.cols = ['Totals', 'Contracted', 'Delivered', 'Variance'];
  //   nTable.rows = [
  //     [
  //       'Units',
  //       unitsContracted,
  //       unitsDelivered,
  //       unitsDelivered - unitsContracted,
  //     ],
  //     [
  //       'Impressions',
  //       impressionsContracted,
  //       impressionsDelivered,
  //       impressionsDelivered - impressionsContracted,
  //     ],
  //     [
  //       'CPM',
  //       impressionsContracted > 0
  //         ? (playoffsBudget / impressionsContracted) * 1000
  //         : 0,
  //       impressionsDelivered > 0
  //         ? (playoffsBudget / impressionsDelivered) * 1000
  //         : 0,
  //       (impressionsContracted > 0
  //         ? (playoffsBudget / impressionsContracted) * 1000
  //         : 0) -
  //         (impressionsDelivered > 0
  //           ? (playoffsBudget / impressionsDelivered) * 1000
  //           : 0),
  //     ],
  //   ];
  //   nTable.comments = this.data.campaign.description;

  //   return nTable;
  // }
}
