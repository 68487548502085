import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';

import { fadeInAnimation } from '@shared/animations/fade.animation';
import { ImageControllerComponent } from '@admin/components';
import { FormComponent, TitleComponent } from '@shared/components';
import { AlertComponent } from '@shared/components/alert/alert.component';

@Component({
  selector: 'app-profile',
  imports: [
    NgClass,
    ReactiveFormsModule,
    AlertComponent,
    ImageControllerComponent,
    TitleComponent,
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
  animations: [fadeInAnimation],
})
export class ProfileComponent extends FormComponent {
  override endpoint: string = 'admin/profile/me';
  override method: string = 'put';
  override data = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    name: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    avatar: '',
    password: [''],
    confirm: [''],
  });

  currentTemplate = {
    name: '',
    file: '',
  };
  passwordEditing: boolean = false;
  matchPassword: boolean = true;
  passwordVisible: boolean = false;

  ngOnInit(): void {
    this.startLoading();

    this.load();
  }

  load() {
    this.api.call('admin/profile/me').subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.data.patchValue(response.data);
        }

        this.currentTemplate.file = this.data.get('avatar')?.value || '';

        this.initialized = true;

        this.stopLoading();
      },
      error: (error) => {
        console.error(error);
        this.stopLoading();
      },
    });
  }

  editPassword() {
    this.passwordEditing = !this.passwordEditing;

    if (this.passwordEditing) {
      this.addPasswordValidators();
    } else {
      this.removePasswordValidators();
    }
  }

  addPasswordValidators() {
    this.data.get('password')?.setValidators(Validators.required);
    this.data.get('confirm')?.setValidators(Validators.required);

    this.data.updateValueAndValidity();
  }

  removePasswordValidators() {
    this.data.get('password')?.clearValidators();
    this.data.get('confirm')?.clearValidators();

    this.data.updateValueAndValidity();
  }

  override getParams() {
    const params: any = this.data.value;

    if (!this.data.get('password')?.value) {
      delete params['password'];
      delete params['confirm'];
    }

    return params;
  }

  override isValid(): boolean {
    this.data.markAllAsTouched();

    this.matchPassword =
      this.data.get('password')?.value === this.data.get('confirm')?.value;

    const valid = this.data.valid && this.matchPassword;

    return valid;
  }

  override success(response: any): void {
    this.handleAlert('Cambios guardados correctamnete', 'success');
    this.stopLoading();
  }

  selectFile(file: any) {
    if (this.loadingService.getLoading()) return;

    this.startLoading();

    if (file) {
      this.currentTemplate.name = file.name;
      this.currentTemplate.file = file.contents;
      this.handleFile();
      this.stopLoading();
    }
  }

  handleFile() {
    if (this.currentTemplate.file) {
      this.data.get('avatar')?.setValue(this.currentTemplate.file);
    }
  }
}
