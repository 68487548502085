import { formatNumber, NgClass } from '@angular/common';
import {
  Component,
  computed,
  effect,
  forwardRef,
  input,
  output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-custom-input-number',
  imports: [FormsModule, NgClass],
  template: `
    <div class="input-group">
      @if(addon()) {
      <span class="input-group-text">$</span>
      }

      <input
        [type]="inputFocus ? 'number' : 'text'"
        [min]="inputFocus ? 0 : null"
        class="form-control text-center"
        [ngClass]="{ 'is-invalid': invalid() }"
        [id]="idC()"
        [placeholder]="placeholder()"
        [(ngModel)]="formattedValue"
        (ngModelChange)="onInputChange($event)"
        (focus)="onFocus()"
        (blur)="onBlur()"
        [readOnly]="readOnly()"
      />
    </div>
  `,
  styleUrl: './custom-input-number.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomInputNumberComponent),
      multi: true,
    },
  ],
})
export class CustomInputNumberComponent implements ControlValueAccessor {
  addon = input<string>();
  id = input<string>();
  invalid = input<boolean>();
  readOnly = input<boolean>();
  data = input<number>(0);
  placeholder = input<string>();
  onDataChange = output<number>();

  idC = computed(() => {
    if (this.id()) {
      return this.id();
    }

    if (this.placeholder()) {
      return this.placeholder()?.replaceAll(' ', '-').toLowerCase();
    }

    return '';
  });

  inputFocus: boolean = false;

  private _value: number = 0;

  constructor() {
    effect(() => {
      if (!this.data()) return;

      this._value = this.data();
    });
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  get formattedValue(): string {
    return this.inputFocus
      ? this._value.toString()
      : formatNumber(this._value, 'en-US', '1.0-0');
  }

  set formattedValue(value: string) {
    const numericValue = parseFloat(value.replace(/,/g, ''));

    if (!isNaN(numericValue)) {
      this._value = numericValue;
      this.onChange(this._value);
      this.onDataChange.emit(this._value);
    }
  }

  onInputChange(value: string) {
    this.formattedValue = value;
  }

  onFocus() {
    this.inputFocus = true;
  }

  onBlur() {
    this.inputFocus = false;
    this.onTouched();
  }

  // Métodos de ControlValueAccessor
  writeValue(value: number): void {
    this._value = value || 0;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Implementar si es necesario
  }
}
