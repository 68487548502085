import { inject } from '@angular/core';
import { CanActivateFn, CanActivateChildFn, Router } from '@angular/router';
import { UserService } from '@services/user.service';

export const privateGuard: CanActivateFn = (route, state) => {
  const authorized = inject(UserService).logged;

  if (!authorized) {
    inject(Router).navigate(['/auth']);
    return false;
  }

  return true;
};

export const privateGuardChild: CanActivateChildFn = (route, state) => {
  const authorized = inject(UserService).logged;

  if (authorized) {
    return true;
  } else {
    inject(Router).navigate(['/auth']);
    return false;
  }
};
