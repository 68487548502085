<app-crud-form-header
  [id]="dataId()"
  title="role"
  [disable]="readonly"
  [modal]="isComponent"
></app-crud-form-header>

<div [ngClass]="isComponent ? 'modal-body' : 'container py-3 py-lg-4 py-xl-5'">
  <form [formGroup]="data" (ngSubmit)="submit()" class="row gy-3">
    <div class="col-12">
      <label for="name" class="form-label">Name</label>

      <input
        id="name"
        name="name"
        type="text"
        class="form-control"
        formControlName="name"
      />

      @if (data.get('name')?.invalid && (data.get('name')?.dirty ||
      data.get('name')?.touched) ) {
      <div id="validationName" class="invalid-feedback">Name is required.</div>
      }
    </div>

    @for (permission of permissions; track $index) {
    <div class="col-lg-6 col-xl-4 col-xxl-3">
      <div class="form-check">
        <input
          [id]="'permission-' + permission.id"
          class="form-check-input"
          type="checkbox"
          [value]="permission.id"
          (click)="handleId(permission.id)"
          [checked]="ids.has(permission.id)"
          [disabled]="readonly"
        />
        <!-- [checked]="data.get('permissions')?.value?.includes(permission.id)" -->

        <label class="form-check-label" [for]="'permission-' + permission.id">
          {{ permission.name }}
        </label>
      </div>
    </div>
    }

    <app-alert [content]="alertData"></app-alert>

    <div class="w-100 mt-0"></div>

    @if (!isComponent) {
    <div class="col-12 px-0">
      <app-crud-form-footer
        [created]="created"
        [disable]="readonly"
        [modal]="isComponent"
        [loading]="loadingService.getLoading()"
        (cancel)="cancel()"
      ></app-crud-form-footer>
    </div>
    }
  </form>
</div>

@if (isComponent) {
<app-crud-form-footer
  [created]="created"
  [disable]="readonly"
  [modal]="isComponent"
  [loading]="loadingService.getLoading()"
  (onSubmit)="submit()"
  (cancel)="cancel()"
></app-crud-form-footer>
}
