import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-edit',
  standalone: true,
  imports: [FormsModule, NgClass],
  templateUrl: './video-edit.component.html',
  styleUrl: './video-edit.component.scss',
})
export class VideoEditComponent {
  @Input() video: string = '';
  @Output() onVideoChange: EventEmitter<string> = new EventEmitter();

  editingVideo: boolean = false;
  embebed: SafeResourceUrl | null = null;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.video) {
      this.handleEmbed();
    } else {
      this.editingVideo = true;
    }
  }

  editVideo() {
    if (this.editingVideo) {
      if (!this.video) return;

      this.handleEmbed();

      this.editingVideo = false;
    } else {
      this.editingVideo = true;
    }
  }

  handleEmbed() {
    let videoId = null;

    if (this.video.includes('youtube')) {
      videoId = this.extractVideoId(this.video, 'youtube');

      if (videoId) {
        const url = `https://www.youtube.com/embed/${videoId}`;
        this.embebed = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    } else if (this.video.includes('vimeo')) {
      const videoId = this.extractVideoId(this.video, 'vimeo');

      if (videoId) {
        const url = `https://player.vimeo.com/video/${videoId}`;
        this.embebed = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    }
  }

  extractVideoId2(url: string): string | null {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  // Función para extraer el ID del video
  extractVideoId(url: string, platform: string): string | null {
    let videoId: string | null = null;

    if (platform === 'youtube') {
      // Extracción para YouTube
      const regex =
        /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      const matches = url.match(regex);
      videoId = matches ? matches[1] : null;
    } else if (platform === 'vimeo') {
      // Extracción para Vimeo
      const regex = /vimeo\.com\/(?:video\/)?(\d+)/;
      const matches = url.match(regex);
      videoId = matches ? matches[1] : null;
    }

    return videoId;
  }
}
