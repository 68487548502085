<div class="row align-items-center">
  <div [ngClass]="disable ? 'col-12' : 'col-lg-7 mb-4 mb-lg-0 px-0'">
    <div class="row align-items-center">
      <div class="col-lg-4">
        <img
          [src]="template.contents"
          class="img-fluid img-controller"
          [alt]="alt"
          onerror="this.onerror=null; this.src='assets/images/logo.png'"
        />
      </div>

      <div class="col">
        @if (title) {
        <p class="fw-bold mb-1">{{ title }}</p>
        } @if (label) {
        <p class="lh-1 mb-0">{{ label }}</p>
        }
      </div>
    </div>
  </div>

  @if ( !disable ) {
  <div class="col-lg-5">
    <div class="input-group mb-3">
      <button
        id="image-selector"
        class="btn btn-secondary text-light px-lg-3 px-xl-4 px-xxl-5"
        type="button"
        (click)="fileInput.click()"
      >
        SELECT
      </button>

      <input
        type="text"
        class="form-control"
        aria-describedby="image-selector"
        [value]="template.name || 'Choose an image...'"
        [readOnly]="true"
        [disabled]="true"
      />

      <input
        #fileInput
        type="file"
        hidden
        (change)="onFileSelected($event)"
        [accept]="fileTypes"
      />
    </div>
  </div>
  }
</div>
