import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { MenuItem } from '@admin/models/MenuItem.model';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-sidebar',
  imports: [
    NgClass,
    RouterModule,
    NgbCollapseModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  items = input<MenuItem[]>([]);

  collapsed: boolean = true;
  checkHavePermission: boolean = true;

  constructor(private router: Router, protected user: UserService) {}

  logout() {
    this.user.clearSession();
    this.collapsed = true;
    this.router.navigate(['/auth/login']);
  }
}
