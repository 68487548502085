import { Component } from '@angular/core';
import { TitleComponent } from '@shared/components';

@Component({
    selector: 'app-privacy',
    imports: [TitleComponent],
    templateUrl: './privacy.component.html',
    styleUrl: './privacy.component.scss'
})
export class PrivacyComponent {}
