import { Component, ElementRef, input, output, ViewChild } from '@angular/core';

import { forkJoin, Observable, Observer } from 'rxjs';

// ! 5 MB
const MB_SIZE = 1024 * 1024 * 5;

@Component({
  selector: 'app-media-edit',
  imports: [],
  templateUrl: './media-edit.component.html',
  styleUrl: './media-edit.component.scss',
})
export class MediaEditComponent {
  src = input('');
  template: File | string = '';
  preview: any = '';

  onError = output<string>();
  onSelectFile = output<any>();

  @ViewChild('fileInput') fileInput!: ElementRef;

  ngOnInit(): void {
    if (this.src) {
      this.preview = this.src();
    }
  }

  reset(inputRef: HTMLInputElement) {
    inputRef.value = '';
  }

  clearFileInput(): void {
    this.template = '';
    this.fileInput.nativeElement.value = '';
  }

  selectFile() {
    this.fileInput?.nativeElement.click();
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;

    if (files instanceof FileList) {
      const allFilesValid = Array.from(files).every((file) => {
        return file.size <= MB_SIZE;
      });

      if (!allFilesValid) {
        this.onError.emit('El archivo supera el tamaño máximo permitido');
        return;
      }

      console.debug(files);

      this.convertFilesToBase64(files).subscribe({
        next: (base64Array: File[]) => {
          for (let i = 0; i < base64Array.length; i++) {
            this.template = base64Array[i];
            this.preview = URL.createObjectURL(base64Array[i]);
          }
          this.onSelectFile.emit(this.template);
        },
        error: (error: any) => {
          // Manejo del error
          console.error(error);
          this.onError.emit(error);
        },
      });
    }
  }

  remove(inputElement: HTMLInputElement, index: number) {
    if (inputElement.files) {
      this.reset(inputElement);
    }
  }

  createFileList(files: File[]): FileList {
    const dataTransfer = new DataTransfer();

    for (const file of files) {
      dataTransfer.items.add(file);
    }

    return dataTransfer.files;
  }

  convertFilesToBase64(files: FileList): Observable<File[]> {
    const conversions: Observable<File>[] = Array.from(files).map((file) =>
      this.convert(file)
    );

    return forkJoin(conversions);
  }
  convert(file: File): Observable<File> {
    return new Observable((observer) => {
      observer.next(file);
      observer.complete();
    });
  }

  convertToBase64(file: File): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result as string;
        observer.next(base64String);
        observer.complete();
      };

      reader.onerror = () => {
        observer.error('Error al cargar el archivo.');
      };

      reader.readAsDataURL(file);
    });
  }
}
