<app-title
  [title]="title"
  [subtitle]="stepData.subtitle"
  [description]="campaign.description"
  [breadcrumb]="true"
>
  @if(step > -1) {
  <div class="row py-3 align-items-center justify-content-end">
    <div class="col-auto ms-auto">
      <button type="button" class="btn btn-secondary px-4" (click)="back()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
    </div>
    @for (item of stepData.actions; track $index) {
    <!--  -->
    <div class="col-auto">
      @if (item === 'PUBLISH') {
      <div class="d-inline-block">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="published"
            [(ngModel)]="data.published"
            (ngModelChange)="handleAction($index)"
          />

          <label class="form-check-label" for="published">Published</label>
        </div>
      </div>
      <!--  -->
      } @else {
      <button
        type="button"
        class="btn ms-2"
        [ngClass]="$index % 2 === 0 ? 'btn-primary' : 'btn-secondary'"
        (click)="handleAction($index)"
      >
        {{ item }}
      </button>
      }
      <!--  -->
    </div>
    }
  </div>
  }

  <!-- ! DEBUG -->
  <!-- <div class="row py-3">
    <div class="col-12 px-0">
      <button class="btn btn-warning" (click)="getParams()">
        Step: {{ step }} {{ method }} {{ endpoint }}
      </button>
    </div>
  </div> -->
  <!-- ! DEBUG -->
</app-title>

<div class="container px-0">
  <div class="row py-3 py-lg-4 py-xl-5">
    <div class="col-12 px-0">
      @switch (step) {
      <!-- FIELDBOARDS ID = 1 -->
      @case (1) {
      <!--  -->
      @if (campaign.broadcasts[0]) {
      <app-fieldboards
        #fieldboards
        [data]="data.data.fieldboard"
        [typeId]="segmentId"
      ></app-fieldboards>
      }
      <!--  -->
      }
      <!-- KEY MOMENTS ID = 2 -->
      @case (2) {
      <!--  -->
      @if (campaign.broadcasts[1]) {
      <app-fieldboards
        #keyMoments
        [data]="data.data.keyMoments"
        [typeId]="segmentId"
      ></app-fieldboards>
      }
      <!--  -->
      }
      <!-- NEAR GOAL ID = 3 -->
      @case (3) {
      <!--  -->
      @if (campaign.broadcasts[2]) {
      <app-near-goal
        #nearGoal
        [data]="data.data.nearGoal"
        [typeId]="segmentId"
      ></app-near-goal>
      }
      <!--  -->
      }
      <!-- EARNED MEDIA ID = 4 -->
      @case (4) {
      <!--  -->
      @if (campaign.broadcasts[3]) {
      <app-earned-media
        #earnedMedia
        [data]="data.data.earnedMedia"
        [typeId]="segmentId"
      ></app-earned-media>
      }
      <!--  -->
      }
      <!-- HOME SCREEN -->
      @default {
      <!--  -->
      @if (campaign.id !== -1) {
      <app-monthly-summary
        [data]="data"
        [broadcasts]="campaign.broadcasts"
        [typeId]="segmentId"
        (onStepChange)="goTo($event)"
      ></app-monthly-summary>
      }
      <!-- HOME SCREEN -->
      }
      <!--  -->
      }
    </div>
  </div>
</div>
