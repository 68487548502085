import { Component, Signal, computed, inject, signal } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { FormComponent } from '@shared/components';
import { ElementModel } from '@shared/models/Element.model';
import { forkJoin } from 'rxjs';
import { Search } from '../search-bar/search-bar.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Column } from '@shared/models/Pagination.model';
import { RolesService } from '@services/roles.service';
import { CrudModule } from './crud.module';

@Component({
  selector: 'app-crud',
  standalone: true,
  imports: [CrudModule],
  templateUrl: './crud.component.html',
  styleUrl: './crud.component.scss',
})
export class CrudComponent extends FormComponent {
  protected modalService = inject(NgbModal);
  protected route = inject(ActivatedRoute);
  protected router = inject(Router);
  protected roles = inject(RolesService);

  crudRoute: string = '';
  filters: ElementModel[] = [];
  group = signal('');
  pagination = signal(true);
  title: string = 'Elements';
  params: any = {
    take: 12,
    page: 1,
    search: '',
    order: [],
  };

  // !MODALS Components
  showComponent: any = null;
  editComponent: any = null;
  // !MODALS Components

  // ! ACTIONS CONTROLS
  canShow: Signal<boolean> = computed(() =>
    this.roles.can(this.group(), 'show')
  );
  canEdit: Signal<boolean> = computed(() =>
    this.roles.can(this.group(), 'edit')
  );
  canDelete: Signal<boolean> = computed(() =>
    this.roles.can(this.group(), 'destroy')
  );
  canDesactive: Signal<boolean> = computed(() =>
    this.roles.can(this.group(), 'desactive')
  );
  // ! ACTIONS CONTROLS

  ngOnInit() {
    const parts = this.router.url.split('/').slice(0, 3);

    this.endpoint = parts.join('/').substring(1);
    this.group.set(this.endpoint.replace('/', '.'));
    this.crudRoute = `${this.endpoint}`;
    this.endpoint = this.getFormatEndpoint();

    this.settings();
    this.services.push(this.api.call(this.endpoint, 'get', this.params));

    this.load();
  }

  settings() {}

  getFormatEndpoint(): string {
    return this.endpoint;
  }

  showModal(data: any) {
    if (!this.showComponent) {
      this.router.navigate(['/' + this.crudRoute + '/show/' + data.id]);

      return;
    }

    const modalRef = this.modalService.open(this.showComponent, {
      size: 'lg',
      scrollable: true,
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.dataId.set(data.id);
    modalRef.componentInstance.endpoint = this.crudRoute;
    modalRef.componentInstance.readonly = true;
    modalRef.componentInstance.isComponent = true;

    modalRef.componentInstance.onAction.subscribe((result: boolean) => {
      modalRef.close();

      if (result) {
        this.load();
      }
    });
  }

  editModal(data: any) {
    if (!this.editComponent) {
      this.router.navigate(['/' + this.crudRoute + '/edit/' + data.id]);

      return;
    }

    const modalRef = this.modalService.open(this.editComponent, {
      size: 'lg',
      scrollable: true,
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.dataId.set(data.id);
    modalRef.componentInstance.endpoint = this.crudRoute;
    modalRef.componentInstance.isComponent = true;

    modalRef.componentInstance.onAction.subscribe((result: boolean) => {
      modalRef.close();

      if (result) {
        this.load();
      }
    });
  }

  onPrimary(id: number) {
    this.router.navigate([`/${this.crudRoute}/show/${id}`]);
  }

  onSecondary(id: number) {
    this.router.navigate([`/${this.crudRoute}/edit/${id}`]);
  }

  delete(id: number) {
    if (this.endpoint) {
      const modalRef = this.modalService.open(DeleteModalComponent);

      modalRef.result.then((result) => {
        if (result) {
          this.startLoading();

          this.api.call(this.endpoint + '/' + id, 'delete', {}).subscribe({
            next: (responses) => {
              if (responses.status === 200) {
                this.handleAlert('Elemento eliminado correctamente', 'success');
              } else {
                this.handleAlert('¡Ocurrio un error al eliminar!');
              }

              this.load();
            },
            error: (error) => {
              console.error(error);
              this.handleAlert(error);
              this.stopLoading();
            },
          });
        }
      });
    }
  }

  override getServices() {
    return [this.api.call(this.endpoint, 'get', this.params)];
  }

  sort(column: Column) {
    let sorted =
      typeof this.params.order == 'string'
        ? this.params.order.split('|')[1]
        : '';
    this.params.order = `${column.field.split('.')[0]}|${
      sorted == 'asc' ? 'desc' : 'asc'
    }`;

    this.load();
  }

  load() {
    const services = this.initialized ? this.getServices() : this.services;

    if (services.length === 0) {
      this.stopLoading();

      return;
    }

    this.startLoading();

    forkJoin(services).subscribe({
      next: (responses) => {
        this.handleLoadResponse(responses);
        this.initialized = true;
        this.stopLoading();
      },
      error: (err) => {
        this.handleErrors(err);
        this.stopLoading();
      },
    });
  }

  handleLoadResponse(responses: any[]) {}

  handleErrors(errors: any[]) {}

  handlePage(event: PageEvent) {
    this.params.take = event.pageSize;
    this.params.page = event.pageIndex + 1;

    this.load();
  }

  handleSearch(search: Search) {
    this.params.search = encodeURIComponent(search.term);
    this.params.filter = search.filter;

    this.load();
  }
}
