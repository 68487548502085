import { Routes } from '@angular/router';

export const authRoutes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.component'),
  },
  {
    path: 'register',
    loadComponent: () => import('./pages/register/register.component'),
  },
  {
    path: 'recover',
    loadComponent: () => import('./pages/recover/recover.component'),
  },
  {
    path: 'recover/:token',
    loadComponent: () => import('./pages/recover/recover.component'),
  },
  { path: '**', redirectTo: 'login' },
];
