<div class="row">
  <div class="col-lg mb-3 mb-lg-0">
    <div class="search-bar">
      <i class="fa-solid fa-magnifying-glass"></i>

      <input
        id="exampleInputEmail1"
        type="search"
        class="form-control"
        placeholder="Search"
        [(ngModel)]="data.term"
        (ngModelChange)="search()"
      />
    </div>
  </div>

  @if (canCreate) {
  <div class="col-lg-auto">
    <a
      class="btn btn-primary px-4"
      [routerLink]="['/' + createRoute + '/create']"
    >
      New {{ name }}
    </a>
  </div>

  <div class="w-100"></div>
  } @if (filters.length > 0) {
  <div class="col-12 mt-4">
    <label for="filtee-select" class="form-label mb-1">Filter by</label>

    <select
      id="filter-select"
      class="form-select"
      aria-label="Filter select"
      [(ngModel)]="data.filter"
      (ngModelChange)="search()"
    >
      @for (filter of filters; track filter) {
      <option [value]="filter.value">{{ filter.label }}</option>
      }
    </select>
  </div>
  }
</div>
