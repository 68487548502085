import { Component } from '@angular/core';

import { CrudComponent } from '@admin/components';
import { CrudModule } from '@admin/components/crud/crud.module';
import { Permission } from '@admin/models/Permission.model';
import { Pagination } from '@shared/models/Pagination.model';

import { ChannelsFormComponent } from './channels-form/channels-form.component';

@Component({
    selector: 'app-channels',
    imports: [CrudModule],
    templateUrl: '../../components/crud/crud.component.html',
    styleUrl: './channels.component.scss'
})
export class ChannelsComponent extends CrudComponent {
  override title: string = 'Channels';
  override data: Pagination<Permission> = new Pagination(Permission);

  override handleLoadResponse(responses: any[]): void {
    if (responses[0].data) this.data.setData(responses[0].data);

    this.pagination.set(this.data.rows.length > 0);

    this.stopLoading();
  }

  override settings(): void {
    this.showComponent = ChannelsFormComponent;
    this.editComponent = ChannelsFormComponent;
  }
}
