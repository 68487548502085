import { Main } from '@shared/models/Main.model';

export class User extends Main {
  id: number = -1;
  name: string = '';
  email: string = '';
  active: string = '';
  created_at: string | null = null;
  [key: string]: any;
}
