import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const DATE_VALUES = {
  weekdays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  months: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Ma',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  weekLabel: 'sem',
};

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  getWeekdayLabel(weekday: number): string {
    return DATE_VALUES.weekdays[weekday - 1];
  }
  override getWeekLabel(): string {
    return DATE_VALUES.weekLabel;
  }
  getMonthShortName(month: number): string {
    return DATE_VALUES.months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
