import { NgClass } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';

import { Observable } from 'rxjs';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  CrudFormComponent,
  CrudFormFooterComponent,
  CrudFormHeaderComponent,
  ImageControllerComponent,
} from '@admin/components';
import { CrudElement } from '@admin/models/CrudElement.model';
import { AlertComponent } from '@shared/components/alert/alert.component';

@Component({
  selector: 'app-clients-form',
  imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatInputModule,
    MatRadioModule,
    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
    ImageControllerComponent,
  ],
  templateUrl: './clients-form.component.html',
  styleUrl: './clients-form.component.scss',
})
export class ClientsFormComponent extends CrudFormComponent {
  @ViewChild(ImageControllerComponent)
  imageController?: ImageControllerComponent;
  viewerships: CrudElement[] = [];

  override services: Observable<any>[] = [
    this.api.call('admin/catalogs/viewerships?limit=1000&page=1'),
  ];

  override data = this.formBuilder.group({
    campaigns: new FormControl<number[] | null>(null),
    name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    description: ['', [Validators.required]],
    viewership_id: new FormControl<number | null>(null),
    logo: ['', [Validators.required]],
    viewership: [''],
    report: [1, [Validators.required]],
  });

  override handleLoadResponse(responses: any[]): void {
    if (responses[0] && responses[0].status === 200)
      this.viewerships = responses[0].data;

    if (
      this.dataId() !== -1 &&
      responses[responses.length - 1] &&
      responses[responses.length - 1].status === 200
    ) {
      const responseData = responses[responses.length - 1].data;
      this.data.patchValue(responseData);

      if (
        responseData['campaigns'] &&
        Array.isArray(responseData['campaigns'])
      ) {
        const ids = responseData['campaigns'].map((cam: any) => cam.id);
        this.data.get('campaigns')?.setValue(ids);
      }

      if (
        this.data.get('logo')?.value &&
        typeof this.data.get('logo')?.value === 'string'
      ) {
        this.currentTemplate.file = String(this.data.get('logo')?.value ?? '');
      }
    }
  }

  override handleFile() {
    if (this.currentTemplate.file) {
      this.data.get('logo')?.setValue(this.currentTemplate.file);
    }
  }

  override resetFile(): void {
    super.resetFile();
    this.imageController?.clearFileInput();
  }

  override getParams() {
    const { viewership, ...params } = this.data.value;

    return params;
  }

  override onSuccess(response: any): void {
    if (this.dataId() === -1) {
      if (response.data?.id) {
        this.router.navigate(['/admin/campaigns/client/', response.data?.id]);
      } else {
        this.data.reset();
        this.resetFile();
      }
    }
  }
}
