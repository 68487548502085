<div class="card border-0 shadow-sm">
  <div class="card-header bg-primary">
    <div class="row align-items-center">
      <div class="col-xl mb-3 mb-xl-0">
        <h5 class="text-white mb-0">
          {{ data.game }}
        </h5>
      </div>

      <div class="col-auto">
        <button
          type="button"
          class="btn btn-secondary me-xl-3"
          (click)="collapsed = !collapsed"
        >
          <i
            class="fa-solid fa-lg"
            [ngClass]="collapsed ? 'fa-chevron-down' : 'fa-chevron-up'"
          ></i>
        </button>

        <button
          type="button"
          class="btn btn-light"
          ngbPopover="Delete"
          triggers="mouseenter:mouseleave"
          (click)="deleteMatch()"
        >
          <i class="fa-solid fa-trash fa-lg"></i>
        </button>
      </div>
    </div>
  </div>

  <div
    class="card-body text-center p-0"
    #collapse="ngbCollapse"
    [(ngbCollapse)]="collapsed"
  >
    <div class="container bg-secondary-subtle py-3">
      <div class="row h-100">
        <div class="col-lg col-input mb-4 mb-xl-0">
          <label for="game" class="form-label"> Game </label>

          <input
            id="game"
            type="text"
            class="form-control form-control-sm"
            [(ngModel)]="data.game"
          />
        </div>

        <div class="col-lg col-input mb-4 mb-xl-0">
          <label for="date" class="form-label"> Date </label>

          <input
            id="date"
            type="date"
            class="form-control form-control-sm"
            [(ngModel)]="data.date"
          />
        </div>

        <div class="col-lg col-input mb-4 mb-xl-0">
          <label for="units-received" class="form-label">
            Units Received
          </label>

          <input
            id="units-received"
            type="number"
            class="form-control form-control-sm"
            min="0"
            [(ngModel)]="data.unitsReceived"
            (ngModelChange)="calculateTimeReceived()"
          />
        </div>

        <div class="col-lg-auto col-input mb-4 mb-xl-0">
          <label for="time-received" class="form-label"> Time Received </label>

          <p class="fw-bold my-auto">
            {{
              ('' + data.timeReceived.minutes).padStart(2, '0') +
                ':' +
                ('' + data.timeReceived.seconds).padStart(2, '0')
            }}
          </p>
        </div>

        <div class="col-lg col-input mb-4 mb-xl-0">
          <label for="virwership" class="form-label"> Viewership (P2+) </label>

          <input
            id="viwership"
            type="number"
            min="0"
            class="form-control form-control-sm mt-auto"
            [(ngModel)]="data.viewership"
            (ngModelChange)="calculateDelivered()"
          />
        </div>

        <div class="col-lg-auto col-input mb-4 mb-xl-0">
          <label for="delivered" class="form-label"> Delivered </label>

          <p class="fw-bold my-auto">
            {{ data.delivered | number : '1.0-0' }}
          </p>
        </div>
      </div>
    </div>

    <div class="container rounded-bottom text-bg-secondary py-4">
      <div class="row">
        <div class="col-xl-6 mb-4 mb-xl-0">
          <app-video-edit
            [video]="data.leftVideo"
            (onVideoChange)="data.leftVideo = $event"
          ></app-video-edit>
        </div>

        <div class="col-xl-6">
          <app-video-edit
            [video]="data.rightVideo"
            (onVideoChange)="data.rightVideo = $event"
          ></app-video-edit>
        </div>
      </div>

      <div class="row pt-4">
        <div class="col-xl-6 mb-4 mb-xl-0 text-center">
          <h5 class="text-light mb-0">Left corner</h5>
        </div>

        <div class="col-xl-6 text-center">
          <h5 class="text-light mb-0">Right corner</h5>
        </div>
      </div>
    </div>
  </div>
</div>
