import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-crud-form-header',
  standalone: true,
  imports: [NgClass],
  templateUrl: './crud-form-header.component.html',
  styleUrl: './crud-form-header.component.scss',
})
export class CrudFormHeaderComponent {
  @Input() id: number = -1;
  @Input() disable: boolean = false;
  @Input() modal: boolean = false;
  @Input() titleContainerClass: string = 'container px-0 pt-3 pt-lg-4';
  @Input() title: string = '';
  @Input() newA: boolean = false;

  ngOnInit(): void {
    if (this.disable && this.title) {
      this.title = this.title.charAt(0).toUpperCase() + this.title.substring(1);
    }
  }
}
