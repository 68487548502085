import { ActivatedRoute, Router } from '@angular/router';
import { Component, inject } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { forkJoin } from 'rxjs';

import { FormComponent, TitleComponent } from '@shared/components';

import { Campaign } from '@admin/models/Campaign.model';
import { CampaignSeason } from '@admin/models/Playoffs.model';
import { ReportComponent } from '@admin/components/report/report.component';
import { Segment } from '@admin/models/Segments.model';
import { CampaignReport } from '@admin/models/CampaignReport.model';

@Component({
  selector: 'app-general-report',
  standalone: true,
  imports: [
    FormsModule,
    DecimalPipe,
    CurrencyPipe,
    ReportComponent,
    TitleComponent,
  ],
  providers: [DecimalPipe, CurrencyPipe],
  templateUrl: './general-report.component.html',
  styleUrl: './general-report.component.scss',
})
export class GeneralReportComponent extends FormComponent {
  private decimalPipe = inject(DecimalPipe);
  private currencyPipe = inject(CurrencyPipe);
  route = inject(ActivatedRoute);
  router = inject(Router);

  override data: CampaignReport = new CampaignReport();
  title: string = 'CLIENT NAME / CAMPAIGN NAME';
  campaignId: number = -1;
  comments = {
    general: '',
    fieldboards: '',
    'key-moments': '',
    'near-goal': '',
    'earned-media': '',
    playoffs: '',
  };

  servicesRegularSeason: string[] = [];
  servicesPlayoffs: string[] = [];

  types: string[] = [];
  segments: string[] = [];

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      if (this.campaignId === -1) {
        this.campaignId = Number(params.get('id') ?? -1);
      }

      if (this.loading) return;

      this.services = [
        this.api.call(`admin/campaigns/${this.campaignId}`),
        this.api.call(
          `admin/campaigns/${this.campaignId}/types?limit=1000&page=1`
        ),
      ];

      this.loadServices();
    });
  }

  override onLoadServicesSuccess(responses: any[]): void {
    this.servicesPlayoffs = [];

    // ! CAMPAIGN RESPONSE
    if (responses[0].status === 200) {
      this.data.campaign = responses[0].data;

      this.title = `${
        this.data.campaign.clients.length > 0
          ? this.data.campaign.clients[0].name
          : ''
      } / ${this.data.campaign.name}`;
    }

    // ! TYPES INDEX
    if (responses[1].status === 200 && responses[1].data?.rows) {
      this.types = responses[1].data.rows.map((type: any) => {
        return `admin/campaigns/${this.campaignId}/segments?type_id=${type.id}?limit=1000`;
      });
    }

    this.loadTypes();
  }

  loadTypes() {
    if (this.types.length === 0) {
      this.stopLoading();
      return;
    }

    const services = this.types.map((segment) => {
      return this.api.call(segment);
    });

    this.segments = [];

    forkJoin(services).subscribe({
      next: (responses) => {
        responses.forEach((response) => {
          if (response.status === 200 && response.data.rows) {
            response.data.rows.forEach((row: any) => {
              this.segments.push(`admin/segments/${row.id}`);
            });
          }
        });

        this.loadSegments();
      },
      error: (errors) => {
        console.error(errors);

        this.stopLoading();
      },
    });
  }

  loadSegments() {
    if (this.segments.length === 0) {
      this.stopLoading();
      return;
    }

    const services = this.segments.map((segment) => {
      return this.api.call(segment);
    });

    forkJoin(services).subscribe({
      next: (responses) => {
        responses.forEach((response) => {
          if (response.status === 200 && response.data) {
            this.data.segments.push(this.getPlayoffs(response.data));
          }
        });

        this.stopLoading();
        this.initialized = true;
      },
      error: (errors) => {
        console.error(errors);
        this.stopLoading();
        this.initialized = true;
      },
    });
  }

  getPlayoffs(resData: any) {
    const season = new CampaignSeason();

    if (resData.broadcasts) {
      season.budgets = Array(4)
        .fill(0)
        .map((_, index) => {
          return this.data.campaign.broadcasts[index]?.amount || 0;
        });
      season.setBudgets();

      // ! ID 1
      if (resData.broadcasts[0]) {
        season.data.fieldboard.id = resData.broadcasts[0].id;
        season.data.fieldboard.matches = resData.broadcasts[0].items;
        season.data.fieldboard.summary.impressionsContracted =
          resData.broadcasts[0].impressions;

        season.data.fieldboard.calculateSummary();
        season.data.fieldboard.calculateCPMDelivered();
      }

      // ! ID 2
      if (resData.broadcasts[1]) {
        season.data.keyMoments.id = resData.broadcasts[1].id;
        season.data.keyMoments.matches = resData.broadcasts[1].items;
        season.data.keyMoments.summary.impressionsContracted =
          resData.broadcasts[1].impressions;

        season.data.keyMoments.calculateSummary();
        season.data.keyMoments.calculateCPMDelivered();
      }

      // ! ID 3
      if (resData.broadcasts[2]) {
        season.data.nearGoal.id = resData.broadcasts[2].id;
        season.data.nearGoal.matches = resData.broadcasts[2].items;
        season.data.nearGoal.summary.impressionsContracted =
          resData.broadcasts[2].impressions;

        season.data.nearGoal.calculateSummary();
        season.data.nearGoal.calculateCPMDelivered();
      }

      // ! ID 4
      if (resData.broadcasts[3]) {
        season.data.earnedMedia.id = resData.broadcasts[3].id;
        season.data.earnedMedia.matches = resData.broadcasts[3].items;
        season.data.earnedMedia.summary.totalViews =
          resData.broadcasts[3].impressions;

        season.data.earnedMedia.calculateSummary();
      }
    }

    season.calculate();

    season.comments = resData.comments;

    return season;
  }

  handleAction(action: number): void {
    switch (action) {
      case 1:
        this.endpoint = `admin/campaigns/${this.campaignId}`;
        this.method = 'put';

        this.submit();
        break;

      default:
        break;
    }
  }

  override getParams() {
    return this.data.campaign.comments;
  }

  override success(response: any): void {
    this.handleAlert(`¡Changes saved successfully!`, 'success');

    this.stopLoading();
  }

  formatNumber(value: number): string {
    return this.decimalPipe.transform(value, '1.1-2') || '';
  }

  formatCurrency(value: number, currencyCode: string): string {
    return (
      this.currencyPipe.transform(value, currencyCode, 'symbol', '1.2-2') || ''
    );
  }

  back(): void {
    this.router.navigate(['/admin/campaigns/show', this.campaignId]);
  }
}
