<div class="sidebar container px-0 py-3 py-lg-4">
  <div class="sidebar-header mb-3 mb-lg-4 mb-xl-5">
    <a [routerLink]="['/admin/']">
      <img
        src="assets/images/logo.png"
        class="img-fluid"
        alt="Interticket Logo"
        onerror="this.onerror=null; this.src='assets/images/logo.png'"
      />
    </a>
  </div>

  <div class="sidebar-content">
    <div class="sidebar-items">
      <ul class="list-unstyled">
        @for (item of items; track item.id) {
        <li [ngClass]="{ 'li-seperator': item.separator }">
          @if (item.children.length === 0) {
          <a
            [routerLink]="[item.route]"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <i class="fa-solid {{ item.icon }} me-2"></i>

            <span>{{ item.title }}</span>
          </a>
          } @else {
          <a
            class="item-collapsable mt-auto"
            (click)="item.collapse = !item.collapse"
            [attr.aria-expanded]="!item.collapse"
          >
            <i class="fa-solid {{ item.icon }} me-2"></i>

            <span>{{ item.title }}</span>

            <i
              class="fa-solid fa-caret-{{
                item.collapse ? 'down' : 'up'
              }} ms-auto"
            ></i>
          </a>

          <div
            class="child-menu-container"
            #collapse="ngbCollapse"
            [(ngbCollapse)]="item.collapse"
          >
            <div class="child-menu-content">
              @for (child of item.children; track child.title) {
              <a
                class="btn btn-light"
                [routerLink]="[child.route]"
                routerLinkActive="active"
              >
                <div class="d-flex w-100 align-items-center">
                  <i class="fa-solid {{ child.icon }} me-2"></i>

                  {{ child.title }}
                </div>
              </a>
              }
            </div>
          </div>

          }
        </li>
        }
      </ul>

      <div class="menu-container">
        <div
          class="menu-collapsed"
          [ngClass]="{
            'show-menu': !collapsed,
          }"
        >
          <a
            [routerLink]="['/admin/settings']"
            class="btn btn-light w-100"
            (click)="collapsed = true"
          >
            <div class="d-flex w-100 align-items-center">
              <i class="fa-solid fa-gear me-2"></i>

              Settings
            </div>
          </a>

          <button type="button" class="btn btn-light w-100" (click)="logout()">
            <div class="d-flex w-100 align-items-center">
              <i class="fa-solid fa-right-from-bracket me-2"></i>

              Logout
            </div>
          </button>
        </div>

        <button
          type="button"
          class="btn btn-light w-100"
          (click)="collapsed = !collapsed"
        >
          <div class="d-flex w-100 align-items-center">
            <!-- <i class="fa-solid fa-circle-user me-2"></i> -->
            <img
              [src]="profile.avatar || 'assets/images/logo.png'"
              class="img-fluid"
              width="30"
              alt="User avatar image"
              onerror="this.onerror=null; this.src='/assets/images/avatar.png'"
            />

            <span class="ms-2">{{
              (profile.name || '') + ' ' + (profile.lastname || '')
            }}</span>

            <i
              class="fa-solid ms-auto"
              [ngClass]="collapsed ? 'fa-caret-up' : 'fa-caret-down'"
            ></i>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
