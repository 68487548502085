<div
  class="{{ titleContainerClass }}"
  [ngClass]="{
    'modal-header bg-primary text-white': modal
  }"
>
  <div class="row">
    <div class="col-12" [ngClass]="{ 'my-3 mb-lg-4': !modal }">
      <h1 class="h3 fw-bold text-uppercase mb-0">
        {{ disable ? '' : id !== -1 ? 'Edit' : 'New' }}
        {{ title }}
      </h1>
    </div>
  </div>
</div>
