import { Component } from '@angular/core';
import { TitleComponent } from '@shared/components';

@Component({
    selector: 'app-terms',
    imports: [TitleComponent],
    templateUrl: './terms.component.html',
    styleUrl: './terms.component.scss'
})
export class TermsComponent {}
