import { Main } from '@shared/models/Main.model';

export class CampaignComments {
  general: string = '';
  fieldboards: string = '';
  'key-moments': string = '';
  'near-goal': string = '';
  'earned-media': string = '';
}

export class Campaign extends Main {
  id: number = -1;
  name: string = '';
  description: string = '';
  image: string = '';
  property_id: number = 0;
  property_other: string | null = null;
  created_at: string | null = null;
  updated_at: string | null = null;
  deleted_at: string | null = null;
  clients: Client[] = [];
  broadcasts: Broadcast[] = [];
  types: Client[] = [];
  comments: CampaignComments = new CampaignComments();

  [key: string]: any;
}

export class Broadcast {
  broadcast_type_id: number = -1;
  amount: number = 0;
  comments: string | null = null;
}

export class Client {
  id: number = -1;
  name: string = '';
  logo: string | null = null;
}
