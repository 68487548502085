import {
  Component,
  HostListener,
  Signal,
  computed,
  signal,
} from '@angular/core';
import { AsyncPipe, NgClass } from '@angular/common';
import { RouterLink, RouterOutlet } from '@angular/router';

import { MatSidenavModule } from '@angular/material/sidenav';
import { ToastModule } from 'primeng/toast';

import { SharedModule } from '@shared/shared.module';
import { ApiService } from '@services/api.service';
import { LoadingService } from '@services/loading.service';
import { RolesService } from '@services/roles.service';
import { FooterComponent, SidebarComponent } from './layout';
import { MenuItem } from './models/MenuItem.model';
import { fadeInAnimation } from '@shared/animations/fade.animation';
import { UserService } from '@services/user.service';

const MIN_SCREEN_SIZE = 992;

@Component({
  selector: 'app-admin',
  imports: [
    RouterOutlet,
    RouterLink,
    NgClass,
    AsyncPipe,
    SidebarComponent,
    FooterComponent,
    MatSidenavModule,
    SharedModule,
    ToastModule,
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss',
  animations: [fadeInAnimation],
})
export class AdminComponent {
  initialized: boolean = false;
  isMobile: boolean = false;
  loading = signal(false);
  menuItems: MenuItem[] = [];
  params: any = {
    page: 1,
    limit: 10,
  };
  group = signal('');
  canShow: Signal<boolean> = computed(() =>
    this.roleService.can(this.group() + '.index')
  );
  canCreate: Signal<boolean> = computed(() =>
    this.roleService.can(this.group() + '.create')
  );
  loading$ = this.loadingService.loading$;
  sections: any[] = [
    {
      name: 'RECENT CAMPAIGNS',
      icon: 'fa-solid fa-folder',
      permission: 'admin.campaigns.index',
      route: '/admin/campaigns',
    },
    {
      name: 'CLIENTS',
      icon: 'fa-solid fa-user',
      permission: 'admin.clients.index',
      route: '/admin/clients',
    },
    {
      name: 'NEW CLIENT',
      icon: 'fa-solid fa-user-plus',
      permission: 'admin.clients.create',
      route: '/admin/clients/create',
    },
    {
      name: 'NEW CAMPAIGN',
      icon: 'fa-solid fa-folder-plus',
      permission: 'admin.campaigns.create',
      route: '/admin/campaigns/create',
    },
    {
      name: 'PROPERTIES',
      icon: 'fa-solid fa-square-list',
      permission: 'admin.properties.index',
      route: '/admin/properties',
    },
    {
      name: 'ADMIN',
      icon: 'fa-solid fa-user-gear',
      permission: 'admin.dashboard.index',
      separator: true,
      children: [
        {
          name: 'CHANNELS',
          icon: 'fa-solid fa-tower-broadcast',
          permission: 'admin.channels.index',
          route: '/admin/channels',
        },
        {
          name: 'PERMISSIONS',
          icon: 'fa-solid fa-lock',
          permission: 'admin.permissions.index',
          route: '/admin/permissions',
        },
        {
          name: 'ROLES',
          icon: 'fa-solid fa-user-shield',
          permission: 'admin.roles.index',
          route: '/admin/roles',
        },
        {
          name: 'USERS',
          icon: 'fa-solid fa-users',
          permission: 'admin.users.index',
          route: '/admin/users',
        },
      ],
    },
  ];

  constructor(
    private api: ApiService,
    protected loadingService: LoadingService,
    protected user: UserService,
    private roleService: RolesService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.isMobile = window.innerWidth < MIN_SCREEN_SIZE;
  }

  ngOnInit(): void {
    console.log('admin');

    this.isMobile = window.innerWidth < MIN_SCREEN_SIZE;

    this.roleService.roles$.subscribe(() => {
      this.handleItems();
    });
  }

  handleItems() {
    const menuItems = this.sections.filter((section) => {
      const allowed = section.permission
        ? this.roleService.can(section.permission)
        : true;

      if (section.children && section.children.length > 0) {
        const cItems = section.children.filter((sectionC: any) => {
          if (sectionC.permission) {
            const allowed = this.roleService.can(sectionC.permission);

            return allowed;
          }

          return true;
        });

        if (cItems.length === 0) {
          return false;
        }
      }

      return allowed;
    });

    this.menuItems = menuItems.map((section, ind) => {
      const nItem = new MenuItem();

      nItem.id = ind + 1;
      nItem.title = section.name;
      nItem.icon = section.icon;
      nItem.permission = section.permission;
      nItem.route = section.route;
      nItem.separator = section.separator || false;

      if (section.children && section.children.length > 0) {
        const cItems = section.children.filter((sectionC: any) => {
          if (sectionC.permission) {
            return this.roleService.can(sectionC.permission);
          }

          return true;
        });

        if (cItems.length > 0) {
          nItem.children = cItems.map((sectionC: any, index: number) => {
            const nCItem = new MenuItem();

            nCItem.id = index + 1;
            nCItem.title = sectionC.name;
            nCItem.icon = sectionC.icon;
            nCItem.permission = sectionC.permission;
            nCItem.route = sectionC.route;
            nCItem.separator = sectionC.separator || false;

            return nCItem;
          });
        }
      }

      return nItem;
    });
  }

  pagination(next: boolean = false) {
    this.params['page'] = this.params['page'] + (next ? 1 : -1);

    this.api.call('admin/events', 'get', this.params).subscribe({
      next: (response) => {
        if (response.status === 200) {
          // this.data.setData(response.data);
        }

        this.loading.set(false);
      },
      error: (error) => {
        this.loading.set(false);
      },
    });
  }
}
