import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { UserService } from '../services/user.service';

const UNAUTHORIZED = 401;

export const ErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const user = inject(UserService);

  return next(req).pipe(
    catchError((error) => {
      if ([UNAUTHORIZED].includes(error.status)) {
        user.clearSession();
      }

      return throwError(() => error);
    })
  );
};
