<div class="container py-3 py-lg-4 py-xl-5">
  <app-crud-form-header [id]="dataId()" title="campaign"></app-crud-form-header>

  <form [formGroup]="data" (ngSubmit)="submit()" class="row h-100 gy-3 gy-lg-4">
    <div class="col-12">
      <label for="name" class="fw-bold mb-1">Campaign name</label>

      <p class="mb-1">To differentiate this campaign from the others.</p>

      <input
        id="name"
        type="text"
        class="form-control mt-auto"
        formControlName="name"
        [ngClass]="{
          'is-invalid':
            data.get('name')?.invalid &&
            (data.get('name')?.dirty || data.get('name')?.touched)
        }"
      />

      @if ( data.get('name')?.invalid && (data.get('name')?.dirty ||
      data.get('name')?.touched)) {
      <div id="validationName" class="invalid-feedback">
        Campaign name is required.
      </div>
      }
    </div>

    <div class="col-lg-6 d-flex flex-column">
      <p class="fw-bold mb-1">Client</p>

      <p class="mb-1">Assign an existing client to the campaign.</p>

      <ng-select
        id="tag"
        appearance="outline"
        [items]="clients"
        bindLabel="name"
        bindValue="id"
        class="mt-auto"
        formControlName="clients"
        placeholder="Select Clients"
        [multiple]="true"
        [clearable]="false"
      ></ng-select>

      @if ( data.get('clients')?.invalid && (data.get('clients')?.dirty ||
      data.get('clients')?.touched)) {
      <div id="tagValidation" class="text-danger">
        Select at least one client.
      </div>
      }
    </div>

    <div class="col-lg-6 d-flex flex-column">
      <div class="h-100 d-flex flex-column">
        <p class="fw-bold mb-1">New Client</p>

        <p class="mb-1">Or you can create a new client.</p>

        <div class="my-auto">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="openNewClient()"
          >
            New Client
          </button>
        </div>
      </div>
    </div>

    <div class="col-12">
      <label for="description" class="fw-bold mb-1">Description</label>

      <p class="form-label mb-1">
        Add a client description for more information.
      </p>

      <textarea
        class="form-control"
        id="description"
        rows="3"
        formControlName="description"
      ></textarea>
    </div>

    <div [ngClass]="dataId() !== -1 ? 'col-lg' : 'col-lg-6 d-flex flex-column'">
      <label for="budget" class="fw-bold mb-1">Total campaign budget</label>

      <app-custom-input-number
        addon="$"
        placeholder="Budget"
        formControlName="budget"
        [readOnly]="dataId() !== -1"
      ></app-custom-input-number>

      @if(dataId() !== -1) {
      <div id="budgetHelp" class="form-text">
        Budget is calculated automatically
      </div>
      }
    </div>

    @if (dataId() !== -1) {
    <div class="col-lg-9 px-0" formArrayName="budgets">
      @for (budget of budgets.controls; track $index) {
      <div class="row gy-4" [formGroupName]="$index">
        <!-- Name -->
        <div class="col-12">
          <label [for]="'month-name-' + $index" class="mb-1">Month:</label>

          <input
            [id]="'month-name-' + $index"
            type="text"
            formControlName="name"
            class="form-control mt-auto"
            readonly
          />
        </div>

        <div class="col-12 px-0">
          <div class="row gy-4" formGroupName="budgets">
            <div class="col-lg-4">
              <label [for]="'b-fieldboard-' + $index" class="mb-1">
                Budgets for Fieldboards:
              </label>

              <app-custom-input-number
                addon="$"
                [id]="'b-fieldboard-' + $index"
                formControlName="fieldboards"
                (ngModelChange)="calculateTotalBudget()"
              ></app-custom-input-number>
            </div>
            <!-- Fieldboards Array -->

            <div class="col-lg-4">
              <label [for]="'b-kmoments-' + $index" class="mb-1">
                Budgets for Key Moments:
              </label>

              <app-custom-input-number
                addon="$"
                [id]="'b-kmoments-' + $index"
                formControlName="key_moments"
                (ngModelChange)="calculateTotalBudget()"
              ></app-custom-input-number>
            </div>

            <div class="col-lg-4">
              <label [for]="'b-near-' + $index" class="mb-1">
                Budgets for Near Goal:
              </label>

              <app-custom-input-number
                addon="$"
                [id]="'b-near-' + $index"
                formControlName="near_goal"
                (ngModelChange)="calculateTotalBudget()"
              ></app-custom-input-number>
            </div>
          </div>
        </div>

        <div class="col-12">
          <hr class="border-1 border-primary" />
        </div>
      </div>
      }
    </div>
    }

    <app-alert [content]="alertData"></app-alert>

    <div class="col-12 px-0">
      <app-crud-form-footer
        [created]="created"
        [loading]="loadingService.getLoading()"
        (cancel)="cancel()"
      ></app-crud-form-footer>
    </div>
  </form>
</div>
