import { NgModule } from '@angular/core';
import { ZeroDatePipe } from './pipes';
import { NumericInputDirective } from './directives';

@NgModule({
  declarations: [
    // Directives
    NumericInputDirective,
    ZeroDatePipe,
  ],
  imports: [],
  exports: [
    // Directives
    NumericInputDirective,
    ZeroDatePipe,
  ],
})
export class SharedModule {}
