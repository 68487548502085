<div class="row">
  <div class="col-12">
    <div class="w-100 table-responsive card mb-4">
      <table class="table text-center mb-0">
        <thead class="table-secondary">
          <tr>
            <th scope="col">Units Received</th>

            <th scope="col">Impressions Contracted</th>

            <th scope="col">Impressions Delivered</th>
          </tr>
        </thead>

        <tbody class="table-warning">
          <tr>
            <td class="col-3">{{ data.summary.unitsReceived | number }}</td>
            <td class="col-3">
              <app-custom-input-number
                [data]="data.summary.impressionsContracted"
                placeholder="Impressions"
                (onDataChange)="
                  data.summary.impressionsContracted = $event;
                  calculateCPMContrated()
                "
              ></app-custom-input-number>
            </td>
            <td class="col-3">
              {{ data.summary.impressionsDelivered | number }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  @for (item of data.matches; track $index) {
  <div id="match-{{ $index + 1 }}" class="col-12 mb-4">
    <app-near-goal-video
      [data]="item"
      [viewership]="viewership()"
      (onChange)="calculateTotal()"
      (onMedia)="handleFileMedia($event, $index)"
      (onDelete)="deleteMatch($index)"
    ></app-near-goal-video>
  </div>
  }
</div>
