import { TimeMatch, TimeModel } from '@admin/models/Match.model';
import { Component, Input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SecondsInputDirective } from '@shared/directives/seconds-input.directive';
import { ZeroPadPipe } from '@shared/pipes/zero-pad.pipe';

@Component({
  selector: 'app-time-controller',
  imports: [FormsModule, SecondsInputDirective, ZeroPadPipe],
  templateUrl: './time-controller.component.html',
  styleUrl: './time-controller.component.scss',
})
export class TimeControllerComponent {
  @Input() data: TimeMatch[] = [];
  @Input() total: TimeModel = new TimeModel();
  onTimeChange = output<void>();

  ngOnInit(): void {
    if (this.data.length === 0) {
      this.addTime();
    }
  }

  totalTime(index: number) {
    const time = this.data[index];

    // if (time.start.seconds > 59) {
    //   time.start.minutes += Math.floor(time.start.seconds / 60);
    //   time.start.seconds -= 60;
    // }

    // if (time.end.seconds > 59) {
    //   time.end.minutes += Math.floor(time.end.seconds / 60);
    //   time.end.seconds -= 60;
    // }

    if (time.start.totalTime() > time.end.totalTime()) {
      return;
    }

    const diff = this.getDiffTime(time.start, time.end);
    time.total = diff;

    console.debug(time);
    console.debug(this.data[index]);

    this.allTotalTime();
    this.onTimeChange.emit();
  }

  allTotalTime() {
    const timeEntries = this.data;

    const totalDiff = timeEntries.reduce((acc, current) => {
      if (current.end.minutes === 0 && current.end.seconds === 0) {
        return acc;
      }

      const diff = this.getDiffTime(current.start, current.end);

      return acc + (diff.minutes * 60 + diff.seconds);
    }, 0);

    this.total.minutes = Math.floor(totalDiff / 60);
    this.total.seconds = totalDiff % 60;
  }

  getDiffTime(startTime: TimeModel, endTime: TimeModel) {
    const diff = new TimeModel();
    diff.setTime(endTime.totalTime() - startTime.totalTime());

    return diff;
  }

  addTime() {
    const nTime = new TimeMatch();

    this.data.push(nTime);
  }

  deleteTime(index: number) {
    this.data.splice(index, 1);
  }
}
