import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';

import { MatPaginatorIntl } from '@angular/material/paginator';
import { providePrimeNG } from 'primeng/config';
import { MessageService } from 'primeng/api';
import Material from '@primeng/themes/material';

import { routes } from './app.routes';
import { ApiInterceptor } from './interceptors/api-interceptor.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { CustomPaginatorIntl } from './shared/config/custom-paginator-intl';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([ApiInterceptor, ErrorInterceptor])),
    provideRouter(routes),
    MessageService,
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
    providePrimeNG({
      theme: {
        preset: Material,
        options: {
          darkModeSelector: false || 'none',
        },
      },
    }),
  ],
};
