<div class="modal-header text-bg-danger">
  <h4 class="modal-title">Eliminar</h4>

  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>

<div class="modal-body">
  <p>¿Estás seguro de que deseas eliminar este elemento?</p>
</div>

<div class="modal-footer">
  <div class="row ">
    <div class="col-5">
      <button
        type="button"
        class="btn btn-outline-dark w-100"
        (click)="activeModal.close(false)"
      >
        Cancelar
      </button>
    </div>

    <div class="col-5">
      <button
        type="button"
        class="btn btn-danger w-100"
        (click)="activeModal.close(true)"
      >
        Confirmar
      </button>
    </div>
  </div>
</div>
