<app-crud-form-header
  [id]="dataId()"
  [disable]="readonly"
  [modal]="isComponent"
  title="permission"
></app-crud-form-header>

<div [ngClass]="isComponent ? 'modal-body' : 'container py-3 py-lg-4 py-xl-5'">
  <form
    [formGroup]="data"
    (ngSubmit)="submit()"
    class="row justify-content-around gy-3 gy-lg-4"
  >
    <div class="col-12">
      <input
        id="name"
        name="name"
        class="form-control"
        type="text"
        formControlName="name"
        [ngClass]="{
          'is-invalid':
            data.get('name')?.invalid &&
            (data.get('name')?.dirty || data.get('name')?.touched)
        }"
      />

      @if (data.get('name')?.invalid && (data.get('name')?.dirty ||
      data.get('name')?.touched) ) {
      <div id="validationName" class="invalid-feedback">Name is required.</div>
      }
    </div>

    <app-alert [content]="alertData"></app-alert>

    <div class="w-100 mt-0"></div>

    @if (!isComponent) {
    <div class="col-12 px-0">
      <app-crud-form-footer
        [created]="created"
        [disable]="readonly"
        [modal]="isComponent"
        [loading]="loadingService.getLoading()"
        (cancel)="cancel()"
      ></app-crud-form-footer>
    </div>
    }
  </form>
</div>

@if (isComponent) {
<app-crud-form-footer
  [created]="created"
  [disable]="readonly"
  [modal]="isComponent"
  [loading]="loadingService.getLoading()"
  (onSubmit)="submit()"
  (cancel)="cancel()"
></app-crud-form-footer>
}
