<div class="container py-3 py-lg-4 py-xl-5">
  <app-crud-form-header [id]="dataId()" title="campaign"></app-crud-form-header>

  <form [formGroup]="data" (ngSubmit)="submit()" class="row h-100 gy-3 gy-lg-4">
    <div class="col-12">
      <p class="fw-bold mb-1">Campaign name</p>

      <p class="mb-1">To differentiate this campaign from the others.</p>

      <input
        id="name"
        type="text"
        class="form-control mt-auto"
        formControlName="name"
        [ngClass]="{
          'is-invalid':
            data.get('name')?.invalid &&
            (data.get('name')?.dirty || data.get('name')?.touched)
        }"
      />

      @if ( data.get('name')?.invalid && (data.get('name')?.dirty ||
      data.get('name')?.touched)) {
      <div id="validationName" class="invalid-feedback">
        Campaign name is required.
      </div>
      }
    </div>

    <div class="col-lg-6 d-flex flex-column">
      <p class="fw-bold mb-1">Client</p>

      <p class="mb-1">Assign an existing client to the campaign.</p>

      <ng-select
        id="tag"
        appearance="outline"
        [items]="clients"
        bindLabel="name"
        bindValue="id"
        class="mt-auto"
        formControlName="clients"
        placeholder="Select Clients"
        [multiple]="true"
        [clearable]="false"
      ></ng-select>

      @if ( data.get('clients')?.invalid && (data.get('clients')?.dirty ||
      data.get('clients')?.touched)) {
      <div id="tagValidation" class="text-danger">
        Select at least one client.
      </div>
      }
    </div>

    <div class="col-lg-6 d-flex flex-column">
      <div class="h-100 d-flex flex-column">
        <p class="fw-bold mb-1">New Client</p>

        <p class="mb-1">Or you can create a new client.</p>

        <div class="my-auto">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="openNewClient()"
          >
            New Client
          </button>
        </div>
      </div>
    </div>

    <div class="w-100"></div>

    <div class="col-lg-4 col-xl-3">
      <p class="fw-bold mb-1">Total campaign budget</p>

      <input
        id="campaignBudget"
        type="number"
        min="0"
        class="form-control"
        formControlName="totalBudget"
      />
    </div>

    <div class="col-lg-4 col-xl-3">
      <p class="fw-bold mb-1">Budget for Fieldboards</p>

      <input
        id="fieldboardsBudget"
        type="number"
        min="0"
        class="form-control"
        formControlName="fieldboardsBudget"
      />
    </div>

    <div class="col-lg-4 col-xl-3">
      <p class="fw-bold mb-1">Budget for Key Moments</p>

      <input
        id="momentsBudget"
        type="number"
        min="0"
        class="form-control"
        formControlName="keyMomentsBudget"
      />
    </div>

    <div class="col-lg-4 col-xl-3">
      <p class="fw-bold mb-1">Budget for Near Goal</p>

      <input
        id="goalBudget"
        type="number"
        min="0"
        class="form-control"
        formControlName="nearGoalBudget"
      />
    </div>

    <div class="w-100"></div>

    <div class="col-12">
      <p class="fw-bold mb-1">Description</p>

      <label for="description" class="form-label mb-1">
        Add a client description for more information.
      </label>

      <textarea
        class="form-control"
        id="description"
        rows="3"
        formControlName="description"
      ></textarea>
    </div>

    <app-alert [content]="alertData"></app-alert>

    <div class="col-12 px-0">
      <app-crud-form-footer
        [created]="created"
        [loading]="loadingService.getLoading()"
        (cancel)="cancel()"
      ></app-crud-form-footer>
    </div>
  </form>
</div>
