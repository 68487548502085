import {
  Component,
  WritableSignal,
  computed,
  inject,
  signal,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Menu } from '@admin/models/Menu.model';
import { ApiService } from '@services/api.service';
import { LoadingService } from '@services/loading.service';
import { FormComponent, MainCardComponent } from '@shared/components';
import { Profile } from '@admin/models/Profile.model';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [MainCardComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent extends FormComponent {
  private router = inject(Router);

  override data: Profile = new Profile();

  items: Menu[] = [
    {
      id: 2,
      title: 'Recent Campaign',
      route: '/admin/campaigns',
      actions: 'Continue with',
      icon: 'fa-solid fa-folder',
    },
    {
      id: 3,
      title: 'New Client',
      route: '/admin/clients/create',
      actions: 'Add',
      icon: 'fa-solid fa-user-plus',
    },
    {
      id: 4,
      title: 'New Campaign',
      route: '/admin/campaigns/create',
      actions: 'Start',
      icon: 'fa-solid fa-folder-plus',
    },
  ];

  // private api: ApiService,
  // private loading: LoadingService

  ngOnInit() {
    this.load();
  }

  load() {
    this.startLoading();

    this.api.call('admin/profile/me').subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.data = response.data;
        }

        this.stopLoading();
      },
      error: (error) => {
        console.error(error);
        this.stopLoading();
      },
    });
  }

  goTo(route?: string) {
    this.router.navigate([`${route}`]);
  }
}
