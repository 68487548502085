import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import {
  NgbCollapseModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { UserService } from '@services/index';

@Component({
    selector: 'app-header',
    imports: [
        NgbCollapseModule,
        NgbDropdownModule,
        NgSelectModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        RouterLink,
    ],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Input() canShow: boolean = false;
  @Input() canCreate: boolean = false;
  @Input() dataId = signal(-1);
  @Output() onOpen = new EventEmitter<boolean>(false);
  @Output() onPageChange = new EventEmitter<boolean>(false);

  isMenuCollapsed: boolean = true;
  logged = signal(false);
  // currentEvent: Signal<EventModel> = computed(() => {
  //   const event = this.data.rows.filter((row) => row.id === this.dataId())[0];

  //   if (event) return event;

  //   return new EventModel();
  // });

  constructor(private user: UserService, private router: Router) {}

  ngOnInit(): void {
    this.user.loggedIn$.subscribe((loggedIn) => {
      this.logged.set(this.user.logged);
    });
  }

  closeMenu() {
    this.isMenuCollapsed = true;
  }

  open() {
    this.onOpen.emit(true);
  }

  changePage(next: boolean = false) {
    this.onPageChange.emit(next);
  }

  logout() {
    this.closeMenu();
    this.user.clearSession();
    this.logged.set(false);
    this.router.navigate(['/auth/login']);
  }
}
