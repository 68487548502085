import { Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { RoutePermissionsPipe } from '@shared/pipes/route-permissions.pipe';
import { MenuItem } from '@admin/models/MenuItem.model';
import { UserService } from '@services/user.service';
import { NgClass } from '@angular/common';
import { Profile } from '@admin/models/Profile.model';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    NgClass,
    RouterModule,
    NgbCollapseModule,
    MatButtonModule,
    MatIconModule,
    RoutePermissionsPipe,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  @Input() items: MenuItem[] = [];
  @Input() profile = new Profile();

  collapsed: boolean = true;
  checkHavePermission: boolean = true;

  constructor(private router: Router, private user: UserService) {}

  logout() {
    this.user.clearSession();
    this.collapsed = true;
    this.router.navigate(['/auth/login']);
  }
}
