import { Component, HostListener } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import AOS from 'aos';

import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SharedModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  loading: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setAppHeight();
  }

  constructor() {
    this.setAppHeight();
  }

  ngOnInit() {
    AOS.init();
  }

  private setAppHeight() {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  }
}
