<app-title title="ACCOUNT SETTINGS"></app-title>

<div class="container py-4">
  <form class="row h-100 gy-3 gy-lg-4" [formGroup]="data" (ngSubmit)="submit()">
    <div class="col-12 px-0 my-3">
      @if (initialized) {
      <app-image-controller
        title="Avatar"
        label="Add an image, accepts jpg and png formats, with a minimum size of 50px x 50px."
        [src]="currentTemplate.file || '/assets/images/avatar.png'"
        (selectFile)="selectFile($event)"
      ></app-image-controller>
      }
    </div>

    <input
      #fileInput
      type="file"
      hidden
      (change)="selectFile($event)"
      accept="image/png, image/jpeg, .png, .jpg"
    />

    <!-- ! DEBUG -->
    <!-- <div class="col-12">
      <button type="button" class="btn btn-danger" (click)="getParams()">
        test
      </button>
    </div> -->
    <!-- ! DEBUG -->

    <div class="col-12 col-xl-6">
      <p class="fw-bold mb-1">Personal name</p>

      <p>Is the name displayed in the welcome and on the menu.</p>

      <input
        id="name"
        type="text"
        class="form-control"
        formControlName="name"
        [ngClass]="{
          'is-invalid':
            data.get('name')?.invalid &&
            (data.get('name')?.dirty || data.get('name')?.touched)
        }"
      />

      @if (data.get('name')?.invalid && (data.get('name')?.dirty ||
      data.get('name')?.touched)){
      <div id="validationName" class="invalid-feedback">Name is required.</div>
      }
    </div>

    <div class="col-12 col-xl-6 d-flex flex-column">
      <p class="fw-bold mb-3">Lastname</p>

      <input
        id="lastname"
        type="text"
        class="form-control mt-auto"
        formControlName="lastname"
        [ngClass]="{
          'is-invalid':
            data.get('lastname')?.invalid &&
            (data.get('lastname')?.dirty || data.get('lastname')?.touched)
        }"
      />

      @if (data.get('lastname')?.invalid && (data.get('lastname')?.dirty ||
      data.get('lastname')?.touched) ) {
      <div id="validationLastname" class="invalid-feedback">
        Required field!
      </div>
      }
    </div>

    <div class="col-12 col-xl-6">
      <p class="fw-bold mb-1">Email</p>

      <p>It is for alerts, notices and password reset.</p>

      <input
        id="email"
        autocomplete="username"
        type="text"
        class="form-control"
        formControlName="email"
        [ngClass]="{
          'is-invalid':
            data.get('email')?.invalid &&
            (data.get('email')?.dirty || data.get('email')?.touched)
        }"
      />

      @if (data.get('email')?.invalid && (data.get('email')?.dirty ||
      data.get('email')?.touched)){
      <div id="validationEmail" class="invalid-feedback">
        Email is required.
      </div>
      }
    </div>

    <div class="col-12 col-xl-6">
      <p class="fw-bold mb-1">Password</p>

      <p>Password Click the following button for update your password.</p>

      @if (passwordEditing) {
      <div class="card py-1 border border-dark">
        <div class="card-body px-1">
          <div class="row">
            <div class="col-12 mb-4">
              <div class="input-group">
                <input
                  id="new-password"
                  autocomplete="new-password"
                  [type]="passwordVisible ? 'text' : 'password'"
                  class="form-control"
                  placeholder="Password"
                  aria-label="Password"
                  aria-describedby="button-password-toggle"
                  formControlName="password"
                  [ngClass]="{
                    'is-invalid':
                      data.get('password')?.invalid &&
                      (data.get('password')?.dirty ||
                        data.get('password')?.touched)
                  }"
                />

                <button
                  id="button-password-toggle"
                  class="btn btn-outline-secondary"
                  type="button"
                  (click)="passwordVisible = !passwordVisible"
                >
                  <i
                    class="fa-solid"
                    [ngClass]="passwordVisible ? 'fa-eye-slash' : 'fa-eye'"
                  ></i>
                </button>

                @if (data.get('password')?.invalid &&
                (data.get('password')?.dirty || data.get('password')?.touched)){
                <div id="validationPassword" class="invalid-feedback">
                  @if (data.get('password')?.errors?.['required']) {
                  <span> Password is required. </span>
                  } @if (data.get('password')?.errors?.['minlength']) {
                  <span> Password is required. </span>
                  } @if (data.get('password')?.errors?.['password']) {
                  <span> Password is required. </span>
                  }
                </div>
                }
              </div>
            </div>

            <div class="col-12 mb-4">
              <div class="input-group">
                <input
                  id="confirm-new-password"
                  autocomplete="new-password"
                  [type]="passwordVisible ? 'text' : 'password'"
                  class="form-control"
                  placeholder="Password"
                  aria-label="Password"
                  aria-describedby="button-password-toggle"
                  formControlName="confirm"
                  [ngClass]="{
                    'is-invalid':
                      data.get('confirm')?.invalid &&
                      (data.get('confirm')?.dirty ||
                        data.get('confirm')?.touched)
                  }"
                />

                <button
                  id="button-confirm-toggle"
                  class="btn btn-outline-secondary"
                  type="button"
                  (click)="passwordVisible = !passwordVisible"
                >
                  <i
                    class="fa-solid"
                    [ngClass]="passwordVisible ? 'fa-eye-slash' : 'fa-eye'"
                  ></i>
                </button>

                @if (data.get('confirm')?.invalid && (data.get('confirm')?.dirty
                || data.get('confirm')?.touched) || !matchPassword){
                <div id="validationConfirm" class="invalid-feedback">
                  @if (data.get('confirm')?.errors?.['required']) {
                  <span> Password is required. </span>
                  } @if (!matchPassword) {
                  <span> Passwords must match. </span>
                  }
                </div>
                }
              </div>
            </div>

            <div class="col-12 text-end">
              <button
                type="button"
                class="btn btn-primary"
                (click)="editPassword()"
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>

      } @else {
      <button
        type="button"
        class="btn btn-secondary opacity-75 text-light"
        (click)="editPassword()"
      >
        CHANGE PASSWORD
      </button>
      }
    </div>

    <app-alert [content]="alertData"></app-alert>

    <div class="col-auto ms-auto">
      <button type="submit" class="btn btn-primary">SAVE CHANGES</button>
    </div>
  </form>
</div>
