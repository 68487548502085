<div
  id="crud-footer"
  class="row justify-content-end"
  [ngClass]="{ 'modal-footer': modal }"
>
  <div class="col-lg-6 col-xl-4 col-xxl-3 mb-3 mb-lg-0">
    <button
      type="button"
      class="btn btn-secondary border-dark w-100"
      (click)="cancel.emit()"
      [disabled]="loading"
    >
      <!-- {{ disable ? 'CLOSE' : created ? 'ACEPT' : 'CANCEL' }} -->
      {{ disable ? 'CLOSE' : 'CANCEL' }}
    </button>
  </div>

  @if (!disable) {
  <div class="col-lg-6 col-xl-4 col-xxl-3">
    <button
      type="submit"
      class="btn btn-primary w-100"
      color="accent"
      (click)="onSubmit.emit()"
      [disabled]="loading"
    >
      @if (loading) {
      <span
        class="spinner-border spinner-border-sm me-2"
        aria-hidden="true"
      ></span>
      } SAVE
    </button>
  </div>
  }
</div>
