import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { VideoEditComponent } from '@admin/components/video-edit/video-edit.component';
import { ACTION_LOADING } from '@admin/campaigns/constants/variables';
import { FormComponent } from '@shared/components';
import {
  EarnedMedia,
  CampaignEarnedMedia,
} from '@admin/models/EarnedMedia.model';
import { DecimalPipe, NgClass } from '@angular/common';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { EarnedMediaVideoComponent } from '@admin/components/earned-media-video/earned-media-video.component';

@Component({
  selector: 'app-earned-media',
  standalone: true,
  imports: [
    FormsModule,
    DecimalPipe,
    VideoEditComponent,
    EarnedMediaVideoComponent,
  ],
  templateUrl: './earned-media.component.html',
  styleUrl: './earned-media.component.scss',
})
export class EarnedMediaComponent extends FormComponent {
  @Input() override data: EarnedMedia = new EarnedMedia();
  @Input() typeId: number = 0;
  collapsed: boolean = false;

  ngOnInit(): void {
    this.endpoint = `admin/segments/broadcast/${this.data.id}`;
    this.method = 'put';

    if (this.data.matches.length === 0) {
      this.newMatch();
    }

    this.calculateTotal();
  }

  newMatch() {
    const nMedia = new CampaignEarnedMedia();

    this.data.matches.push(nMedia);

    setTimeout(() => {
      const element = document.getElementById(
        `match-${this.data.matches.length}`
      );

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, ACTION_LOADING - 80);
  }

  deleteMatch(index: number) {
    this.data.matches.splice(index, 1);
  }

  override isValid(): boolean {
    this.data.matches = this.data.matches.filter((match) => {
      if (!match.video) {
        return false;
      }

      return true;
    });

    const valid = this.data.matches.length > 0;

    if (!valid) {
      this.handleAlert('Match is required');
      this.newMatch();
    }

    return valid;
  }

  override getParams() {
    const params = {
      impressions: this.data.summary.totalViews,
      items: this.data.matches,
    };

    return params;
  }

  override success(response: any): void {
    this.handleAlert(`¡Changes saved successfully!`, 'success');

    this.stopLoading();
  }

  calculateTotal() {
    this.data.calculateSummary();
  }
}
