import { Component, input, Input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DecimalPipe, NgClass } from '@angular/common';

import {
  NgbCollapseModule,
  NgbPopoverModule,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';

import { MatchModel, TimeMatch, TimeModel } from '@admin/models/Match.model';
import { FormComponent } from '@shared/components';
import { SimpleNameElement } from '@shared/models/SimpleNameElement.model';
import { TimeControllerComponent } from '../time-controller/time-controller.component';
import { MediaEditComponent } from '../media-edit/media-edit.component';
import { CustomDatePickerComponent } from '../custom-date-picker/custom-date-picker.component';
import { CustomInputNumberComponent } from '../custom-input-number/custom-input-number.component';

@Component({
  selector: 'app-campaign-time-controller',
  imports: [
    FormsModule,
    NgClass,
    DecimalPipe,
    NgSelectModule,
    NgbPopoverModule,
    NgbCollapseModule,
    NgbTimepickerModule,
    TimeControllerComponent,
    CustomDatePickerComponent,
    CustomInputNumberComponent,
    MediaEditComponent,
  ],
  templateUrl: './campaign-time-controller.component.html',
  styleUrl: './campaign-time-controller.component.scss',
})
export class CampaignTimeControllerComponent extends FormComponent {
  @Input() override data: MatchModel = new MatchModel();
  viewership = input<string>();

  onChange = output<void>();
  onMedia = output<any[]>();
  onDelete = output<void>();

  channels: SimpleNameElement[] = [];
  collapsed: boolean = false;
  minutes: number[] = Array.from({ length: 131 }, (_, i) => i);
  seconds: number[] = Array.from({ length: 61 }, (_, i) => i);
  media: any[] = [];

  override services: Observable<any>[] = [
    this.api.call(`admin/channels?limit=1000`),
  ];

  ngOnInit(): void {
    this.data.firstTimes = this.data.firstTimes.map((timeMatch: any) => {
      return plainToClass(TimeMatch, timeMatch);
    });

    this.data.secondTimes = this.data.secondTimes.map((timeMatch: any) => {
      return plainToClass(TimeMatch, timeMatch);
    });

    this.calculateTimeContracted();
    this.calculateTotalImpressions();
    this.calculateUnits();

    this.loadServices();
  }

  handleSelectMedia(media: any, index: number) {
    this.media[index] = media;
    console.debug(this.media);

    this.onMedia.emit(this.media);
  }

  override onLoadServicesSuccess(responses: any[]): void {
    if (responses[0].status === 200 && responses[0].data.rows) {
      this.channels = responses[0].data.rows;
    }

    console.debug(this.data);

    super.onLoadServicesSuccess(responses);
  }

  deleteMatch() {
    this.onDelete.emit();
  }

  calculateUnits() {
    this.data.timeContracted.setTime(this.data.unitsContracted * 30);
  }

  calculateTotalImpressions() {
    this.data.impressions = this.data.viewership * this.data.unitsDelivered;
    this.data.impressions = parseFloat(this.data.impressions.toFixed(2));
    this.onChange.emit();
  }

  calculateTimeContracted() {
    console.debug('change', this.data);

    // ! DEFINED ON FORMULA
    const DIVIDER = 30;

    this.data.timeDelivered.setTime(
      this.data.totalFirst.totalTime() + this.data.totalSecond.totalTime()
    );

    this.data.unitsDelivered = this.data.timeDelivered.totalTime() / DIVIDER;
    this.calculateTotalImpressions();

    this.onChange.emit();
  }
}
