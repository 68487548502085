import { Component, EventEmitter, Input, output, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { Broadcast } from '@admin/models/Campaign.model';
import { Menu } from '@admin/models/Menu.model';
import { CampaignSeason } from '@admin/models/Playoffs.model';
import { MainCardComponent } from '@shared/components';

@Component({
  selector: 'app-monthly-summary',
  standalone: true,
  imports: [FormsModule, MainCardComponent],
  templateUrl: './monthly-summary.component.html',
  styleUrl: './monthly-summary.component.scss',
})
export class MonthlySummaryComponent {
  @Input() broadcasts: Broadcast[] = [];
  @Input() data: CampaignSeason = new CampaignSeason();
  @Input() typeId: number = 0;
  onStepChange = output<number>();

  menuItems: Menu[] = [];

  ngOnInit(): void {
    this.menuItems = [];

    this.broadcasts.forEach((broadcast) => {
      const nItem = this.getItem(broadcast.broadcast_type_id);

      if (nItem) {
        this.menuItems.push(nItem);
      }
    });

    this.menuItems.push({
      id: 4,
      title: 'EARNED MEDIA',
      actions: 'Add / View',
      icon: 'fa-solid fa-video',
      route: '',
    });

    this.calculateMonthly();
  }

  calculateMonthly() {
    this.data.data.fieldboard.calculateSummary();
    this.data.data.keyMoments.calculateSummary();
    this.data.data.nearGoal.calculateSummary();
    this.data.data.earnedMedia.calculateSummary();

    this.data.data.fieldboard.calculateCPMContrated();
    this.data.data.keyMoments.calculateCPMContrated();
    this.data.data.nearGoal.calculateCPMDelivered();

    this.data.calculateUnitsContracted();
    this.data.calculateUnitsDelivered();
    this.data.calculateImpressionsContracted();
    this.data.calculateImpressionsDelivered();
  }

  getItem(id: number): Menu | null {
    switch (id) {
      case 2:
        return {
          id: 1,
          title: 'FIELDBOARDS',
          actions: 'Add / View',
          icon: 'fa-solid fa-star-of-life',
          route: '',
        };
      case 3:
        return {
          id: 2,
          title: 'KEY MOMENTS',
          actions: 'Add / View',
          icon: 'fa-solid fa-star',
          route: '',
        };
      case 4:
        return {
          id: 3,
          title: 'NEAR GOAL',
          actions: 'Add / View',
          icon: 'fa-solid fa-circle',
          route: '',
        };

      default:
        return null;
    }
  }

  goTo(id: number) {
    this.onStepChange.emit(id);
  }
}
