import { Routes } from '@angular/router';

import { environment } from '@environments';
import { adminGuard } from '@shared/guards/admin.guard';

import { ReportType } from './enums/ReportType.enum';
import {
  CampaignsComponent,
  CampaignsFormComponent,
  CampaignsShowComponent,
  ChannelsComponent,
  ChannelsFormComponent,
  ClientsComponent,
  ClientsFormComponent,
  GeneralReportComponent,
  HomeComponent,
  PermissionsComponent,
  PermissionsFormComponent,
  PrivacyComponent,
  ProfileComponent,
  PropertiesComponent,
  PropertiesFormComponent,
  RolesComponent,
  RolesFormComponent,
  SegmentFormComponent,
  SegmentsComponent,
  TermsComponent,
  UsersComponent,
  UsersFormComponent,
} from './pages';

export const adminRoutes: Routes = [
  // * CRUD ROUTES
  {
    path: 'campaigns',
    title: `Campaigns - ${environment.title}`,
    canActivate: [adminGuard],
    data: { requiredRoles: ['admin.campaigns.index'], breadcrumb: 'Campaigns' },
    children: [
      {
        path: '',
        component: CampaignsComponent,
      },
      {
        path: 'client/:clientId',
        component: CampaignsComponent,
      },
      {
        path: 'create',
        component: CampaignsFormComponent,
      },
      {
        path: 'edit/:id',
        component: CampaignsFormComponent,
      },
      {
        path: 'show/:id',
        children: [
          {
            path: '',
            component: CampaignsShowComponent,
          },
          {
            path: 'client/:clientId',
            children: [
              {
                path: '',
                component: CampaignsShowComponent,
              },
              // ! periodId = typeId
              {
                path: 'period/:periodId/property/:propertyId',
                component: CampaignsShowComponent,
              },
            ],
          },
          // ! periodId = typeId
          {
            path: 'period/:periodId/property/:propertyId',
            component: CampaignsShowComponent,
          },
        ],
      },
      {
        path: ':id',
        children: [
          {
            path: 'general-report',
            children: [
              {
                path: '',
                component: GeneralReportComponent,
                data: {
                  type: ReportType.general,
                },
              },
              // ! periodId = typeId
              {
                path: 'period/:periodId',
                children: [
                  {
                    path: '',
                    component: GeneralReportComponent,
                    data: {
                      type: ReportType.quarter,
                    },
                  },
                  {
                    path: 'type/:typeId',
                    component: GeneralReportComponent,
                    data: {
                      type: ReportType.month,
                    },
                  },
                ],
              },
            ],
          },
          // ! periodId = typeId
          {
            path: 'period/:periodId/property/:propertyId',
            children: [
              {
                path: 'client/:clientId/:type/:typeId',
                children: [
                  {
                    path: '',
                    component: SegmentsComponent,
                  },
                  {
                    path: 'segment/:segmentId',
                    component: SegmentFormComponent,
                  },
                  {
                    path: '**',
                    redirectTo: '',
                  },
                ],
              },
              {
                path: ':type/:typeId',
                children: [
                  {
                    path: '',
                    component: SegmentsComponent,
                  },
                  {
                    path: 'segment/:segmentId',
                    component: SegmentFormComponent,
                  },
                  {
                    path: '**',
                    redirectTo: '',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'channels',
    title: `Channels - ${environment.title}`,
    canActivate: [adminGuard],
    data: { requiredRoles: ['admin.channels.index'] },
    children: [
      {
        path: '',
        component: ChannelsComponent,
      },
      {
        path: 'create',
        component: ChannelsFormComponent,
      },
      {
        path: 'edit/:id',
        component: ChannelsFormComponent,
      },
      {
        path: 'show/:id',
        component: ChannelsFormComponent,
        data: { readonly: true },
      },
    ],
  },
  {
    path: 'clients',
    title: `Clients - ${environment.title}`,
    canActivate: [adminGuard],
    data: { requiredRoles: ['admin.clients.index'] },
    children: [
      {
        path: '',
        component: ClientsComponent,
      },
      {
        path: 'create',
        component: ClientsFormComponent,
      },
      {
        path: 'edit/:id',
        component: ClientsFormComponent,
      },
      {
        path: 'show/:id',
        component: ClientsFormComponent,
        data: { readonly: true },
      },
    ],
  },
  {
    path: 'permissions',
    title: `Permissions - ${environment.title}`,
    canActivate: [adminGuard],
    data: { requiredRoles: ['admin.permissions.index'] },
    children: [
      {
        path: '',
        component: PermissionsComponent,
      },
      {
        path: 'create',
        component: PermissionsFormComponent,
      },
      {
        path: 'edit/:id',
        component: PermissionsFormComponent,
      },
      {
        path: 'show/:id',
        component: PermissionsFormComponent,
        data: { readonly: true },
      },
    ],
  },
  {
    path: 'properties',
    title: `Properties - ${environment.title}`,
    canActivate: [adminGuard],
    data: { requiredRoles: ['admin.properties.index'] },
    children: [
      {
        path: '',
        component: PropertiesComponent,
      },
      {
        path: 'create',
        component: PropertiesFormComponent,
      },
      {
        path: 'edit/:id',
        component: PropertiesFormComponent,
      },
      {
        path: 'show/:id',
        component: PropertiesFormComponent,
        data: { readonly: true },
      },
    ],
  },
  {
    path: 'roles',
    title: `Roles - ${environment.title}`,
    canActivate: [adminGuard],
    data: { requiredRoles: ['admin.roles.index'] },
    children: [
      {
        path: '',
        component: RolesComponent,
      },
      {
        path: 'create',
        component: RolesFormComponent,
      },
      {
        path: 'edit/:id',
        component: RolesFormComponent,
      },
      {
        path: 'show/:id',
        component: RolesFormComponent,
        data: { readonly: true },
      },
    ],
  },
  {
    path: 'users',
    title: `Users - ${environment.title}`,
    canActivate: [adminGuard],
    data: { requiredRoles: ['admin.users.index'] },
    children: [
      {
        path: '',
        component: UsersComponent,
      },
      {
        path: 'create',
        component: UsersFormComponent,
      },
      {
        path: 'edit/:id',
        component: UsersFormComponent,
      },
      {
        path: 'show/:id',
        component: UsersFormComponent,
        data: { readonly: true },
      },
    ],
  },
  // * CRUD ROUTES
  {
    path: 'privacy',
    component: PrivacyComponent,
    title: `Privacy policy - ${environment.title}`,
  },
  {
    path: 'home',
    component: HomeComponent,
    title: `Home - ${environment.title}`,
  },
  {
    path: 'settings',
    component: ProfileComponent,
  },
  {
    path: 'terms',
    component: TermsComponent,
    title: `Terms - ${environment.title}`,
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];
