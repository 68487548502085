import {
  Component,
  Input,
  Signal,
  computed,
  input,
  output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterLink } from '@angular/router';
import { FormComponent } from '@shared/components';
import { ElementModel } from '@shared/models/Element.model';

export interface Search {
  filter: string;
  term: string;
}

@Component({
  selector: 'app-search-bar',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    RouterLink,
  ],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
})
export class SearchBarComponent extends FormComponent {
  @Input() canCreate: boolean = false;
  @Input() createRoute: string = '';
  @Input() disabled: boolean = false;
  @Input() filters: ElementModel[] = [];
  @Input() name: string = '';
  // @Output() onSearch: EventEmitter<Search> = new EventEmitter();
  onSearch = output<Search>();
  override data: Search = {
    filter: '',
    term: '',
  };
  onInput: Signal<void> = computed(() => {
    this.search();
  });

  search() {
    if (this.loading) {
      return;
    }

    this.loading = true;

    setTimeout(() => {
      this.onSearch.emit(this.data);
      this.loading = false;
    }, 500);
  }
}
