import { CrudComponent } from '@admin/components';
import { Component } from '@angular/core';

import { CrudModule } from '@admin/components/crud/crud.module';
import { Pagination } from '@shared/models/Pagination.model';
import { Main } from '@shared/models/Main.model';
import { PropertiesFormComponent } from './properties-form/properties-form.component';

class Property extends Main {
  id: number = -1;
  name: string = '';
  property: string | null = null;
  [key: string]: any;
}

@Component({
    selector: 'app-properties',
    imports: [CrudModule],
    templateUrl: '../../components/crud/crud.component.html',
    styleUrl: './properties.component.scss'
})
export class PropertiesComponent extends CrudComponent {
  override title: string = 'Properties';
  override data: Pagination<Property> = new Pagination(Property);

  override handleLoadResponse(responses: any[]): void {
    if (responses[0].data) this.data.setData(responses[0].data);

    this.pagination.set(this.data.rows.length > 0);
    this.stopLoading();
  }

  override settings(): void {
    this.showComponent = PropertiesFormComponent;
    this.editComponent = PropertiesFormComponent;
    this.modalOptions.scrollable = false;
  }
}
