import { NgClass } from '@angular/common';
import { Component, Input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { CampaignEarnedMedia } from '@admin/models/EarnedMedia.model';
import { MediaEditComponent } from '../media-edit/media-edit.component';
import { CustomInputNumberComponent } from '../custom-input-number/custom-input-number.component';

@Component({
  selector: 'app-earned-media-video',
  imports: [
    FormsModule,
    NgClass,
    NgbCollapseModule,
    MediaEditComponent,
    CustomInputNumberComponent,
  ],
  templateUrl: './earned-media-video.component.html',
  styleUrl: './earned-media-video.component.scss',
})
export class EarnedMediaVideoComponent {
  @Input() index: number = 0;
  @Input() data: CampaignEarnedMedia = new CampaignEarnedMedia();

  onDelete = output<void>();
  onChange = output<void>();
  onMedia = output<any[]>();
  collapsed: boolean = false;

  media: any[] = [];

  deleteMatch() {
    this.onDelete.emit();
  }

  onVideoChange() {
    this.onChange.emit();
  }

  handleSelectMedia(media: any, index: number) {
    this.media[index] = media;
    console.debug(this.media);

    this.onMedia.emit(this.media);
  }
}
