import { Component, inject, signal, viewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, NgClass } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';

import {
  ConfirmModalComponent,
  FormComponent,
  MainCardComponent,
  TitleComponent,
} from '@shared/components';
import { Campaign } from '@admin/models/Campaign.model';
import { CampaignType, Property } from '@admin/models/CampaignType.model';
import { RolesService } from '@services/roles.service';
import { fadeInAnimation } from '@shared/animations/fade.animation';
import { SlugifyPipe } from '@shared/pipes/slugify.pipe';
import { TypeFormComponent } from './type-form/type-form.component';

const GROUP = 'admin.types';

@Component({
  selector: 'app-campaigns-show',
  imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    TypeFormComponent,
    MainCardComponent,
    TitleComponent,
  ],
  animations: [fadeInAnimation],
  templateUrl: './campaigns-show.component.html',
  styleUrl: './campaigns-show.component.scss',
  providers: [SlugifyPipe],
})
export class CampaignsShowComponent extends FormComponent {
  private location = inject(Location);
  private modalService = inject(NgbModal);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  protected roles = inject(RolesService);
  slugify = inject(SlugifyPipe);

  // * -1 = nothing state, 0 > item id to edit
  editing: number = -1;
  creating: boolean = false;
  campaign: Campaign = new Campaign();
  title: string = 'CLIENT NAME / CAMPAIGN NAME';
  subtitle: string = '';

  campaignId: number = -1;
  clientId: number = -1;
  // ! For Quearters
  periodId: number = -1;
  // ! For months
  propertyId: number = -1;

  items: CampaignType[] = [];
  properties: Property[] = [];
  page: number = 1;
  limit: number = 11;

  // ! ACTIONS CONTROLS
  canCreate = signal(false);
  canDelete = signal(false);
  canEdit = signal(false);
  canUpdate = signal(false);
  // ! ACTIONS CONTROLS

  createForm = viewChild<TypeFormComponent>('new');
  editForm = viewChild<TypeFormComponent>('edit');

  ngOnInit(): void {
    this.roles.roles$.subscribe((loaded) => {
      if (loaded) {
        this.canCreate.set(this.roles.can(GROUP + '.create'));
        this.canEdit.set(this.roles.can(GROUP + '.edit'));
        this.canDelete.set(this.roles.can(GROUP + '.destroy'));
        this.canUpdate.set(this.roles.can(GROUP + '.update'));
      }
    });

    this.route.paramMap.subscribe((params) => {
      this.campaignId = Number(params.get('id') ?? -1);
      this.clientId = Number(params.get('clientId') ?? -1);
      this.periodId = Number(params.get('periodId') ?? -1);
      this.propertyId = Number(params.get('propertyId') ?? -1);

      const data = this.route.snapshot.data;
      this.subtitle = data['title'] || '';

      if (this.loading) return;

      this.services = [this.api.call(`admin/campaigns/${this.campaignId}`)];

      if (this.periodId !== -1) {
        this.services.push(
          this.api.call(
            `admin/campaigns/${this.campaignId}/types?type_id=${this.periodId}`
          )
        );
      } else {
        this.services.push(
          this.api.call(`admin/campaigns/${this.campaignId}/types`)
        );

        this.services.push(this.api.call('admin/catalogs/properties'));
      }

      this.loadServices();
    });
  }

  override getServices(): Observable<any>[] {
    const services = [this.api.call(`admin/campaigns/${this.campaignId}`)];

    if (this.periodId !== -1) {
      services.push(
        this.api.call(
          `admin/campaigns/${this.campaignId}/types?type_id=${this.periodId}`
        )
      );
    } else {
      services.push(this.api.call(`admin/campaigns/${this.campaignId}/types`));
      this.services.push(this.api.call('admin/catalogs/properties'));
    }

    return services;
  }

  // /admin/campaigns/1/segments
  override onLoadServicesSuccess(responses: any[]): void {
    if (responses[2] && responses[2].status === 200)
      this.properties = responses[2].data;

    if (responses[0].status === 200) {
      this.campaign = plainToClass(Campaign, responses[0].data);

      let client: string | null = null;

      if (this.campaign.clients.length > 0 && this.clientId !== -1) {
        client =
          this.campaign.clients.find((client) => client.id === this.clientId)
            ?.name || null;
      }

      if (!client && this.campaign.clients.length > 0) {
        client = this.campaign.clients[0].name;
      }

      this.title = `${client ? client + ' / ' : ''}${this.campaign.name}`;

      this.subtitle = this.campaign.property_other
        ? this.campaign.property_other
        : this.properties.find((prop) => prop.id === this.campaign.property_id)
            ?.name || '';
    }

    if (responses[1].status === 200 && responses[1].data.rows) {
      const items: CampaignType[] = responses[1].data.rows.map((item: any) => {
        const nType = plainToClass(CampaignType, item);

        nType.setData(item);

        return nType;
      });

      if (this.page === 1) {
        this.items = [...items];
      } else {
        this.items = [...this.items, ...items];
      }
    }

    if (this.propertyId !== -1) {
      this.loadProperties();
    }

    this.stopLoading();
  }

  getProperty(property: Property | null): Property {
    let nProperty = property ? property : new Property();

    if (property?.property !== null && property?.property !== undefined) {
      nProperty = this.getProperty(property.property);
    }

    return nProperty;
  }

  loadProperties() {
    this.api
      .call(`admin/catalogs/properties/${this.propertyId}`)
      .subscribe((response) => {
        if (response && response.status === 200) {
          this.properties = response.data;
        }
      });
  }

  loadMore() {
    this.page += 1;
    this.loadServices();
  }

  handlePrymary(item: CampaignType) {
    if (this.editing !== -1) {
      this.editForm()?.submit();
    } else {
      let route = '';

      if (this.periodId !== -1) {
        route = `admin/campaigns/${this.campaignId}/period/${this.periodId}${
          this.propertyId !== -1 ? '/property/' + this.propertyId : ''
        }${
          this.clientId !== -1 ? '/client/' + this.clientId : ''
        }/${encodeURIComponent(
          this.slugify.transform(item.property?.name || 'period')
        )}/${item.id}`;
      } else {
        route = `admin/campaigns/show/${this.campaignId}${
          this.clientId !== -1 ? '/client/' + this.clientId : ''
        }/period/${item.id}/property/${item.property.id}`;
      }

      this.router.navigate([route]);
    }
  }

  generalReport() {
    this.router.navigate([
      `/admin/campaigns/${this.campaignId}/general-report${
        this.periodId !== -1 ? '/period/' + this.periodId : ''
      }`,
    ]);
  }

  handleSubmit() {
    this.cancelNew();
    this.loadServices();
  }

  cancelNew() {
    this.creating = false;
    this.editing = -1;
  }

  createType() {
    this.creating = true;
    this.editing = -1;
  }

  editType(id: number) {
    this.creating = false;
    this.editing = this.editing === id ? -1 : id;
  }

  deleteModal(id: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      backdrop: 'static',
      centered: true,
      // backdrop: false,
    });

    modalRef.componentInstance.title = 'DELETE TYPE';
    modalRef.componentInstance.message =
      'Do you really want to delete the type?';
    modalRef.result.then(
      (result: any) => {
        if (result) {
          this.deleteSegment(id);
        }
      },
      (_reason) => {}
    );
  }

  deleteSegment(id: number) {
    if (this.loading) return;

    this.startLoading();

    this.api.call(`admin/types/${id}`, 'delete', {}).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.handleAlert('Type deleted successfully!', 'success');
        } else {
          this.handleAlert(
            Array.isArray(response.message)
              ? response.message.join(', ')
              : response.message
          );
        }

        this.loadServices();
      },
      error: (error) => {
        this.handleAlert('An unexpected error occurred!');
        console.error(error);
        this.stopLoading();
      },
    });
  }

  goBack() {
    const clientRoute = this.clientId !== -1 ? '/client/' + this.clientId : '';

    const route =
      this.periodId !== -1
        ? `/admin/campaigns/show/${this.campaignId}${clientRoute}`
        : `/admin/campaigns${clientRoute}`;

    this.router.navigate([route]);
  }
}
