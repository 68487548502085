import { Component } from '@angular/core';

import { CrudComponent } from '@admin/components';

import { ElementModel } from '@shared/models/Element.model';
import { Pagination } from '@shared/models/Pagination.model';
import { Client } from '@admin/models/Client.model';
import { CrudModule } from '@admin/components/crud/crud.module';
import { MainCardComponent } from '@shared/components';

@Component({
    selector: 'app-clients',
    imports: [CrudModule, MainCardComponent],
    templateUrl: './clients.component.html',
    styleUrl: './clients.component.scss'
})
export class ClientsComponent extends CrudComponent {
  override data: Pagination<Client> = new Pagination(Client);

  override handleLoadResponse(responses: any[]): void {
    if (responses[0].data) this.data.setData(responses[0].data);

    this.pagination.set(this.data.rows.length > 0);
    this.stopLoading();
  }

  override onPrimary(id: number) {
    this.router.navigate([`/admin/campaigns/client/${id}`]);
  }
}
