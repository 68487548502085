import { Location, NgClass } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NgSelectModule } from '@ng-select/ng-select';

import {
  ConfirmModalComponent,
  FormComponent,
  MainCardComponent,
  TitleComponent,
} from '@shared/components';
import { Campaign } from '@admin/models/Campaign.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RolesService } from '@services/roles.service';
import { SegmentType } from '@admin/models/Playoffs.model';
import { SlugifyPipe } from '@shared/pipes/slugify.pipe';

class Season {
  id: number = -1;
  name: string = '';
  comments: string = '';
  published: boolean = false;
  // * hardcoded because is regular season, check catalog admin/catalogs/types to confirm
  type_id: number = -1;
  type: SegmentType = new SegmentType();
}

const GROUP = 'admin.segments';

@Component({
  selector: 'app-segments',
  standalone: true,
  imports: [
    NgClass,
    NgSelectModule,
    FormsModule,
    MainCardComponent,
    TitleComponent,
  ],
  templateUrl: './segments.component.html',
  styleUrl: './segments.component.scss',
})
export class SegmentsComponent extends FormComponent {
  protected modalService = inject(NgbModal);
  protected route = inject(ActivatedRoute);
  protected router = inject(Router);
  protected roles = inject(RolesService);
  location = inject(Location);

  // * -1 = nothing state, 0 > index to edits
  editing: number = -1;
  creating: boolean = false;
  campaign: Campaign = new Campaign();
  nSeason: Season = new Season();
  title: string = 'CLIENT NAME / CAMPAIGN NAME';
  subtitle: string = '';
  campaignId: number = -1;
  type: string = '';
  typeId: number = -1;
  items: Season[] = [];
  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // ! ACTIONS CONTROLS
  canCreate = signal(false);
  canDelete = signal(false);
  canEdit = signal(false);
  canUpdate = signal(false);
  // ! ACTIONS CONTROLS

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.type = String(params.get('type') || '');
      this.type = decodeURIComponent(this.type).toUpperCase();
      this.campaignId = Number(params.get('id') ?? -1);
      this.typeId = Number(params.get('typeId') ?? -1);

      const data = this.route.snapshot.data;
      this.subtitle = data['title'] || '';

      if (this.loading) return;

      this.services = [
        this.api.call(`admin/campaigns/${this.campaignId}`),
        this.api.call(
          `admin/campaigns/${this.campaignId}/segments?type_id=${this.typeId}&order=id|ASC`
        ),
      ];

      this.loadServices();
    });

    this.roles.roles$.subscribe((loaded) => {
      if (loaded) {
        this.canCreate.set(this.roles.can(GROUP, 'create'));
        this.canEdit.set(this.roles.can(GROUP, 'edit'));
        this.canDelete.set(this.roles.can(GROUP, 'destroy'));
        this.canUpdate.set(this.roles.can(GROUP, 'update'));
      }
    });
  }

  // /admin/campaigns/1/segments
  override onLoadServicesSuccess(responses: any[]): void {
    if (responses[0].status === 200) {
      this.campaign = responses[0].data;

      this.title = `${
        this.campaign.clients.length > 0 ? this.campaign.clients[0].name : ''
      } / ${this.campaign.name}`;
    }

    if (responses[1].status === 200 && responses[1].data.rows) {
      this.items = responses[1].data.rows.map((segment: any) => ({
        id: segment.id,
        name: segment.name || '',
        published: segment.published ? Boolean(segment.published) : false,
        comments: segment.comments || '',
        type: segment.type || new SegmentType(),
      }));

      // ! DEBUG
      // console.log(this.campaign);
      // console.log(this.data);
      // console.log(this.items);
      // ! DEBUG
    }

    this.stopLoading();
  }

  override getParams() {
    this.nSeason.type_id = this.typeId;
    const params: any = this.nSeason;

    if (this.nSeason.id === -1) {
      this.method = 'post';
      this.endpoint = `admin/campaigns/${this.campaignId}/segments`;

      delete params.id;
    } else {
      this.method = 'put';
      this.endpoint = `admin/segments/${this.nSeason.id}`;
    }

    return params;
  }

  handlePrymary(season: Season) {
    if (this.editing !== -1) {
      if (this.editing !== season.id) {
        this.editMonth(season.id);

        return;
      }

      this.editing = -1;

      if (this.nSeason.id !== -1) {
        this.submit();
      }
    } else {
      const slugify = new SlugifyPipe();

      this.router.navigate([
        `admin/campaigns/${this.campaignId}/type/${this.typeId}/segment/${season.id}`,
      ]);
    }
  }

  cancelNew() {
    this.creating = false;
    this.nSeason = new Season();
  }

  createMonth() {
    this.creating = true;
    this.editing = -1;
  }

  editMonth(id: number) {
    this.creating = false;
    this.nSeason = new Season();
    this.editing = this.editing === id ? -1 : id;

    if (this.editing !== -1) {
      this.nSeason = this.items.find((item) => item.id === id) || new Season();

      if (this.nSeason.id !== -1) {
        this.nSeason = { ...this.nSeason };
      }
    }
  }

  onEdit(ind: number) {
    this.items[ind] = this.nSeason;
    this.nSeason = new Season();
    this.editing = -1;
  }

  back(): void {
    this.location.back();
  }

  deleteModal(id: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      backdrop: 'static',
      centered: true,
      // backdrop: false,
    });

    modalRef.componentInstance.title = 'DELETE SEGMENT';
    modalRef.componentInstance.message =
      'Do you really want to delete the segment?';
    modalRef.result.then(
      (result: any) => {
        if (result) {
          this.deleteSegment(id);
        }
      },
      (reason) => {}
    );
  }

  deleteSegment(id: number) {
    //
    if (this.loading) {
      return;
    }

    this.startLoading();

    this.api.call(`admin/segments/${id}`, 'delete', {}).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.handleAlert('Segment deleted successfully!', 'success');
        } else {
          this.handleAlert(
            Array.isArray(response.message)
              ? response.message.join(', ')
              : response.message
          );
        }

        this.loadServices();
      },
      error: (error) => {
        this.handleAlert('An unexpected error occurred!');
        console.error(error);
        this.stopLoading();
      },
    });
  }

  override isValid(): boolean {
    return !!this.nSeason.name;
  }

  override success(response: any): void {
    this.handleAlert(
      `¡${
        this.nSeason.id === -1 ? 'Item created ' : 'Changes saved'
      } successfully!`,
      'success'
    );
    this.endpoint = 'this.getFormatEndpoint()';
    this.cancelNew();

    this.loadServices();
  }
}
