<app-title
  [title]="title"
  [subtitle]="subtitle"
  [description]="data.description"
>
  <div class="row py-3">
    <div class="col-auto ms-auto">
      <button type="button" class="btn btn-secondary px-4" (click)="goBack()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
    </div>
  </div>

  <!-- ! DEBUG -->
  <!-- <div class="row">
    <div class="col-12 px-0">
      <span class="badge text-bg-warning">
        Current editing: {{ editing }} {{ endpoint }} {{ method }}
      </span>
    </div>
  </div> -->
  <!-- ! DEBUG -->
</app-title>

<div class="container py-3 py-lg-4 py-xl-5">
  <div
    class="row gy-3 gy-md-4"
    [ngClass]="{ 'card-height': editing !== -1 || creating }"
  >
    <div class="col-md-6 col-xl-4 py-3 py-lg-4 border-md-end border-primary">
      <app-main-card
        icon="fa-solid fa-file-lines"
        (onPrimary)="generalReport()"
        primary="arrow_forward_ios"
        secondary=""
      >
        <div class="row gx-2">
          <div class="col-12 text-center pb-2">
            <h5 class="mb-2">Add / View</h5>

            <h5 class="fw-bold mb-0 text-primary">General Report</h5>
          </div>
        </div>
      </app-main-card>
    </div>

    @if (canCreate()) {
    <div class="col-md-6 col-xl-4 my-auto">
      @if (creating) {
      <app-main-card
        [hasImage]="false"
        primary="save"
        secondary="close"
        (onSecondary)="cancelNew()"
        (onPrimary)="submit()"
      >
        <form class="row gx-2 gy-3 gy-lg-4" [formGroup]="nSegment">
          <div class="col-12">
            <p class="fw-bold mb-1">Property</p>

            <p class="mb-2">Assigns an existing property to the campaign.</p>

            <ng-select
              id="tag"
              appearance="outline"
              formControlName="property_id"
              placeholder="Select a property"
              [clearable]="false"
              [searchable]="false"
            >
              @for (item of properties; track item.id) {
              <ng-option [value]="item.id">{{ item.name }}</ng-option>
              }

              <ng-option [value]="-1">Other</ng-option>
            </ng-select>

            @if ( nSegment.get('property_id')?.invalid &&
            (nSegment.get('property_id')?.dirty ||
            nSegment.get('property_id')?.touched)) {
            <div id="tagValidation" class="text-danger">
              Property is required
            </div>
            }
          </div>

          @if (nSegment.get('property_id')?.value === -1) {
          <div class="col-12" @fadeIn>
            <p class="fw-bold mb-1">New property</p>

            <p class="mb-2">If you need a new property write it below.</p>

            <input
              id="campaign_property"
              type="text"
              class="form-control"
              formControlName="property_other"
              [ngClass]="{
                'is-invalid':
                  nSegment.get('property_other')?.invalid &&
                  (nSegment.get('property_other')?.dirty ||
                    nSegment.get('property_other')?.touched)
              }"
            />

            @if ( nSegment.get('property_other')?.invalid &&
            (nSegment.get('property_other')?.dirty ||
            nSegment.get('property_other')?.touched)) {
            <div id="validationProperty" class="invalid-feedback">
              Property is required.
            </div>
            }
          </div>
          }
        </form>
      </app-main-card>
      } @else {
      <button
        class="btn btn-primary w-100 h-100 card-new"
        data-aos="flip-left"
        (click)="createType()"
      >
        <div class="d-flex w-100 h-100">
          <h1 class="m-auto"><i class="fa-solid fa-plus"></i></h1>
        </div>
      </button>
      }
    </div>
    } @for (item of items; track $index) {
    <div class="col-md-6 col-xl-4">
      <app-main-card
        icon="fa-solid fa-trophy"
        [primary]="
          canUpdate()
            ? editing === item.id
              ? 'save'
              : 'arrow_forward_ios'
            : ''
        "
        [secondary]="canEdit() ? (editing === item.id ? 'close' : 'edit') : ''"
        [extra]="canDelete() ? 'delete' : ''"
        (onPrimary)="handlePrymary(item)"
        (onSecondary)="editType(item.id)"
        (onExtra)="deleteModal(item.id)"
      >
        @if (item.id === editing) {
        <form class="row gx-2 gy-3 gy-lg-4" [formGroup]="nSegment" @fadeIn>
          <div class="col-12 mt-0">
            <p class="fw-bold mb-1">Property</p>

            <p class="mb-2">Assigns an existing property to the campaign.</p>

            <ng-select
              id="tag"
              appearance="outline"
              formControlName="property_id"
              placeholder="Select a property"
              [clearable]="false"
              [searchable]="false"
            >
              @for (item of properties; track item.id) {
              <ng-option [value]="item.id">{{ item.name }}</ng-option>
              }

              <ng-option [value]="-1">Other</ng-option>
            </ng-select>

            @if ( nSegment.get('property_id')?.invalid &&
            (nSegment.get('property_id')?.dirty ||
            nSegment.get('property_id')?.touched)) {
            <div id="tagValidation" class="text-danger">
              Property is required
            </div>
            }
          </div>

          @if (nSegment.get('property_id')?.value === -1) {
          <div class="col-12" @fadeIn>
            <p class="fw-bold mb-1">New property</p>

            <p class="mb-2">If you need a new property write it below.</p>

            <input
              id="campaign_property"
              type="text"
              class="form-control"
              formControlName="property_other"
              [ngClass]="{
                'is-invalid':
                  nSegment.get('property_other')?.invalid &&
                  (nSegment.get('property_other')?.dirty ||
                    nSegment.get('property_other')?.touched)
              }"
            />

            @if ( nSegment.get('property_other')?.invalid &&
            (nSegment.get('property_other')?.dirty ||
            nSegment.get('property_other')?.touched)) {
            <div id="validationProperty" class="invalid-feedback">
              Property is required.
            </div>
            }
          </div>
          }
        </form>
        } @else {
        <div class="row gx-2">
          <div class="col-12 text-center pb-2" @fadeIn>
            <h5 class="mb-2">Add / View</h5>

            <h5 class="fw-bold mb-0 text-primary">{{ item.name }}</h5>
          </div>
        </div>
        }
      </app-main-card>
    </div>
    } @if (items.length > limit) {
    <div class="col-12 text-center">
      <button type="button" class="btn btn-primary" (click)="loadMore()">
        Load more
      </button>
    </div>
    }
  </div>
</div>
