import { Component, HostListener, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import AOS from 'aos';

import { SharedModule } from '@shared/shared.module';
import { UserService } from '@services/user.service';
import { ApiService } from '@services/api.service';
import { LoadingService } from '@services/loading.service';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet, SharedModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  private user = inject(UserService);
  private api = inject(ApiService);
  private loadingService = inject(LoadingService);

  loading: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setAppHeight();
  }

  ngOnInit() {
    AOS.init();
    this.setAppHeight();

    this.user.loggedIn$.subscribe(() => {
      console.log('User logged in' + this.user.logged);

      if (this.user.logged) {
        this.loadProfile();
      }
    });
  }

  private setAppHeight() {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  }

  loadProfile() {
    if (!this.user.logged || this.user.profile) {
      this.loadingService.stop();
      return;
    }

    this.api.call('admin/profile/me').subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.user.profile = response.data;
        }

        this.loadingService.stop();
      },
      error: (error) => {
        console.error(error);

        this.loadingService.stop();
      },
    });
  }
}
