import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appSecondsInput]',
  standalone: true,
})
export class SecondsInputDirective {
  @Input('max') max: number = 59;

  constructor(private el: ElementRef<HTMLInputElement>) {}

  private isNumber(value: string) {
    return String(value).match(new RegExp(/^\d+$/));
  }

  private specialKeys = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
  ];

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    if (event.key == ' ') {
      event.preventDefault();
      return;
    }

    const next: string = event.key;

    if (next && !this.isNumber(next)) {
      event.preventDefault();
      return;
    }

    const inputValue: string = this.el.nativeElement.value;
    const nextString = inputValue.concat(next).replace(/ /g, '');
    const value = Number(nextString);

    if (value > this.max) {
      event.preventDefault();
      return;
    }
  }
}
