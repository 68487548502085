<app-crud-form-header
  [id]="dataId()"
  [disable]="readonly"
  [modal]="isComponent"
  title="user"
></app-crud-form-header>

<div [ngClass]="isComponent ? 'modal-body' : 'container py-3 py-lg-4 py-xl-5'">
  <form [formGroup]="data" (ngSubmit)="submit()" class="row gy-3 gy-lg-4">
    <div class="col-12 px-0">
      @if (initialized) {
      <app-image-controller
        title="Avatar"
        label="Add an image, accepts jpg and png formats, with a minimum size of 50px x 50px."
        [src]="currentTemplate.file || '/assets/images/avatar.png'"
        [disable]="readonly"
        (selectFile)="selectFile($event)"
      ></app-image-controller>
      }
    </div>

    <div class="col-lg-6">
      <label for="name" class="form-label">Name</label>

      <input
        id="name"
        name="name"
        type="text"
        class="form-control"
        formControlName="name"
        [ngClass]="{
          'is-invalid':
            data.get('name')?.invalid &&
            (data.get('name')?.dirty || data.get('name')?.touched)
        }"
      />

      @if (data.get('name')?.invalid && (data.get('name')?.dirty ||
      data.get('name')?.touched) ) {
      <div id="validationName" class="invalid-feedback">Name is required.</div>
      }
    </div>

    <div class="col-lg-6">
      <label for="lastname" class="form-label">Lastname</label>

      <input
        id="lastname"
        name="lastname"
        type="text"
        class="form-control"
        formControlName="lastname"
        [ngClass]="{
          'is-invalid':
            data.get('lastname')?.invalid &&
            (data.get('lastname')?.dirty || data.get('lastname')?.touched)
        }"
      />

      @if (data.get('lastname')?.invalid && (data.get('lastname')?.dirty ||
      data.get('lastname')?.touched) ) {
      <div id="validationLastName" class="invalid-feedback">
        Lastname is required.
      </div>
      }
    </div>

    <div class="col-lg-6">
      <label for="email" class="form-label">Email</label>

      <input
        id="email"
        name="email"
        type="email"
        class="form-control"
        formControlName="email"
        [ngClass]="{
          'is-invalid':
            data.get('email')?.invalid &&
            (data.get('email')?.dirty || data.get('email')?.touched)
        }"
      />

      @if (data.get('email')?.invalid && (data.get('email')?.dirty ||
      data.get('email')?.touched) ) {
      <div id="validationEmail" class="invalid-feedback">
        Email is required.
      </div>
      }
    </div>

    <div class="col-lg-6">
      <label for="email" class="form-label">Roles</label>

      <ng-select
        id="tag"
        appearance="outline"
        [items]="roles"
        bindLabel="name"
        bindValue="id"
        class="mt-auto"
        formControlName="roles"
        placeholder="Select Roles"
        [multiple]="true"
        [clearable]="false"
      ></ng-select>

      @if (data.get('roles')?.invalid && (data.get('roles')?.dirty ||
      data.get('roles')?.touched) ) {
      <div id="validationEmail" class="text-danger mt-1">
        @if (data.get('password')?.errors?.['required']) {
        <span> Role is required. </span>
        } @if (data.get('password')?.errors?.['minArrayLength']) {
        <span> Select at leat one role. </span>
        }
      </div>
      }
    </div>

    <div class="col-12">
      <label for="email" class="form-label">Client</label>

      <ng-select
        id="tag"
        appearance="outline"
        [items]="clients"
        bindLabel="name"
        bindValue="id"
        class="mt-auto"
        formControlName="client"
        placeholder="Select Client"
        [multiple]="false"
        [clearable]="false"
      ></ng-select>

      @if (data.get('client')?.invalid && (data.get('client')?.dirty ||
      data.get('client')?.touched) ) {
      <div id="validationEmail" class="text-danger mt-1">
        @if (data.get('password')?.errors?.['required']) {
        <span> Role is required. </span>
        } @if (data.get('password')?.errors?.['minArrayLength']) {
        <span> Select at leat one role. </span>
        }
      </div>
      }
    </div>

    <div class="col-12 px-4">
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          id="activeSwitch"
          formControlName="active"
        />

        <label class="form-check-label" for="activeSwitch"> Active </label>
      </div>
    </div>

    <div class="col-12">
      <p>Access</p>

      <div class="input-group mb-3">
        <input
          [type]="passwordVisible ? 'text' : 'password'"
          class="form-control"
          placeholder="Password"
          aria-label="Password"
          aria-describedby="button-password-toggle"
          formControlName="password"
          [ngClass]="{
            'is-invalid':
              data.get('password')?.invalid &&
              (data.get('password')?.dirty || data.get('password')?.touched)
          }"
        />

        <button
          id="button-password-toggle"
          class="btn btn-outline-primary"
          type="button"
          (click)="passwordVisible = !passwordVisible"
        >
          <i
            class="fa-solid"
            [ngClass]="passwordVisible ? 'fa-eye-slash' : 'fa-eye'"
          ></i>
        </button>

        @if (data.get('password')?.invalid && (data.get('password')?.dirty ||
        data.get('password')?.touched) ) {
        <div id="validationPassword" class="invalid-feedback">
          @if (data.get('password')?.errors?.['required']) {
          <span> Password is required. </span>
          }
        </div>
        }
      </div>

      <div class="input-group mb-3">
        <input
          [type]="passwordVisible ? 'text' : 'password'"
          class="form-control"
          placeholder="Confirm Password"
          aria-label="Confirm Password"
          aria-describedby="button-password-toggle"
          formControlName="confirm"
          [ngClass]="{
            'is-invalid':
              data.get('confirm')?.invalid &&
              (data.get('confirm')?.dirty || data.get('confirm')?.touched)
          }"
        />

        <button
          id="button-confirm-toggle"
          class="btn btn-outline-primary"
          type="button"
          (click)="passwordVisible = !passwordVisible"
        >
          <i
            class="fa-solid"
            [ngClass]="passwordVisible ? 'fa-eye-slash' : 'fa-eye'"
          ></i>
        </button>

        @if (data.get('confirm')?.invalid && (data.get('confirm')?.dirty ||
        data.get('confirm')?.touched) || !matchPassword ) {
        <div id="validationEmail" class="invalid-feedback">
          @if (data.get('password')?.errors?.['required']) {
          <span> Confirm Password is required. </span>
          } @if (!matchPassword) {
          <span> Passwords must match. </span>
          }
        </div>
        }
      </div>
    </div>

    <app-alert [content]="alertData"></app-alert>

    <div class="w-100 mt-0"></div>

    @if (!isComponent) {
    <div class="col-12 px-0">
      <app-crud-form-footer
        [created]="created"
        [disable]="readonly"
        [modal]="isComponent"
        [loading]="loadingService.getLoading()"
        (cancel)="cancel()"
      ></app-crud-form-footer>
    </div>
    }
  </form>
</div>

@if (isComponent) {
<app-crud-form-footer
  [created]="created"
  [disable]="readonly"
  [modal]="isComponent"
  [loading]="loadingService.getLoading()"
  (onSubmit)="submit()"
  (cancel)="cancel()"
></app-crud-form-footer>
}
