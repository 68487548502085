import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

import { ACTION_LOADING } from '@admin/campaigns/constants/variables';
import { FormComponent } from '@shared/components';
import {
  EarnedMedia,
  CampaignEarnedMedia,
} from '@admin/models/EarnedMedia.model';
import { EarnedMediaVideoComponent } from '@admin/components/earned-media-video/earned-media-video.component';

@Component({
  selector: 'app-earned-media',
  imports: [FormsModule, DecimalPipe, EarnedMediaVideoComponent],
  templateUrl: './earned-media.component.html',
  styleUrl: './earned-media.component.scss',
})
export class EarnedMediaComponent extends FormComponent {
  @Input() override data: EarnedMedia = new EarnedMedia();
  @Input() typeId: number = 0;

  collapsed: boolean = false;

  ngOnInit(): void {
    this.endpoint = `admin/segments/broadcast/${this.data.id}`;
    this.method = 'put';

    if (this.data.matches.length === 0) {
      this.newMatch();
    }

    this.calculateTotal();
  }

  handleSubmit() {
    console.debug(this.files);

    if (this.files.length > 0) {
      this.handleMedia().subscribe({
        next: (paths: string[]) => {
          let pIndex = 0;
          this.files.forEach((mathFile, index) => {
            mathFile.file.forEach((file) => {
              if (file instanceof File && paths[pIndex]) {
                this.data.matches[index].media = paths[pIndex];
                pIndex++;
              }
            });
          });

          this.submit();
        },
        error: (error) => {
          this.handleAlert(error || 'An error occurred');
          this.stopLoading();
        },
      });
    } else {
      this.submit();
    }
  }

  newMatch() {
    const nMedia = new CampaignEarnedMedia();

    this.data.matches.push(nMedia);

    setTimeout(() => {
      const element = document.getElementById(
        `match-${this.data.matches.length}`
      );

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, ACTION_LOADING - 80);
  }

  deleteMatch(index: number) {
    this.data.matches.splice(index, 1);
  }

  override isValid(): boolean {
    this.data.matches = this.data.matches.filter((match) => {
      if (!match.media) {
        return false;
      }

      return true;
    });

    const valid = this.data.matches.length > 0;

    if (!valid) {
      this.handleAlert('Match is required');
      this.newMatch();
    }

    return valid;
  }

  override getParams() {
    const params = {
      impressions: this.data.summary.totalViews,
      items: this.data.matches,
    };

    return params;
  }

  override success(response: any): void {
    this.handleAlert(`¡Changes saved successfully!`, 'success');

    this.stopLoading();
  }

  calculateTotal() {
    this.data.calculateSummary();
  }
}
