import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import {
  ReactiveFormsModule,
  FormsModule,
  Validators,
  FormControl,
} from '@angular/forms';

import { AlertComponent } from '@shared/components';
import {
  CrudFormHeaderComponent,
  CrudFormFooterComponent,
  CrudFormComponent,
} from '@admin/components';
import { Property } from '@admin/models/CampaignType.model';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'app-properties-form',
    imports: [
        NgClass,
        ReactiveFormsModule,
        NgSelectModule,
        FormsModule,
        AlertComponent,
        CrudFormHeaderComponent,
        CrudFormFooterComponent,
    ],
    templateUrl: './properties-form.component.html',
    styleUrl: './properties-form.component.scss'
})
export class PropertiesFormComponent extends CrudFormComponent {
  resources: string[] = [];
  types: string[] = [];
  properties: Property[] = [];

  override data = this.formBuilder.group({
    name: ['', [Validators.required]],
    property_id: new FormControl<number | null>(null),
    property_other: new FormControl<string | null>(null),
  });

  override initSettings(): void {
    this.data.get('property_id')?.valueChanges.subscribe((type) => {
      this.updateDateValidator(type);
    });

    if (this.readonly) this.data.disable();

    this.services = [this.api.call('admin/catalogs/properties')];
  }

  private updateDateValidator(type: number | null): void {
    const propertyControl = this.data.get('property_other');

    propertyControl?.clearValidators();

    if (type === -1) {
      propertyControl?.setValidators([Validators.required]);
    }

    propertyControl?.updateValueAndValidity();
  }

  override onLoadServicesSuccess(responses: any[]): void {
    super.onLoadServicesSuccess(responses);
  }

  override handleLoadResponse(responses: any[]): void {
    if (responses[0] && responses[0].status === 200) {
      this.properties = responses[0].data;
    }

    console.debug(this.dataId());
    console.debug(responses);

    if (this.dataId() !== -1 && responses[1] && responses[1].status === 200) {
      const { property, ...responseData } = responses[1].data;
      console.debug(responseData);

      const params: any = { ...responseData };

      if (property) {
        params['property_id'] = property.id;
        params['property_other'] = property.name;
      }

      this.data.patchValue(params);
    }
  }

  override getParams() {
    const params = this.data.value;

    return params;
  }

  override getFormatEndpoint(): string {
    return this.endpoint.startsWith('admin/')
      ? this.endpoint
      : 'admin/' + this.endpoint;
  }
}
