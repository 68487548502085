<app-title [title]="title" [subtitle]="type" [description]="data.description">
  <div class="row py-3">
    <div class="col-auto ms-auto">
      <button type="button" class="btn btn-secondary px-4" (click)="goBack()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
    </div>
  </div>

  <!-- ! DEBUG -->
  <!-- <div class="row">
    <div class="col-12 px-0">
      <span class="badge text-bg-warning">
        Segments | typeId: {{ periodId }} // propertyId: {{ propertyId }} // periodId: {{ periodId }} //
        clientId:
        {{ clientId }}
      </span>
    </div>
  </div> -->
  <!-- ! DEBUG -->
</app-title>

<div class="container py-3 py-md-4 py-xl-5">
  <div class="row align-items-center gy-3 gy-md-4">
    <div class="col-md-6 col-xl-4 py-3 py-lg-4 border-md-end border-primary">
      <app-main-card
        icon="fa-solid fa-file-lines"
        (onPrimary)="generalReport()"
        primary="arrow_forward_ios"
        secondary=""
      >
        <div class="row gx-2">
          <div class="col-12 text-center pb-2">
            <h5 class="mb-2">View</h5>

            <h5 class="fw-bold mb-0 text-primary">Month Report</h5>
          </div>
        </div>
      </app-main-card>
    </div>

    @if (canCreate()) {
    <div class="col-md-6 col-xl-4">
      @if (creating) {
      <app-main-card
        icon="fa-solid fa-trophy"
        primary="save"
        secondary="close"
        (onSecondary)="cancelNew()"
        (onPrimary)="submit()"
      >
        <div class="row gx-2 py-3 gy-3">
          <div class="col-12">
            <p class="fw-bold mb-1">Property</p>

            <p class="mb-2">Select a property to add to the month.</p>

            <ng-select
              id="tag"
              appearance="outline"
              [(ngModel)]="nSeason.name"
              placeholder="Select a property"
              [clearable]="false"
              [searchable]="false"
            >
              @for (item of months; track $index) {
              <ng-option [value]="item">{{ item }}</ng-option>
              }
              <!--  -->
              @if (months.length === 0) {
              <ng-option [value]="-1">Other</ng-option>
              }
            </ng-select>
          </div>

          <div class="col-12">
            <div class="w-100 d-inline-block ps-2">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="published"
                  [(ngModel)]="nSeason.published"
                />

                <label class="form-check-label" for="published"
                  >Published</label
                >
              </div>
            </div>
          </div>

          <div class="col-12">
            <label for="comments" class="form-label mb-1">
              <strong> Comments </strong>
            </label>

            <textarea
              class="form-control"
              id="comments"
              rows="3"
              [(ngModel)]="nSeason.comments"
            ></textarea>
          </div>
        </div>
      </app-main-card>
      } @else {
      <button
        class="btn btn-primary w-100 ratio ratio-1x1 card-new"
        data-aos="flip-left"
        (click)="createMonth()"
      >
        <div class="d-flex w-100 h-100">
          <h1 class="m-auto"><i class="fa-solid fa-plus"></i></h1>
        </div>
      </button>
      }
    </div>
    }
    <!--  -->
    @for (item of items; track $index) {
    <div class="col-md-6 col-xl-4">
      <app-main-card
        icon="fa-solid fa-trophy"
        [primary]="
          canUpdate()
            ? editing === item.id
              ? 'save'
              : 'arrow_forward_ios'
            : ''
        "
        [secondary]="canEdit() ? (editing === item.id ? 'close' : 'edit') : ''"
        [extra]="canDelete() ? 'delete' : ''"
        (onPrimary)="handlePrymary(item)"
        (onSecondary)="editMonth(item.id)"
        (onExtra)="deleteModal(item.id)"
      >
        <div class="row gx-2 gy-3 py-3">
          @if (item.id === editing) {
          <div class="col-12">
            <p class="fw-bold mb-1">Property</p>

            <p class="mb-2">Select a property to add to the month.</p>

            <ng-select
              id="tag"
              appearance="outline"
              [(ngModel)]="nSeason.name"
              placeholder="Select a property"
              [clearable]="false"
              [searchable]="false"
            >
              @for (item of months; track $index) {
              <ng-option [value]="item">{{ item }}</ng-option>
              }
              <!--  -->
              @if (months.length === 0) {
              <ng-option [value]="'-1'">Other</ng-option>
              }
            </ng-select>
          </div>

          @if (nSeason.name === '-1') {
          <div class="col-12" @fadeIn>
            <p class="fw-bold mb-1">New property</p>

            <p class="mb-2">If you need a new property write it below.</p>

            <input
              id="campaign_property"
              type="text"
              class="form-control"
              [(ngModel)]="nSeason.name"
            />
          </div>
          }

          <div class="col-12">
            <div class="w-100 d-inline-block ps-2">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="published"
                  [(ngModel)]="nSeason.published"
                />

                <label class="form-check-label" for="published"
                  >Published</label
                >
              </div>
            </div>
          </div>

          <div class="col-12">
            <label for="comments" class="form-label mb-1">
              <strong> Comments </strong>
            </label>

            <textarea
              class="form-control"
              id="comments"
              rows="3"
              [(ngModel)]="nSeason.comments"
            ></textarea>
          </div>
          } @else {
          <div class="col-12">
            <p>
              <i class="fa-solid fa-calendar-days icon-w"></i>
              <strong> {{ item.name }} </strong>
            </p>

            <p class="mb-0">
              <i
                class="fa-solid fa-circle icon-w"
                [ngClass]="item.published ? 'text-success' : 'text-danger'"
              ></i>
              {{ item.published ? 'Published' : 'Not published' }}
            </p>
          </div>
          }
        </div>
      </app-main-card>
    </div>
    }
  </div>
</div>
