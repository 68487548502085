import { Component, effect, input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';

import { CustomDateAdapter } from '@services/custom-date-adapter.service';
import { CustomDateParserFormatter } from '@services/custom-date-parser-formatter.service';
import { CustomDatepickerI18n } from './date-picker';

const DELIMITER = '-';

@Component({
  selector: 'app-custom-date-picker',
  imports: [FormsModule, NgbDatepickerModule],
  template: `
    <div class="input-group">
      <input
        class="form-control"
        placeholder="mm/dd/yyyy"
        name="dp"
        [(ngModel)]="data"
        [displayMonths]="0"
        (ngModelChange)="handleDate()"
        ngbDatepicker
        #d="ngbDatepicker"
        [readOnly]="true"
        (click)="d.toggle()"
      />

      <span class="input-group-text" (click)="d.toggle()"
        ><i class="far fa-calendar-star"></i
      ></span>
    </div>
  `,
  styleUrl: './custom-date-picker.component.scss',
  providers: [
    { provide: NgbDateAdapter, useClass: CustomDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})
export class CustomDatePickerComponent {
  date = input('');
  onDateChange = output<string>();

  data = '';
  dataFormatted = '';

  constructor() {
    effect(() => {
      if (!this.date()) return;

      const parts = this.date().split(DELIMITER);

      const month = parts[1].padStart(2, '0');
      const day = parts[2].padStart(2, '0');

      this.data = `${month}${DELIMITER}${day}${DELIMITER}${parts[0]}`;
    });
  }

  handleDate() {
    // mm-dd-yyyy
    if (!this.data) return;

    const parts = this.data.split(DELIMITER);

    const month = parts[0].padStart(2, '0');
    const day = parts[1].padStart(2, '0');

    this.dataFormatted = `${parts[2]}${DELIMITER}${month}${DELIMITER}${day}`;
    this.onDateChange.emit(this.dataFormatted);
  }
}
