import { CampaignEarnedMedia } from '@admin/models/EarnedMedia.model';
import { NgClass } from '@angular/common';
import { Component, Input, output } from '@angular/core';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { VideoEditComponent } from '../video-edit/video-edit.component';

@Component({
  selector: 'app-earned-media-video',
  standalone: true,
  imports: [FormsModule, NgClass, NgbCollapseModule, VideoEditComponent],
  templateUrl: './earned-media-video.component.html',
  styleUrl: './earned-media-video.component.scss',
})
export class EarnedMediaVideoComponent {
  @Input() index: number = 0;
  @Input() data: CampaignEarnedMedia = new CampaignEarnedMedia();
  onDelete = output<void>();
  onChange = output<void>();
  collapsed: boolean = false;

  deleteMatch() {
    this.onDelete.emit();
  }

  onVideoChange() {
    this.onChange.emit();
  }
}
