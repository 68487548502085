import { Component } from '@angular/core';

import { ElementModel } from '@shared/models/Element.model';
import { Pagination } from '@shared/models/Pagination.model';

import { CrudComponent } from '@admin/components/crud/crud.component';
import { CrudModule } from '@admin/components/crud/crud.module';
import { Permission } from '@admin/models/Permission.model';
import { RolesFormComponent } from './roles-form/roles-form.component';

@Component({
  selector: 'app-roles',
  standalone: true,
  imports: [CrudModule],
  templateUrl: '../../components/crud/crud.component.html',
  styleUrl: './roles.component.scss',
})
export class RolesComponent extends CrudComponent {
  override title: string = 'Roles';
  override data: Pagination<Permission> = new Pagination(Permission);

  override handleLoadResponse(responses: any[]): void {
    if (responses[0].data) this.data.setData(responses[0].data);

    this.pagination.set(this.data.rows.length > 0);

    this.stopLoading();
  }

  override settings(): void {
    this.showComponent = RolesFormComponent;
    this.editComponent = RolesFormComponent;
  }
}
